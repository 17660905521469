import { combineReducers } from 'redux';
import { set, isObject, get, uniq } from 'lodash';

import session from './services/session/reducer';
import measurements from './services/measurements/reducer';
import users from './services/users/reducer';
import accounts from './services/accounts/reducer';
import snackbarNotifications from './services/snackbar-notifications/reducer';
import samplesAssets from './services/samples-assets/reducer';

import { userLogoutTypes } from './services/session/actions';
import { SUCCESS } from './constants';

const appReducer = combineReducers({
  session,
  measurements,
  users,
  accounts,
  snackbarNotifications,
  samplesAssets
});

const rootReducer = (state, action) => {
  if (action.type === userLogoutTypes[SUCCESS]) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

export function updateObject(oldObject, path, values, override = true) {
  if (!isObject(oldObject) || values === undefined || typeof path !== 'string') {
    throw new Error(`[updateObject]: Ivalid params. oldObject ${oldObject}, values ${values}, path ${path}.`);
  }

  const newValue = override
    ? values
    : Object.assign({}, get(oldObject, path), values);

  return set(
    Object.assign({}, oldObject),
    path,
    newValue
  );
}

export function updateItemInArray(array, itemId, updateItemCallback) {
  if (!Array.isArray(array) || typeof updateItemCallback !== 'function' || typeof itemId !== 'string') {
    throw new Error(`[updateObject]: Ivalid params.`);
  }

  const updatedItems = array.map(item => {
    if (item.id !== itemId) { return item; }

    return updateItemCallback(item);
  });

  return updatedItems;
}

export function extendNormalizedStructureByItems(base = {}, entityName = '', items) {
  let result;
  let entities;

  if (Array.isArray(items)) {
    result = items.map(i => i.id);
    entities = items.reduce((acc, curr) => ({
      ...acc,
      [curr.id]: curr
    }), {});
  } else {
    result = items.result;
    entities = items.entities[entityName];
  }

  return {
    result: uniq([
      ...base.result,
      ...result
    ]),
    entities: {
      ...base.entities,
      [entityName]: {
        ...base.entities[entityName],
        ...entities
      }
    }
  };
}
