import React, { useCallback } from 'react';
import PT from 'prop-types';
import { useMutation } from '@apollo/client';

import EditBooleanValue from './EditBooleanValue';
import ViewBooleanValue from './ViewBooleanValue';
import EditableValueContainer from '../../EditableValueContainer';
import { UPDATE_FEATURE_VALUE } from '../gql';

const BooleanValue = ({ data, itemId, tableParameterId, readOnly, ...props }) => {
  const {
    boolean,
  } = data;

  const [updateFeatureValue] = useMutation(UPDATE_FEATURE_VALUE);

  const handleUpdate = useCallback(async (newValue) => {
    const valuesInput = { boolean: newValue };
    const variablesInput = {
      tableParameterId,
      tableItemId: itemId,
      ...valuesInput
    };

    try {
      await updateFeatureValue({
        variables: { input: variablesInput },
        optimisticResponse: {
          updateTableValue: {
            __typename: 'TableValue',
            quantity: null,
            text: null,
            linkedTableItem: null,
            ...variablesInput,
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, [itemId, tableParameterId, updateFeatureValue]);

  return (
    <EditableValueContainer
      {...props}

      readOnly={readOnly}
      defaultValue={boolean}
      onUpdate={handleUpdate}
      viewComponent={ViewBooleanValue}
      editComponent={EditBooleanValue}
    />
  );
};

BooleanValue.propTypes = {
  readOnly: PT.any,
  itemId: PT.string.isRequired,
  tableParameterId: PT.string.isRequired,
  data: PT.shape({
    boolean: PT.bool
  }).isRequired
};

export default BooleanValue;
