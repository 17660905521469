import React, { useState, useMemo, useCallback, useRef } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import useAutocomplete from '@mui/material/useAutocomplete';

import Option from './Option';
import OptionContent from './OptionContent';

export const PARSING_CONFIGS = gql`
  query ParsingConfigs {
    parsingConfigs {
      id
      code
      sharingSettings
      dateUsed
      instrument {
        code
        manufacturer {
          id
          name
          code
        }
        model
        id
      }
      measurement {
        id
        name
        code
      }
      parser {
        id
        name
        filename
        code
        runtime
        dataType
        viewType
      }
    }
  }
`;

const ListBox = styled(Paper)`
  max-height: 150px;
  overflow: auto;
  margin: 2px 0;
  padding: 0;
  border: 1px solid #DCDBDC;
  border-radius: 4px;
  display: ${({ open }) => open ? 'block' : 'none'};
  list-style: none;

  position: absolute;
  z-index: 1;

  li.Mui-focused {
    background: #eee;
    cursor: pointer;
  },
`;

const Input = styled.input`
  width: ${({ focused }) => focused ? '100%' : '1px'};
  height: 100%;
  border: none;
  padding: 0;

  :focus {
    outline: none;
  }
`;

const expandIconStyles = {
  marginLeft: '6px'
};

function getFileExtension(value) {
  const hasExt = value.includes('.');
  const suffix = hasExt ? value.split('.').pop() : '';
  const extension = suffix.split(' ')[0];

  return extension;
}

function findCodeInFilename(filename) {
  if(typeof filename !== 'string') return;

  const result = filename.match(/#[A-Z0-9]{2,}-[A-Z0-9]{2,}-[A-Z0-9]{1,}-[A-Z0-9]{2,}/gi);

  return result?.[0];
}

const ParsingConfigSelector = ({ value, filename, onSelect }) => {
  const [clearInput, setClearInput] = useState(true);

  const copyClicked = useRef(false);

  const { data, loading } = useQuery(PARSING_CONFIGS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if(value) return;

      const codeInFilename = findCodeInFilename(filename);

      if(codeInFilename) {
        const parsingConfig = data.parsingConfigs.find(({ code }) => codeInFilename === `#${code}`);

        if(parsingConfig) {
          onSelect(parsingConfig);
        }
      }
    }
  });

  const parsingConfigs = useMemo(() => {
    if(!data)
      return [];

    const extension = getFileExtension(filename);

    return data.parsingConfigs?.filter(({ id, sharingSettings, parser }) => {

      if(value?.id === id)
        return true;

      return sharingSettings !== 'API' &&
        parser.name.toLowerCase().includes(extension);
    });
  }, [data, filename, value]);

  const {
    inputValue,
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    setAnchorEl,
    anchorEl,
    focused,
    expanded
  } = useAutocomplete({
    id: 'new-measurement-parsing-config',
    options: parsingConfigs,
    getOptionLabel: (option) => {
      if(!option) return;
      const { code, measurement, instrument, parser } = option;

      return [
        measurement.name,
        instrument.model,
        instrument.manufacturer.name,
        parser.name,
        code
      ].join(' | ');
    },
    onChange: (ev, value) => {
      if(value)
        onSelect(value);
    },
    onOpen: () => {
      setClearInput(true);
    },
    onInputChange: () => {
      setClearInput(false);
    },
    isOptionEqualToValue: (option, value) => {
      return option.id === value.id;
    },
    value,
    blurOnSelect: true,
    openOnFocus: true
  });

  const { onBlur } = getInputProps();

  const handleCopy = useCallback(() => {
    copyClicked.current = true;
  }, []);

  const handleBlur = useCallback(ev => {
    if(copyClicked.current) {
      copyClicked.current = false;
      return;
    }

    onBlur(ev);
  }, [onBlur]);

  return (
    <Box
      position="relative"
    >
      <Typography
        variant="caption"
        component="label"
        color="rgba(0,0,0,.6)"
        mb="2px"
        display="block"
        {...getInputLabelProps()}
      >
        Configuration
      </Typography>

      <Box
        sx={{
          height: '48px',
          px: '12px',
          py: '6px',
          background: 'white',
          border: '1px solid #DCDBDC',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
        ref={setAnchorEl}
        {...getRootProps()}
      >
        <Input
          focused={focused}
          {...getInputProps()}
          // override `onBlur` handler to prevent closing the menu
          // when user copies the configuration code
          onBlur={handleBlur}
          value={clearInput ? '' : inputValue}
        />

        {focused ?
          null :
          value ?
            <OptionContent value={value} /> :
            <Box flexGrow="1">
              <Typography variant="body2" color="#999">
                Start typing to search for configuration
              </Typography>
            </Box>
        }

        {expanded ?
          <ExpandLessIcon fontSize="16px" sx={expandIconStyles} /> :
          <ExpandMoreIcon fontSize="16px" sx={expandIconStyles} />
        }
      </Box>

      <ListBox
        component="ul"
        elevation={3}
        open={expanded}
        {...getListboxProps()}
        style={{
          width: anchorEl ? anchorEl.clientWidth : null,
          zIndex: 2
        }}
      >
        {loading ?
          'Loading...' :
          null
        }

        {groupedOptions.length ?
          groupedOptions.map((option, index) => (
            // the `key` prop is returned by the `getOptionProps` function
            // eslint-disable-next-line react/jsx-key
            <Option
              {...getOptionProps({ option, index })}
            >
              <OptionContent value={option} onCopy={handleCopy} />
            </Option>
          )) :
          'Nothing found'
        }
      </ListBox>
    </Box>
  );
};

ParsingConfigSelector.propTypes = {
  value: PT.object,
  filename: PT.string,
  onSelect: PT.func
};

export default ParsingConfigSelector;
