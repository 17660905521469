import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { COLOR_BLACK, COLOR_PRIMARY } from '../../../../../../styles';
import { Container } from './styles';

import { routes } from '../../../../../../services/session/constants';
import { rolesIds } from '../../../../../../utils/roles';


const LinkValue = ({ data }) => {
  const noAccess = data.link?.viewerMaxRole === rolesIds.NO_ACCESS || data.link?.archived;

  const titleProps = noAccess ?
    {
      color: '#9e9e9e',
      sx: { textDecoration: 'underline' }
    } :
    {
      color: COLOR_PRIMARY,
      component: Link,
      to: data.link?.id ?
        generatePath(routes.ITEMS, { sampleId: data.link?.id })
        : null,
      target: '_blank',
      onClick: ev => ev.stopPropagation()
    };

  return (
    <Container>
      {(data.link?.id && !noAccess) ?
        <OpenInNewIcon
          color="primary"
          sx={{
            height: '18px'
          }}
        /> :
        null
      }

      <Tooltip
        title={noAccess ? 'Inaccessible item' : data.link?.title}
        placement="top"
      >
        <Typography
          variant="body2"
          {...titleProps}
        >
          {data.link?.title}

          {data.link?.code ?
            <Typography
              minWidth="fit-content"
              variant="body2"
              sx={{
                color: COLOR_BLACK,
                textDecoration: 'none',
                display: 'inline-block',
                paddingLeft: '4px'
              }}
            >
              ({data.link?.code})
            </Typography> :
            null
          }
        </Typography>
      </Tooltip>
    </Container>
  );
};

LinkValue.propTypes = {
  data: PropTypes.object.isRequired
};

export default LinkValue;
