import React from 'react';
import dateFnsFormat from 'date-fns/format';
import get from 'lodash/get';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';

import ItemActions from './ItemActions';
import OwnerField from './OwnerField';
import DataCollectionField from './DataCollectionField';
import ItemTitle from './ItemTitle';

const TableRowContent = ({
  type,
  data,
  onArchive,
  onDelete,
  onAddMeasurement,
  onAddProtocol,
  renderItemActions,
  onItemClick
}) => {
  switch (type) {
    case 'checkbox':
      return true;

    case 'id':
      return data.id;

    case 'sample-name':
      return (
        <ItemTitle
          id={data.id}
          title={data.title}
          code={data.code}
          description={data.description}
          onItemClick={onItemClick}
        />
      );

    case 'owner':
      return (
        <OwnerField
          userId={data.owner.userId}
          username={data.owner.name}
          avatar={get(data.owner, 'picture.downloadURL')}
          avatarColor={data.owner.avatarColor}
        />
      );

    case 'date-updated':
      return dateFnsFormat(data.dateUpdated || Date.now(), 'dd/MM/yyyy HH:mm');

    case 'date-created':
      return dateFnsFormat(data.dateCreated || Date.now(), 'dd/MM/yyyy HH:mm');

    case 'data-collection':
      return (
        <DataCollectionField
          measurementCount={data.objectsStat.measurements}
          protocolCount={data.objectsStat.preparation + data.objectsStat.observation}
        />
      );

    case 'action':
      return renderItemActions
        ? renderItemActions(data)
        : (
          <ItemActions
            id={data.id}
            sharingOption={data.privacy}
            iam={data.iam}
            sample={{
              ...pick(data, [
                'id',
                'title',
                'description',
                'owner',
                'code',
                'privacy',
                'objectsStat',
                'viewerMaxRole',
                'isUsedInProtocols'
              ]),
            }}
            onArchive={onArchive}
            onDelete={onDelete}
            onAddMeasurement={onAddMeasurement.bind(null, data.id)}
            onAddProtocol={onAddProtocol.bind(null, data.id)}
            userRole={data.viewerMaxRole}
          />
        );
    case 'children':
      return true;
  }
};

TableRowContent.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  onArchive: PropTypes.func,
  onDelete: PropTypes.func,
  onAddMeasurement: PropTypes.func,
  onAddProtocol: PropTypes.func,
  renderItemActions: PropTypes.func,
  adminAccess: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default TableRowContent;
