import React from 'react';
import PT from 'prop-types';
import { Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';

import ArchiveIcon from '@mui/icons-material/Archive';

import { ArchivedItemsLink } from './styles';

import { routes } from '../../../services/session/constants';

const ArchiveAction = ({ minimize }) => {
  if(minimize)
    return (
      <IconButton
        className="archive-action"
        component={Link}
        to={routes.ITEMS_ARCHIVED}
        target="_blank"
      >
        <ArchiveIcon fontSize="inherit" />
      </IconButton>
    );
  else
    return (
      <ArchivedItemsLink
        className="archive-action"
        to={routes.ITEMS_ARCHIVED}
        target="_blank"
      >
        Archived items
      </ArchivedItemsLink>
    );
};

ArchiveAction.propTypes = {
  minimize: PT.bool
};

export default ArchiveAction;
