import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import zip from 'lodash/zip';

import makeStyles from '@mui/styles/makeStyles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import OutlinedSelect from './OutlinedSelect';
import AppGraphics from '../AppGraphics';

import { getChartTypeOptions, getChartSettingsConfigByType } from './utils';

const SETTINGS_CONTAINER_WIDTH = 300;
const MIDDLE_PADDING = 40;

const styles = {
  formGroupRoot: {
    marginBottom: 10
  },
  formControlRoot: {
    margin: '8px 0 0'
  },
  settingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: `${SETTINGS_CONTAINER_WIDTH}px`,
    marginRight: `${MIDDLE_PADDING}px`
  },
  previewContainer: {
    width: `calc(100% - ${SETTINGS_CONTAINER_WIDTH + MIDDLE_PADDING}px)`,
    minHeight: '340px',
    maxHeight: props => props?.chartContainer?.maxHeight ?? '400px'
  },
  svg: {
    width: '100%'
  },
  root: {
    'display': 'flex',
    '@media (max-width: 768px)': {
      flexDirection: 'column'
    }
  },
  FormHelperTextRoot: {
    margin: '0 0 10px'
  },
  warningMessage: {
    color: 'red',
    fontSize: '13px'
  }
};

const useStyles = makeStyles(styles);

const ChartSettings = ({
  styles = {},
  settingsOptions,
  chartType,
  data,
  id,
  onChange,
  config,
  graphicsType,
  onChangeGraphicsType,
}) => {
  const classes = useStyles(styles);

  const handleChangeGraphicsType = useCallback(ev => {
    onChangeGraphicsType({
      id,
      value: ev.target.value
    });
  }, [onChangeGraphicsType, id]);

  const handleChange = useCallback(ev => {
    const { name, value } = ev.target;

    onChange({
      name,
      value,
      id
    });
  }, [onChange, id]);

  const handleAxesConfigChange = useCallback(prop => ev => {
    const { name, value, checked } = ev.target;
    const warning = shouldDisplayWarning(name, checked, config, data);

    onChange({
      name: 'axes',
      value: {
        ...(config?.axes || {}),
        [name]: {
          ...get(config, `axes[${name}]`, {}),
          [prop]: checked ? value : null,
          warning
        }
      },
      id
    });
  }, [onChange, id, config, data]);

  const {
    allowAxisTransform,
    axes: axesSettings
  } = getChartSettingsConfigByType(chartType);

  return (
    <div className={classes.root}>
      <div className={classes.settingsContainer}>
        <FormHelperText classes={{ root: classes.FormHelperTextRoot }}>
          Fields marked with asterisk (*) are required
        </FormHelperText>

        <FormGroup classes={{ root: classes.formGroupRoot }}>
          <FormControl
            classes={{ root: classes.formControlRoot }}
            variant="outlined"
            fullWidth
            required
          >
            <OutlinedSelect
              id={id}
              items={getChartTypeOptions()}
              label="Graphics type"
              value={graphicsType}
              onChange={handleChangeGraphicsType}
              name="type"
              required
            />
          </FormControl>
        </FormGroup>

        {axesSettings.map(({
          id,
          required,
          multiple,
          label,
          name,
          placeholder,
          transform,
          defaultValue
        }) => (
          <FormGroup key={id} classes={{ root: classes.formGroupRoot }}>
            <FormControl
              classes={{ root: classes.formControlRoot }}
              variant="outlined"
              fullWidth
              required={required}
            >
              <OutlinedSelect
                id={id}
                items={settingsOptions.axes}
                multiple={multiple}
                displayEmpty={!required}
                label={label}
                placeholder={placeholder}
                value={config?.[name] ?? defaultValue}
                onChange={handleChange}
                name={name}
                required={required}
              />
            </FormControl>

            {!!allowAxisTransform && (transform !== void 0) &&
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={get(config, `axes.${name}.transform`) === 'log'}
                      onChange={handleAxesConfigChange('transform')}
                      value="log"
                      name={name}
                      color="primary"
                      disableRipple
                    />
                  }
                  componentsProps={{
                    typography: { variant: 'body2' }
                  }}
                  label="Plot axis using logarithmic scale"
                />
                {
                  get(config, `axes.${name}.warning`) ?
                    <span className={classes.warningMessage}>
                      {getWarningMessage(name)}
                    </span> :
                    null
                }
              </>
            }
          </FormGroup>
        ))}
      </div>

      <div className={classes.previewContainer}>
        <AppGraphics
          className={classes.svg}
          type={graphicsType}
          data={data}
          config={config}
        />
      </div>
    </div>
  );
};

ChartSettings.propTypes = {
  settingsOptions: PropTypes.object,
  chartType: PropTypes.string,
  styles: PropTypes.shape({
    chartContainer: PropTypes.shape({
      maxHeight: PropTypes.string
    })
  }),
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ]),
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  config: PropTypes.object,
  graphicsType: PropTypes.string,
  onChangeGraphicsType: PropTypes.func.isRequired
};

export default ChartSettings;

function getWarningMessage(name) {
  if(['xAxis', 'yAxis'].includes(name)) {
    return `The ${name === 'xAxis' ? 'X' : 'Y'} coordinate contains either negative values or zeros.` +
      ` These values are filtered in the current view.`;
  }
  return null;
}

function shouldDisplayWarning(name, checked, config, data) {
  if (!config) return;

  const coords = zip(...data.slice(1));

  let warning;
  if(name === 'xAxis'){
    const axisDim = config[name];
    warning = coords[axisDim].some(i => i <= 0) && checked;
  } else {
    const axesDim = [...config[name], ...(config.yAxisRight || [])];
    warning = axesDim.some(dim => coords[dim].some(i => i <= 0)) && checked;
  }

  return warning;
}
