import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

import FiltersPopper from '../FiltersPopper';

import useFilterOptions from '../../../Filters/hooks/useFilterOptions';
import useFeaturesSync from '../../../Filters/hooks/useFeaturesSync';
import { toParametersByIds } from '../../../Filters/utils';
import useTrack from '../../../Filters/hooks/useTrack';

import { ButtonStyled } from './styles';
import { COLOR_GREY_DARK } from '../../../../../styles';

const VIEWS_WIDTH = 240;

const FiltersButton = ({
  data,
  activeFilters,
  onSubmit,
  disabled,
  onSingleFilterUpdate,
  removeFilters,
  disableParamsFilterSync
}) => {
  const track = useTrack();

  const parameters = useFilterOptions({
    tableIds: activeFilters.tableIds
  });

  const parametersData = removeFilters ? [] : parameters.data;

  const featuresById = toParametersByIds(parametersData);

  const handleSingleFilter = useCallback((type, filterData) => {
    track(type, filterData, data);
    onSingleFilterUpdate(type, filterData);
  }, [track, data, onSingleFilterUpdate]);

  useFeaturesSync({
    onUpdate: (data) => handleSingleFilter('featureSliceByList', {
      featureSliceBys: data
    }),
    allFeatures: featuresById,
    selectedFeatures: activeFilters?.featureSliceByList?.featureSliceBys || [],
    disableParamsFilterSync
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((e) => {
    e.preventDefault();
    setAnchorEl(null);
  }, []);

  const handleClear = useCallback(() => {
    setAnchorEl(null);
    onSubmit();
  }, [onSubmit]);

  const allFilters = useMemo(() => ({
    ...data,
    parameters: parameters?.data
  }), [data, parameters?.data]);

  const filteredActiveFilters = useMemo(() => {
    return {
      featureSliceBys: activeFilters.featureSliceByList?.featureSliceBys || [],
      ...omit(activeFilters, ['featureSliceByList', 'tableIds'])
    };
  }, [activeFilters]);

  const activeFilterTags = useMemo(() => {
    let tag = [];

    if(filteredActiveFilters.featureSliceBys?.length){
      filteredActiveFilters.featureSliceBys.forEach(featureSlice => {
        tag.push(`${featuresById ? featuresById[featureSlice.featureId]?.title : ''}`);
      });
    }

    if(filteredActiveFilters.creatorIds?.length){
      tag.push('Owner');
    }

    if(filteredActiveFilters.createdTimestamp){
      tag.push('Creation Date');
    }

    return tag.filter(Boolean).join(', ');
  }, [featuresById, filteredActiveFilters.createdTimestamp, filteredActiveFilters.creatorIds?.length, filteredActiveFilters.featureSliceBys]);

  const handleSubmit = useCallback((data) => {
    setAnchorEl(null);
    onSubmit(data);
  }, [onSubmit]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        maxWidth: `calc(100% - ${VIEWS_WIDTH}px)`,
      }}
    >
      <ButtonStyled
        onClick={handleClick}
        startIcon={<FilterListIcon sx={{ width: '14px', height: '14px' }} />}
        active={activeFilterTags}
        disabled={disabled}
      >
        <Typography
          noWrap
          sx={{
            fontSize: '12px'
          }}
        >
          {activeFilterTags ? `Filters: ${activeFilterTags}` : 'Add filters'}
        </Typography>
      </ButtonStyled>

      {activeFilterTags ?
        <Button
          onClick={handleClear}
          startIcon={<CloseIcon sx={{ width: '14px', height: '14px' }} />}
          sx={{
            textTransform: 'none',
            color: COLOR_GREY_DARK,
            height: '24px',
            minWidth: '110px',
            '.MuiButton-startIcon': {
              mr: '4px'
            }
          }}
        >
          <Typography
            noWrap
            sx={{
              fontSize: '12px'
            }}
          >
            Clear filters
          </Typography>
        </Button>
        : null
      }

      <FiltersPopper
        anchorEl={anchorEl}
        onClose={handleClose}
        onSubmit={handleSubmit}
        data={allFilters}
        value={filteredActiveFilters}
        featuresById={featuresById}
      />
    </Box>
  );
};

FiltersButton.propTypes = {
  data: PropTypes.object.isRequired,
  activeFilters: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onSingleFilterUpdate: PropTypes.func.isRequired,
  removeFilters: PropTypes.bool,
  disableParamsFilterSync: PropTypes.bool,
};

export default FiltersButton;
