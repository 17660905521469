import { gql } from '@apollo/client';

export const GET_FOLDERS = gql`
    query Folders {
      folders {
        id
        title
        viewerMaxRole
      }
    }
`;

export const DUPLICATE_TABLE = gql`
mutation CloneTable($cloneTableId: ID!, $data: CloneTableInput) {
  cloneTable(id: $cloneTableId, data: $data) {
    id
    title
    description
    folderId
  }
}
`;
