import { useReducer, useEffect, useCallback } from 'react';
import difference from 'lodash/difference';
import omit from 'lodash/omit';

const initialState = {};

const actions = {
  UPDATE_STATE: 'UPDATE_STATE',
  TOGGLE_VISIBILITY: 'TOGGLE_VISIBILITY',
  TOGGLE_COLLAPSE: 'TOGGLE_COLLAPSE'
};

const handleUpdateState = (state, payload) => {
  const diff = difference(Object.keys(state), Object.keys(payload));

  return {
    ...payload,
    ...omit(state, diff)
  };
};

const handleToggleVisibility = (state, payload) => {
  const { ids, val } = payload;

  return {
    ...state,
    ...ids.reduce((acc, id) => {
      acc[id] = {
        ...state[id],
        visible: val
      };

      return acc;
    }, {})
  };
};

const handleToggleCollapse = (state, payload) => {
  const { id, val } = payload;

  return {
    ...state,
    [id]: {
      ...state[id],
      collapsed: val
    }
  };
};

const reducer = (state, action) => {
  switch(action.type) {
    case actions.UPDATE_STATE:
      return handleUpdateState(state, action.payload);

    case actions.TOGGLE_VISIBILITY:
      return handleToggleVisibility(state, action.payload);

    case actions.TOGGLE_COLLAPSE:
      return handleToggleCollapse(state, action.payload);

    default:
      return state;
  }
};

const getParentIds = (column, result = []) => {
  if(!column.parent)
    return result;

  result.push(column.parent.id);

  return getParentIds(column.parent, result);
};

const useTableState = ({ columnsById }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const payload = {};

    for(const [id, column] of Object.entries(columnsById)) {
      let { visible, collapsed } = column.columnDef;

      payload[id] = {
        visible: visible ?? true,
        collapsed: collapsed ?? false
      };
    }

    dispatch({
      type: actions.UPDATE_STATE,
      payload
    });
  }, [columnsById]);

  const toggleVisibility = useCallback((id, val) => {
    const column = columnsById[id];

    val ??= !column.isVisible();

    let ids = column.getFlatColumns().map(({ id }) => id);

    if(val)
      ids = ids.concat(getParentIds(columnsById[id]));

    dispatch({
      type: actions.TOGGLE_VISIBILITY,
      payload: { ids, val }
    });
  }, [columnsById]);

  const toggleCollapse = useCallback((id, val) => {
    const column = columnsById[id];

    val ??= !column.isCollapsed();

    dispatch({
      type: actions.TOGGLE_COLLAPSE,
      payload: { id, val }
    });
  }, [columnsById]);

  return [
    state,
    {
      toggleVisibility,
      toggleCollapse
    }
  ];
};

export default useTableState;
