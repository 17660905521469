import React, { useState, useCallback, useMemo } from 'react';
import PT from 'prop-types';
import debounce from 'lodash/debounce';

import Select from 'react-select';

import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';

import SingleValue from './SingleValue';
import MenuList from './MenuList';
import DropdownIndicator from './DropdownIndicator';
import CreateItemDialog from '../CreateItemDialog';

import useItemsList from './hooks/items-list.hook';

const BACKSPACE_KEY_EVENT = 'Backspace';

const ItemSelect = ({
  value,
  disabled,
  error,
  onChange,
  placeholder = 'Start typing to search',
  inputProps = {},
  styles = {
    placeholder: {},
    control: {},
    indicators: {},
    root: {}
  }
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [createItemDialogOpen, setCreateItemDialogOpen] = useState(false);

  const {
    items,
    loadMore,
    search,
    networkStatus
  } = useItemsList();

  const isLoading = false;

  const options = useMemo(() => {
    return items.map(({ id, title, code }) => ({
      value: id,
      label: title,
      code
    }));
  }, [items]);

  const menuHeight = useMemo(() => {
    const max = 3,
      l = options.length,
      num = l < max ? l : max;

    return num * 48;
  }, [options]);

  const handleChange = useCallback((data) => {
    onChange(data);
  }, [onChange]);

  const handleInputChange = useMemo(() => {
    return debounce((value) => {
      const searchText = value.trim();

      setSearchValue(searchText);
      search(searchText || null);
    }, 300);
  }, [search]);

  const handleVisibilityChange = useCallback((i, value) => {
    if (value && options.length - i === 1) {
      loadMore();
    }
  }, [options, loadMore]);

  const handleOpenCreateItemDialog = useCallback(() => {
    setCreateItemDialogOpen(true);
  }, []);

  const handleCloseCreateItemDialog = useCallback(() => {
    setCreateItemDialogOpen(false);
  }, []);

  const handleCreateItemDialogSuccess = useCallback(({ id, title, code }) => {
    onChange({ value: id, label: title, code });
    handleCloseCreateItemDialog();
  }, [onChange, handleCloseCreateItemDialog]);

  const handleKeyDown = useCallback(e => {
    if (e.key === BACKSPACE_KEY_EVENT) {
      onChange(null);
    }
  }, [onChange]);

  return (
    <Box sx={styles.root}>
      <Select
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        inputProps={{ autoComplete: 'off', ...inputProps }}
        isDisabled={disabled}
        isLoading={isLoading}
        options={options}
        value={value}
        onChange={handleChange}
        menuHeight={menuHeight}
        styles={{
          menu: (baseStyles) => ({
            ...baseStyles,
            ...styles.menu
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            height: '48px'
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            boxShadow: 'none',
            borderColor: '#DCDBDC',
            minHeight: '28px',
            ':hover': {
              borderColor: '#DCDBDC'
            },
            ...styles.control
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            margin: 0,
            padding: 0,
            fontSize: '14px',
            outline: 'none'
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            margin: 0,
            fontSize: '14px',
            ...styles.placeholder
          }),
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            '.select__dropdown-indicator': {
              padding: '0 8px 0 0'
            },
            '.select__indicator-separator': {
              display: 'none'
            },
            ...styles.indicators
          })
        }}
        components={{
          SingleValue,
          MenuList,
          DropdownIndicator
        }}
        searchValue={searchValue}
        onVisibilityChange={handleVisibilityChange}
        onInputChange={handleInputChange}
        onCreateItem={handleOpenCreateItemDialog}
        classNamePrefix="select"
        menuPlacement="auto"
        loading={networkStatus}
      />

      {error ?
        <FormHelperText sx={{ ml: 0 }} error>
          {error.message}
        </FormHelperText> :
        null
      }

      {createItemDialogOpen ?
        <CreateItemDialog
          initialTitle={searchValue}
          onClose={handleCloseCreateItemDialog}
          onSuccess={handleCreateItemDialogSuccess}
        /> :
        null
      }
    </Box>
  );
};

ItemSelect.propTypes = {
  className: PT.string,
  value: PT.object,
  disabled: PT.bool,
  error: PT.object,
  onChange: PT.func.isRequired,
  placeholder: PT.string,
  styles: PT.shape({
    placeholder: PT.string
  }),
  inputProps: PT.object
};

export default ItemSelect;
