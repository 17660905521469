import { gql } from '@apollo/client/core';

export { default as GET_ITEM } from './get-item';

export const ITEM_IAM_FRAGMENT = gql`
  fragment IamFieldsFragment on LinkedItem {
    iam {
      teams {
        team {
          id
          title
          avatarColor
          picture {
            downloadURL
          }
        }
        role
      }
      users {
        user {
          id
          name

          ... on CurrentUser {
            userId
            email
            avatarColor
            picture {
              downloadURL
            }
          }
        }
        role
      }
    }
  }
`;

const LINKED_ITEM_FRAGMENT = gql`
  ${ITEM_IAM_FRAGMENT}

  fragment LinkedItem on LinkedItem {
    ...IamFieldsFragment
    id
    title
    code
    archived
    viewerMaxRole
    privacy
    owner {
      id
      name
      ... on CurrentUser {
        avatarColor
        picture {
          downloadURL
        }
      }
    }
    linkingProtocols {
      id
      title
      archived
    }
  }
`;

export const GET_LINKED_ITEMS = gql`
  ${LINKED_ITEM_FRAGMENT}
  query LinkedItems($itemId: ID!) {
    linkedItems(id: $itemId) {
      to {
        ...LinkedItem
      }

      from {
        ...LinkedItem
      }
    }
  }
`;

