import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import firebase from 'firebase';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import ItemSidebar from '../../../../components/ItemSidebar';

import { openAppSnackbarNotification } from '../../../../services/snackbar-notifications/actions';
import { VIEWER_KEYS } from './services';

import { IS_E2E_TEST_USER } from '../../../../constants';
import { sidebarSources } from '../../../../analytics/constants';

import Container from './styles';

const userType = IS_E2E_TEST_USER ? 'e2e-test' : 'regular';

const InsightView = forwardRef(({
  currentInsightData,
  tableItemQueryId,
  onRemoveItem,
  dashboardStateId
}, iframeRef) => {
  const [iframeHeight, setIframeHeight] = useState(0);
  const [authToken, setAuthToken] = useState(null);
  const [chosenItemId, setChosenItemId] = useState(null);
  const [dashboardLoading, setDashboardLoading] = useState(true);

  const initialQueryId = useRef(tableItemQueryId || null);

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const [iframeLoading, setIframeLoading] = useState(currentInsightData?.displayLoading ?? false);

  const { data: viewerData } = useQuery(VIEWER_KEYS);

  const src = useRef();

  useEffect(() => {
    const queryDidUpdate = tableItemQueryId && initialQueryId.current && tableItemQueryId !== initialQueryId.current;

    if (iframeRef.current && !iframeLoading && tableItemQueryId && queryDidUpdate) {
      iframeRef.current
        .contentWindow
        .postMessage({
          event: 'ITEM_QUERY_ID',
          payload: tableItemQueryId
        },
        currentInsightData?.url
        );
    }

    if(tableItemQueryId)
      initialQueryId.current = tableItemQueryId;
  }, [currentInsightData?.url, tableItemQueryId, iframeLoading, searchParams, iframeRef]);

  const handleToggleSidebar = useCallback((id) => {
    setChosenItemId(id ?? null);
  }, []);

  const handleMessage = useCallback(ev => {
    if(ev.source !== iframeRef.current?.contentWindow)
      return;

    const message = JSON.parse(ev.data);

    if (message.hasOwnProperty('event') && message.event === 'OPEN_SNACKBAR'){
      dispatch(openAppSnackbarNotification({
        message: message.payload.message,
        variant: message.payload.type
      }));
    }

    if (message.hasOwnProperty('event') && message.event === 'OPEN_ITEM'){
      setChosenItemId(message.payload.itemId);
    }

    if(message.hasOwnProperty('docHeight'))
      setIframeHeight(message.docHeight);

    if(message.hasOwnProperty('isLoading'))
      setDashboardLoading(message.isLoading);
  }, [dispatch, iframeRef]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged(async (user) => {
      const token = await user?.getIdToken();
      setAuthToken(token);

      if (iframeRef.current && !iframeLoading){
        iframeRef.current
          .contentWindow
          .postMessage({ event: 'AUTH_TOKEN_UPDATE', payload: token },
            currentInsightData?.url
          );
      }
    });

    return () => unsubscribe();
  }, [currentInsightData?.url, iframeLoading, iframeRef]);

  const handleIframeLoad = useCallback(() => {
    setIframeLoading(false);
  }, []);

  const paramsList = {
    user_key: viewerData?.viewer.id,
    api_key: viewerData?.viewer.apiKey,
    user_type: userType,
    dashboardStateId,
    itemQueryId: tableItemQueryId,
    auth_token: authToken,
  };

  const isLoading = dashboardLoading || iframeLoading;
  const displayIframe = !iframeLoading || Boolean(paramsList.itemQueryId && paramsList.user_key && paramsList.auth_token);

  if(iframeLoading)
    src.current = `${currentInsightData.url}?${createSearchParams(paramsList)}`;

  return (
    <Container>
      <div className="iframe-container">
        {isLoading &&
          <Box
            position="absolute"
            width="100%"
            height="100%"
            left="0"
            top="0"
            bgcolor="rgba(255,255,255,.5)"
            zIndex="1"
          >
            <CircularProgress
              size="32px"
              sx={{
                position: 'absolute',
                top: 'calc(50% - 16px)',
                right: 'calc(50% - 16px)'
              }}
            />
          </Box>
        }

        {displayIframe ?
          <iframe
            title={currentInsightData?.title}
            src={src.current}
            height={iframeHeight}
            onLoad={handleIframeLoad}
            ref={iframeRef}
            allow="clipboard-read; clipboard-write *"
          /> :
          null
        }
      </div>

      <ItemSidebar
        onClose={handleToggleSidebar}
        itemId={chosenItemId}
        clickSource={sidebarSources.DASHBOARD_ITEM}
        onRemove={onRemoveItem}
      />
    </Container>
  );
});

InsightView.propTypes = {
  currentInsightData: PropTypes.object.isRequired,
  tableItemQueryId: PropTypes.string,
  onRemoveItem: PropTypes.func.isRequired,
  dashboardStateId: PropTypes.string
};

export default InsightView;
