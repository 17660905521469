import { eventChannel } from 'redux-saga';
import { put, call, all, select, take } from 'redux-saga/effects';
import { isUndefined, values, isPlainObject, isNil } from 'lodash';
import { normalize } from 'normalizr';
import { mapKeyToId, errorFactory } from './utils';

import { listeners as sessionListeners } from './services/session/sagas';
import { listeners as commonListeners } from './services/common/sagas';
import { listeners as measurementsListeners } from './services/measurements/sagas';

export default function* root() {
  yield all([
    ...sessionListeners,
    ...measurementsListeners,
    ...commonListeners
  ]);
}

export function* fetchEntity(entity, opts, ...params) {
  let apiFn;
  let requiredFields;

  if (typeof opts === 'function') {
    apiFn = opts;
  } else if (isPlainObject(opts)) {
    apiFn = opts.apiFn;
    requiredFields = opts.requiredFields;

    if (requiredFields && !Array.isArray(requiredFields)) {
      throw new Error('[fetchEntity] requiredFields opt should be an array');
    }
  } else {
    throw new Error('[fetchEntity] Incorrect opts param');
  }

  if (requiredFields) {
    let body;

    if (isPlainObject(params[0])) {
      body = params[0];
    } else if (isPlainObject(params[1])) {
      body = params[1];
    } else {
      throw new Error('[fetchEntity] Incorrect opts param: body object is not exist');
    }

    if (!requiredFields.every(i => !isNil(body[i]))) {
      yield put( entity.failure(
        errorFactory('fetchEntity', 'Missed required fields')
      ));
    }
  }

  const { response, error } = yield call(apiFn, ...params);

  if(!isUndefined(response) && isNil(error)) {
    yield put( entity.success(response) );
  } else {
    yield put( entity.failure(error) );
  }
}

export function* listenLists(entity, apiFn, extra = { params: {} }) {
  const { params, schema } = extra;
  const ref = yield call(apiFn, ...values(params));
  const listener = eventChannel(emit => {
    let init = true;

    ref.once('value', snap => {
      let payload = {
        key: snap.key,
        data: snap.val()
      };

      init = false;

      if (schema) {
        if (!params.mapper) {
          params.mapper = mapKeyToId;
        }

        const mappedData = params.mapper(snap.val());
        payload = normalize(mappedData, schema);
      }

      emit(entity.success(payload));
    });

    entity.added && ref.on('child_added', onAdded);
    entity.changed && ref.on('child_changed', onChanged);
    entity.removed && ref.on('child_removed', onRemoved);

    return () => {
      ref.off();
    };

    function onAdded(snap) {
      const payload = parseSingleItemResponse(snap, schema);
      const response = entity.added(payload);

      if (response && !init) {
        emit(response);
      }
    }

    function onChanged(snap) {
      const payload = parseSingleItemResponse(snap, schema);
      const response = entity.changed(payload);

      if (response && !init) {
        emit(response);
      }
    }

    function onRemoved(snap) {
      const payload = parseSingleItemResponse(snap, schema);
      const response = entity.removed(payload);

      if (response && !init) {
        emit(response);
      }
    }
  });

  return listener;
}

function parseSingleItemResponse(snap, schema) {
  let payload = {
    key: snap.key,
    data: snap.val()
  };

  if (schema) {
    const mappedData = {
      ...snap.val(),
      id: snap.key
    };

    payload = normalize([mappedData], schema);
  }

  return payload;
}

export function* waitFor(selector) {
  let data = yield select(selector);
  if (data) { return data; }

  while (true) {
    yield take('*');
    let data = yield select(selector);
    if (data) {
      return data;
    }
  }
}

export function* shouldRequest({
  notForced,
  selector,
  id,
  requiredProps,
  onSuccess,
  onCachedSuccess
}) {
  if (notForced) {
    const record = yield select(selector, id);

    if (record) {
      if (requiredProps) {
        const isRequiredDataExist = requiredProps.every(i => !isNil(record[i]));

        if (isRequiredDataExist) {
          if (onCachedSuccess || onSuccess) {
            yield put( onCachedSuccess ? onCachedSuccess({ id }) : onSuccess() );
          }

          return false;
        }
      } else {
        if (onSuccess) {
          yield put(onSuccess());
        }

        return false;
      }
    }
  }

  return true;
}
