import { createRequestTypes, action, createHandlers } from '../../actions';
import * as constants from './constants';

const {
  USERS_LIST_GET,
  REQUEST,
  SUCCESS,
  FAILURE,
  USER_BY_ID_GET,
  ALL_USERS_LIST_GET,
  USERS_ADD
} = constants;

export const usersListLoadTypes = createRequestTypes(USERS_LIST_GET);
export const userByIdGetTypes = createRequestTypes(USER_BY_ID_GET);
export const allUsersListGetTypes = createRequestTypes(ALL_USERS_LIST_GET);
export const usersListWithAccountsLoadTypes = createRequestTypes(constants.USERS_LIST_WITH_ACCOUNTS_GET);
export const avatarsListLoadTypes = createRequestTypes(constants.AVATARS_LIST_GET);

export const usersListLoadHandlers = {
  request: params => action(usersListLoadTypes[REQUEST], { params }),
  success: response => action(usersListLoadTypes[SUCCESS], { response }),
  failure: error => action(usersListLoadTypes[FAILURE], { error })
};

export const userByIdGetHandlers = createHandlers(userByIdGetTypes);
export const avatarsListLoadHandlers = createHandlers(avatarsListLoadTypes);

export const allUsersListGetHandlers = {
  request: () => action(allUsersListGetTypes[REQUEST]),
  success: response => action(allUsersListGetTypes[SUCCESS], { response }),
  failure: error => action(allUsersListGetTypes[FAILURE], { error })
};

export const usersListWithAccountsLoadHandlers = createHandlers(usersListWithAccountsLoadTypes);

export const usersByLabIdLoad = labId => usersListLoadHandlers.request({
  equalTo: labId,
  orderBy: 'affiliate_primary'
});
export const usersByIdListLoad = ids => usersListLoadHandlers.request({
  idList: ids
});
export const loadUserById = userByIdGetHandlers.request;
export const allUsersListLoad = allUsersListGetHandlers.request;
export const fetchUsers = usersListLoadHandlers.request;
export const addUsers = list => action(USERS_ADD, { list });
export const fetchUsersWithAccounts = usersListWithAccountsLoadHandlers.request;
export const loadAvatarsList = avatarsListLoadHandlers.request;
