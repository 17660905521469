import { css } from 'styled-components';

export default () => css`
  width: 1em;
  height: 1em;
  font-size: 24px;
  display: inline-block;
  fill: currentColor;
  user-select: none;
  flex-shrink: 0;
`;
