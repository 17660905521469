import groupBy from 'lodash/groupBy';

const mapTables = (tables, searchValue) => {
  return tables
    .filter(({ title }) => !searchValue || title.toLowerCase().includes(searchValue.toLowerCase()))
    .sort((a, b) => a.title.localeCompare(b.title));
};

const mapFolders = (folders, foldersByParentId, tablesByParentId, searchValue) => {
  const result = [];

  for(const folder of folders) {
    const matchSearch = !searchValue || folder.title.toLowerCase().includes(searchValue.toLowerCase());
    const _searchValue = matchSearch ? '' : searchValue;

    const childTables = mapTables(tablesByParentId[folder.id] ?? [], _searchValue);

    const _folder = {
      ...folder,
      isFolder: true,
      children: mapFolders(foldersByParentId[folder.id] ?? [], foldersByParentId, tablesByParentId, _searchValue)
        .concat(childTables)
    };

    if(matchSearch || _folder.children.length)
      result.push(_folder);
  }

  return result.sort((a, b) => a.title.localeCompare(b.title));
};

const createDataTree = (folders = [], tables = [], searchValue = '') => {
  const foldersByParentId = groupBy(folders, 'parentId');
  const tablesByParentId = groupBy(tables, 'folderId');

  const rootFolders = mapFolders(foldersByParentId.null ?? [], foldersByParentId, tablesByParentId, searchValue);
  const rootTables = mapTables(tablesByParentId.null ?? [], searchValue);

  return rootFolders.concat(rootTables);
};

export default createDataTree;
