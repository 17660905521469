export const createHeader = (table, column, options) => {
  const { id, depth, headerGroup } = options;

  return {
    id,
    column,
    depth,
    headerGroup,
    subHeaders: [],
    colSpan: 1,
    rowSpan: 1,
    getContext: () => ({
      table,
      column
    })
  };
};

export const createHeaderGroups = (table, columns, maxDepth) => {
  const createGroup = (columns, parent = null, result = []) => {
    const depth = parent ? parent.depth + 1 : 0;
    const headerGroup = {
      id: String(depth),
      depth,
      headers: []
    };

    if(!result[depth])
      result[depth] = headerGroup;

    const headers = [];
    let parentColSpan = 0;

    for(const column of columns) {
      const header = createHeader(table.current, column, {
        id: column.id,
        depth,
        headerGroup
      });

      if(column.columns?.length) {
        createGroup(column.columns, header, result);
      } else {
        header.colSpan = 1;
        header.rowSpan = maxDepth - depth + 1;
      }

      if(parent)
        parentColSpan += header.colSpan;

      headers.push(header);
    }

    if(parent) {
      parent.subHeaders = headers;
      parent.colSpan = parentColSpan;
    }

    result[depth].headers.push(...headers);

    return result;
  };

  return createGroup(columns);
};
