import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

const DataCollectionField = ({
  protocolCount = 0,
  measurementCount = 0,
}) => ([
  <div
    className="measurements item clickable"
    key="measurements"
  >
    <Tooltip title="Measurements">
      <div className="label clickable"> M </div>
    </Tooltip>
    <div className="value">
      {measurementCount}
    </div>
  </div>,
  <div className="protocols item" key="protocols">
    <Tooltip title="Protocols">
      <div className="label cursor-default"> P </div>
    </Tooltip>
    <div className="value">
      {protocolCount}
    </div>
  </div>
]);

DataCollectionField.propTypes = {
  protocolCount: PropTypes.number,
  measurementCount: PropTypes.number
};

export default DataCollectionField;
