import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Protocol from './Protocol';
import CreateProtocolForm from './CreateProtocolForm';

import { openAppSnackbarNotification } from '../../../../services/snackbar-notifications/actions';

const DELETE_PROTOCOL = gql`
  mutation DeleteTableProtocol($id: ID!) {
    deleteTableProtocol(id: $id)
  }
`;

const UPDATE_TABLE = gql`
  mutation UpdateTable($id: ID!, $hash: String!, $data: UpdateTableInput!) {
    updateTable(id: $id, hash: $hash, data: $data) {
      id
      hash
      tableProtocols {
        id
        hash
        title
        creator {
          id
          name
        }
        dateCreated
        dateUpdated
      }
    }
  }
`;

const StructureTab = ({ table, readOnly, onRefetch }) => {
  const [createProtocolOpen, setCreateProtocolOpen] = useState(false);

  const [updateTable] = useMutation(UPDATE_TABLE);
  const [deleteTableProtocol] = useMutation(DELETE_PROTOCOL);

  const dispatch = useDispatch();

  const handleDragEnd = useCallback(async (ev) => {
    const toIndex = ev.destination?.index;
    const fromIndex = ev.source.index;

    if(toIndex == null || toIndex === fromIndex) return;

    const _tableProtocols = {
      ids: [],
      byId: {}
    };

    for(const protocol of table.tableProtocols) {
      _tableProtocols.ids.push(protocol.id);
      _tableProtocols.byId[protocol.id] = protocol;
    }

    _tableProtocols.ids.splice(toIndex, 0, _tableProtocols.ids.splice(fromIndex, 1)[0]);

    try {
      await updateTable({
        variables: {
          id: table.id,
          hash: table.hash,
          data: {
            tableProtocolIds: _tableProtocols.ids
          }
        },
        optimisticResponse: {
          updateTable: {
            __typename: 'Table',
            id: table.id,
            hash: 'temp-hash',
            tableProtocols: _tableProtocols.ids.map(id => _tableProtocols.byId[id])
          }
        }
      });
    } catch(e) {
      console.error(e);
    }
  }, [table, updateTable]);

  const handleDeleteProtocol = useCallback(async (id) => {
    try {
      await deleteTableProtocol({
        variables: { id }
      });

      dispatch(openAppSnackbarNotification({
        message: 'The protocol has been deleted',
        variant: 'INFO'
      }));

      onRefetch();
    } catch(e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      }));
    }
  }, [deleteTableProtocol, onRefetch, dispatch]);

  const handleCreateProtocolOpen = useCallback(() => {
    setCreateProtocolOpen(true);
  }, []);

  const handleCreateProtocolClose = useCallback(() => {
    setCreateProtocolOpen(false);
  }, []);

  const handleCreateProtocolSuccess = useCallback(() => {
    setCreateProtocolOpen(false);
    onRefetch();
  }, [onRefetch]);

  const handleProtocolUpdated = useCallback(() => {
    onRefetch();
  }, [onRefetch]);

  return (
    <Box pt="16px">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={table.id}>
          {provided => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {table.tableProtocols.map((protocol, i) => (
                <Protocol
                  key={protocol.id}
                  index={i}
                  data={protocol}
                  table={table}
                  readOnly={readOnly}
                  onDelete={handleDeleteProtocol}
                  onUpdated={handleProtocolUpdated}
                />
              ))}

              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      {createProtocolOpen ?
        <CreateProtocolForm
          tableId={table.id}
          protocols={table.tableProtocols}
          onClose={handleCreateProtocolClose}
          onSuccess={handleCreateProtocolSuccess}
        /> :
        null
      }

      {readOnly ?
        null :
        <Button
          sx={{
            marginTop: '15px'
          }}
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleCreateProtocolOpen}
        >
          Add protocol
        </Button>
      }
    </Box>
  );
};

StructureTab.propTypes = {
  table: PT.object,
  readOnly: PT.bool,
  onRefetch: PT.func
};

export default StructureTab;
