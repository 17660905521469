import React, { useCallback } from 'react';
import PT from 'prop-types';

import { Container, InputStyled } from './styles';

import EditButtons from '../../EditButtons';
import { TEXT_VALUE_TYPE } from './constants';
import { interceptKeyboardEvent } from './utils';

const TetEditValue = ({
  value,
  onCancel,
  onChange,
  onSubmit
}) => {
  const handleKeyDown = useCallback(event => {
    interceptKeyboardEvent({
      event,
      onEnter() {
        onSubmit(TEXT_VALUE_TYPE, value || null);
      },
      onEscape() {
        onCancel();
      }
    });
  }, [onSubmit, onCancel, value]);

  const handleSubmit = useCallback(() => {
    onSubmit(TEXT_VALUE_TYPE, value || null);
  }, [onSubmit, value]);

  return (
    <Container onKeyDown={handleKeyDown}>
      <InputStyled
        value={value}
        onChange={onChange}
      />

      <EditButtons
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

TetEditValue.propTypes = {
  value: PT.string,
  onCancel: PT.func.isRequired,
  onChange: PT.func.isRequired,
  onSubmit: PT.func.isRequired
};

export default TetEditValue;
