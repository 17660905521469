import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import LoadingBanner from '../LoadingBanner';
import Table from './Table';

import { COLOR_WHITE, COLOR_PRIMARY } from '../../styles';

import {
  SORT_SETTINGS,
  ARCHIVE_SORT_SETTINGS
} from '../../services/samples/constants';
import {
  isMobileView
} from '../../services/session/selectors';
import { openQuickCreate } from '../../services/samples-assets/actions';
import { browserStorage } from '../../utils';

import { TableContainer } from './styles';

const mapStateToProps = state => ({
  mobile: isMobileView(state),
});

const mapDispatchToProps = {
  openQuickCreate,
};

const SamplesTable = ({
  loading,
  list,
  openQuickCreate,
  excludedColumns = [],
  archivePage = false,
  onItemArchive,
  columnsMap,
  mobile,
  sortSettings = {},
  onCheck,
  renderItemActions,
  onSort,
  hasNextPage,
  hideTable,

  onCheckAll,
  isCheckedAll,

  itemsById,
  ...props
}) => {

  const filteredColumnsMap = useMemo(() =>
    columnsMap.filter(i => !excludedColumns.includes(i.value)
    ), [columnsMap, excludedColumns]);

  const handleAddMeasurement = useCallback(id => {
    openQuickCreate(id, 'measurement');
  }, [openQuickCreate]);

  const handleAddProtocol = useCallback(id => {
    openQuickCreate(id, 'protocol');
  }, [openQuickCreate]);

  const handleSortUpdate = useCallback(params => {
    browserStorage.set(
      archivePage ? ARCHIVE_SORT_SETTINGS : SORT_SETTINGS,
      JSON.stringify(params)
    );

    onSort(params);
  }, [archivePage, onSort]);

  const isIndeterminate = useMemo(() =>
    !isCheckedAll && Object.values(itemsById).some(({ checked }) => checked),
  [isCheckedAll, itemsById]
  );

  return (
    <>
      <TableContainer excludedColumns={excludedColumns}>
        {loading &&
          <LoadingBanner
            overlay
            spinner
            bcgcolor={COLOR_WHITE}
            color={COLOR_PRIMARY}
          />
        }

        <Table
          items={list}
          mobile={mobile}
          archivePage={archivePage}
          onSort={handleSortUpdate}
          onArchive={onItemArchive}
          onAddMeasurement={handleAddMeasurement}
          onAddProtocol={handleAddProtocol}
          isIndeterminate={isIndeterminate}
          sortedField={get(sortSettings, 'sortBy', '')}
          sortAsc={get(sortSettings, 'sortAsc', true)}
          columnsMap={filteredColumnsMap}
          renderItemActions={renderItemActions}
          hasNextPage={hasNextPage}
          hideTable={hideTable}

          onCheckAll={onCheckAll}
          onCheck={onCheck}

          isCheckedAll={isCheckedAll}
          itemsById={itemsById}

          {...props} // used for table-item props only
        />
      </TableContainer>
    </>
  );
};

SamplesTable.propTypes = {
  list: PropTypes.array.isRequired,
  onItemArchive: PropTypes.func,
  sortSettings: PropTypes.object.isRequired,
  mobile: PropTypes.bool.isRequired,
  columnsMap: PropTypes.array.isRequired,
  archivePage: PropTypes.bool,
  loading: PropTypes.bool,
  openQuickCreate: PropTypes.func.isRequired,
  excludedColumns: PropTypes.array.isRequired,
  renderItemActions: PropTypes.func,
  onSort: PropTypes.func.isRequired,
  openAppSnackbarNotification: PropTypes.func.isRequired,
  closeAppSnackbarNotification: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  hideTable: PropTypes.bool,
  onCheck: PropTypes.func.isRequired,
  onCheckAll: PropTypes.func,
  isCheckedAll: PropTypes.bool.isRequired,
  itemsById: PropTypes.object.isRequired,
  onMove: PropTypes.func,
};

SamplesTable.defaultProps = {
  archive: false,
  list: [],
  excludedColumns: [],
  columnsMap: [
    {
      value: 'sample-name',
      label: 'Item Name',
      component: true,
      expandAction: true,
      sortable: true,
      sortProp: 'title'
    },
    {
      value: 'owner',
      label: 'Owner',
      component: true,
    },
    {
      value: 'date-updated',
      label: 'Updated On',
      expandAction: true,
      sortable: true,
      sortProp: 'dateUpdated'
    },
    {
      value: 'date-created',
      label: 'Created On',
      expandAction: true,
      sortable: true,
      sortProp: 'dateCreated'
    },
    { value: 'data-collection', label: 'Data', component: true },
    {
      value: 'interaction',
      label: 'Views',
      component: true,
    },
    { value: 'action', label: '', component: true },
  ]
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(SamplesTable);


