import { gql } from '@apollo/client/core';

const GET_ITEM = gql`
query TableItem($itemId: ID!) {
  tableItem(id: $itemId) {
    id
    title
    code
    dateCreated
    dateUpdated
    dateFabricated
    description
    isUsedInProtocols
    numberOfTableItemFiles
    interactions {
      comments
      views
    }
    archived
    creator {
      id
      name
    }
    values {
      tableParameterId
      tableItemId
      quantity
      text
      boolean
      linkedTableItem {
        id
        code
        title
        viewerMaxRole
      }
    }
    table {
      tableProtocols {
        id
        title
        description
        dateUpdated
        dateCreated
        creator {
          id
          name
        }
        tableParameters {
          id
          title
          valueType
          titleTableItem {
            code
            id
            title
            viewerMaxRole
          }
          unit {
            id
            name
          }
        }
      }
      id
      title
      viewerMaxRole
      numberOfTableProtocols
    }
    files {
      id
      title
      description
      dateUpdated
      dateCreated
      creator {
        id
        name
      }
      processedFilename
      rawFileDownloadURL
      rawFilename
      processedFileDownloadURL
      parsingConfig {
        id
        code
        dateUsed
        measurement {
          id
          code
          name
        }
        instrument {
          id
          code
          model
          manufacturer {
            id
            code
            name
          }
        }
        parser {
          id
          code
          name
          runtime
          viewType
          dataType
        }
      }
      graphics {
        id
        mode
        graphicsType
        config {
          columns
          x1Index
          xAxis
          xIndex
          xOffsetIndex
          y1Index
          yAxis
          yAxisRight
          yIndex
          yOffsetIndex
          zAxis
        }
        alter {
          target
          transform
        }
      }
    }
  }
}
`;

export default GET_ITEM;
