import styled, { css } from 'styled-components';

import { ACTIONS_PANEL_HEIGHT } from './constants';

import ButtonComponent from '@mui/material/Button';

import {
  COLOR_GREY_LIGHTER,
  COLOR_WHITE,
  MEDIA_QUERIES_XS
} from '../../../styles';

export default styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  left: 0;
  z-index: 3;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  padding: 0 1px 10px;
  background-color: ${COLOR_WHITE};
  height: ${ACTIONS_PANEL_HEIGHT}px;
  overflow: hidden;

  > *:not(:last-child) {
    margin-right: 10px;
  }

  button {
    min-width: auto;
    min-height: 34px;
    padding: 0 8px;
  }

  @media (${MEDIA_QUERIES_XS}) {
    justify-content: flex-start;
    width: calc(100% - 24px);
    top: -5px;
    padding: 0 8px;
  }
`;

export const Counter = styled.div`
  margin-left: auto;
  color: ${COLOR_GREY_LIGHTER};
`;

export const Button = styled(ButtonComponent)`
  min-width: auto !important;
  min-height: 34px;
  padding: 0 8px !important;

  ${props => !props.disabled && css`
    background-color: ${COLOR_WHITE} !important;
  `}
`;
