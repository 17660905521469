import React, { useCallback, useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';
import PT from 'prop-types';
import differenceBy from 'lodash/differenceBy';

import DeleteItemsSubmitDialog from '../DeleteItemsSubmitDialog';

import { isRoleEqualOrHigher, rolesIds } from '../../utils/roles';
import { RESTRICT_PERMISSION, RESTRICT_LINKED } from '../../constants';

const DELETE_ITEM_OBJECTS = gql`
  mutation DeleteTableItems($ids: [ID!]!) {
    deleteTableItems(ids: $ids)
  }
`;

const DeleteItemsDialog = ({ items, onClose, onSuccess }) => {
  const [deleteItems, { loading }] = useMutation(DELETE_ITEM_OBJECTS);

  const excludeItems = useMemo(() => {
    const result = [];

    if(!items.length)
      return result;

    items.forEach(({ id, title, table, isUsedInProtocols }) => {
      if(isUsedInProtocols)
        result.push({ id, itemTitle: title, reason: RESTRICT_LINKED });

      if(!isRoleEqualOrHigher(table.viewerMaxRole, rolesIds.WRITE))
        result.push({ id, itemTitle: title, reason: RESTRICT_PERMISSION });
    });

    return result;
  }, [items]);

  const handleDelete = useCallback(async() => {
    const itemsToDelete = differenceBy(items, excludeItems, 'id');

    try {
      if(items.length) {
        const ids = itemsToDelete.map(({ id }) => id);

        const response = await deleteItems({ variables: { ids } });

        const deletedIds = ids
          .filter((id, i) => response.data.deleteTableItems[i]);

        const teamsToUpdate = new Set(itemsToDelete.map(({ table }) => table.id));

        onSuccess(deletedIds, teamsToUpdate);
      }
    } catch (e) {
      console.error(e.message);
    }
  }, [items, excludeItems, deleteItems, onSuccess]);

  if(!items.length)
    return null;

  return (
    <DeleteItemsSubmitDialog
      open
      onClose={onClose}
      onSubmit={handleDelete}
      forbidden={excludeItems}
      loading={loading}
    />
  );
};

DeleteItemsDialog.propTypes = {
  items: PT.arrayOf(PT.object),
  onClose: PT.func,
  onSuccess: PT.func
};

export default DeleteItemsDialog;
