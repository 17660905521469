import React from 'react';
import PT from 'prop-types';

import Dialog from '@mui/material/Dialog';

import WarningContent from './WarningContent';
import ConfirmationContent from './ConfirmationContent';

const ArchiveItemSubmitDialog = ({
  itemName,
  open,
  onSubmit,
  onClose,
  forbidden,
  partial
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="sm"
  >
    {forbidden ?
      <WarningContent
        itemName={itemName}
        onClose={onClose}
      /> :
      <ConfirmationContent
        itemName={itemName}
        partial={partial}
        onCancel={onClose}
        onSubmit={onSubmit}
      />
    }
  </Dialog>
);

ArchiveItemSubmitDialog.propTypes = {
  itemName: PT.string.isRequired,
  open: PT.bool.isRequired,
  onSubmit: PT.func.isRequired,
  onClose: PT.func.isRequired,
  forbidden: PT.bool,
  partial: PT.bool
};

export default ArchiveItemSubmitDialog;
