import React from 'react';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  actionButton: {
    display: 'inline-block',
    margin: 0,
    padding: '0 0 0 4px',
    textTransform: 'none',
    color: 'inherit',
    textDecoration: 'underline'
  }
}));

const CreateItemFailMessage = () => {
  const classes = useStyles();

  const handleChatInterface = () => {
    window.Intercom('show');
  };

  return (
    <span className={classes.root}>
      <span>
        Failed to create a new item. Please try again or
      </span>
      <Button
        className={classes.actionButton}
        onClick={handleChatInterface}
        component={Link}
      >
        contact support
      </Button>
    </span>
  );
};

export default CreateItemFailMessage;
