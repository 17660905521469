export * from '../../constants';

export const UPDATE_GRAPHICS_CONFIG = 'UPDATE_GRAPHICS_CONFIG';
export const CLEAR_GRAPHICS_CONFIG = 'CLEAR_GRAPHICS_CONFIG';
export const ASSET_TYPE_PROTOCOL = 'protocol';
export const ASSET_TYPE_MEASUREMENT = 'measurement';
export const ASSET_TYPE_TREATMENT = 'treatment';
export const SET_GRAPHICS_CONFIG = 'SET_GRAPHICS_CONFIG';

export const QUICK_CREATE_OPEN = 'QUICK_CREATE_OPEN';
export const QUICK_CREATE_CLOSE = 'QUICK_CREATE_CLOSE';

export const FETCH_OBJECTS_ORDER = 'FETCH_OBJECTS_ORDER';
export const SAVE_OBJECTS_ORDER = 'SAVE_OBJECTS_ORDER';
export const DOWNLOAD_ITEMS_OBJECTS_ACTIVE = 'ACTIVE';
