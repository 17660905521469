import _ from 'lodash';
import { generatePath } from 'react-router-dom';

export const isProdEnv = () =>
  process.env.REACT_APP_ENV === 'production';

export const browserStorage = {
  set(name, value) {
    localStorage.setItem(name, value);
  },
  get(name) {
    const data = localStorage.getItem(name);

    try {
      return JSON.parse(data);
    }
    catch(err) {
      return data;
    }
  },
  delete(name) {
    window.localStorage.removeItem(name);
  }
};

export const mapKeyToId = data => _.reduce(data, (result, value, key) => {
  result.push({
    ...value,
    id: key
  });

  return result;
}, []);

export const computeFilesize = bytes => {
  if(typeof bytes !== 'number') return '';

  if(bytes === 0) return '0 Bytes';

  const k = 1000,
    decimalPoint = 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB'],
    _i = Math.floor(Math.log(bytes) / Math.log(k)),
    i = _i <= 3 ? _i : 3;

  return `(${
    Number.parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint))
  } ${sizes[i]})`;
};

export const getFileData = filename => {
  const breakPoint = (filename.lastIndexOf('.') - 1 >>> 0) + 2;

  return [
    filename.slice(0, breakPoint - 1),
    filename.slice(breakPoint)
  ];
};

export const errorFactory = (source, message) => ({
  source,
  message
});

export const createJSONBlob = data =>
  new Blob([JSON.stringify(data)], { type: 'application/json' });
export const createTextHtmlBlob = data =>
  new Blob([data], { type: 'text/html' });

export async function getFileFromUrl(url, name, defaultType = 'image/jpeg'){
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

export const createBlob = data =>
  data instanceof Blob
    ? data
    : (
      typeof data === 'string'
        ? createTextHtmlBlob(data)
        : createJSONBlob(data)
    );

export class APIError extends Error {
  constructor(source, message, ...params) {
    super(message, ...params);

    this.name = 'APIError';
    this.source = source;

    if(Error.captureStackTrace)
      Error.captureStackTrace(this, APIError);
  }
}

export const getScreenType = () => window.innerWidth <= 768 ? 'mobile' : 'desktop';
export const isMobile = () => getScreenType() === 'mobile';

export const compose = (...fns) =>
  x => fns.reduceRight(
    (acc, fn) => acc.then(fn),
    Promise.resolve(x)
  );

export const generatePathWithSearchParams = (route, params = {}) => {
  const basePath = generatePath(route);
  const searchParams = new URLSearchParams(params).toString();

  return `${basePath}?${searchParams}`;
};

export const parseToExponential = (number) => {
  if(Math.abs(number) === 0)
    return number;

  const withExponential = number.toExponential(3);

  if (Math.abs(number) > 10000){
    const splittedNumber = withExponential.split('e+');
    return `${splittedNumber[0]}*10^${splittedNumber[1]}`;
  } else if(Math.abs(number) < 0.0001){
    const splittedNumber = withExponential.split('e-');
    return `${splittedNumber[0]}*10^-${splittedNumber[1]}`;
  }

  return Math.round(number * 1000) / 1000;
};
