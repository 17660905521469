export * from '../../constants';

/** ROUTES **/
export const routes = {
  SIGN_UP: '/signup',
  SIGN_IN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  EMAIL_VERIFICATION: '/email-verification',
  REGISTRATION_DETAILS: '/registration-details',
  ITEMS_ARCHIVED: '/items/archive',
  PROFILE_SETTINGS: '/profile-settings',
  NOTIFICATION_SETTINGS: '/notification-settings',
  APP: '/apps/:folder?/:title',
  ORGANIZATION: '/organization',
  ITEMS: '/items/:sampleId',
  ALL_ITEMS: '/items',
  TABLES: '/tables',
  /* HOT-FIX: Allow Pervoskite Database Project Users to only view Insights */
  LIMITED_ACCESS_START_PAGE: '/home',
  HOME_PAGE: '/home',
  ITEMS_MY: '/items/my',
  ROOT_PAGE: '/',
  NOT_FOUND: '*',
  EMAILS: '/__/auth/action',
  TABLE_SETTINGS: '/table-settings/:id',
  FOLDER_SETTINGS: '/folder-settings/:id',

  DEPRECATED_NOTIFICATION_SETTINGS: '/notifications-settings',
};

export const COMMON_LINKS = {
  INSIGHTS: '/insights',
  APPS: '/apps',
};

/************/

export const FETCH_SIGN_UP = 'FETCH_SIGN_UP';
export const CREATE_USER = 'CREATE_USER';
export const USER_SIGNED_IN = 'USER_SIGNED_IN';
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const PASSWORD_PROVIDER = 'PASSWORD_PROVIDER';
export const OAUTH_PROVIDER = 'OAUTH_PROVIDER';
export const FETCH_SIGN_IN = 'FETCH_SIGN_IN';

export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';
export const USER_LOG_OUT = 'USER_LOG_OUT';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_MOBILE_VIEW = 'TOGGLE_MOBILE_VIEW';
export const SET_MOBILE_VIEW = 'SET_MOBILE_VIEW';
export const BROWSER_STORAGE_SIDEBAR_OPEN = 'BROWSER_STORAGE_SIDEBAR_OPEN';
export const FIREBASE_AUTH_WATCH = 'FIREBASE_AUTH_WATCH';
export const AUTH_TOKEN = 'AUTH_TOKEN';
export const USER_WATCH = 'USER_WATCH';
export const ROUTE_NAME_UPDATE = 'ROUTE_NAME_UPDATE';
export const SAMPLES_SORT_SETTINGS_UPDATE = 'SAMPLES_SORT_SETTINGS_UPDATE';
export const SAMPLES_ARCHIVE_SORT_SETTINGS_UPDATE = 'SAMPLES_ARCHIVE_SORT_SETTINGS_UPDATE';
export const USER_UPDATE = 'USER_UPDATE';
export const SAVE_CLIENT_LOG = 'SAVE_CLIENT_LOG';
export const USER_ADD_FIELD = 'USER_ADD_FIELD';
export const USER_FIELD_UPDATE = 'USER_FIELD_UPDATE';
export const USER_EMAIL_UPDATE = 'USER_EMAIL_UPDATE';
export const USER_PASSWORD_UPDATE = 'USER_PASSWORD_UPDATE';
export const LINK_PROVIDER = 'LINK_PROVIDER';
export const UNLINK_PROVIDER = 'UNLINK_PROVIDER';
export const SAMPLE_ASSETS_SORT_SETTINGS_UPDATE = 'SAMPLE_ASSETS_SORT_SETTINGS_UPDATE';
export const LAST_VISITED_PAGE = 'LAST_VISITED_PAGE';
export const RESET_ERROR = 'RESET_ERROR';
export const CLEAN_PASSWORD_RESET = 'CLEAN_PASSWORD_RESET';
export const ROLES = [
  { value: Number.parseInt('1111', 2), text: 'owner' },
  { value: Number.parseInt('0111', 2), text: 'admin' },
  { value: Number.parseInt('0011', 2), text: 'write' },
  { value: Number.parseInt('0001', 2), text: 'read' }
];
export const ADD_USER_ACCOUNT = 'ADD_USER_ACCOUNT';
export const REMOVE_USER_ACCOUNT = 'REMOVE_USER_ACCOUNT';
export const SET_AUTH_PERSISTENCE = 'SET_AUTH_PERSISTENCE';
