import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Container, TopBlock } from './styles';

import {
  COLOR_GREY,
} from '../../../styles';

const ItemTitle = ({ id, title, code, description, onItemClick }) => {

  const handleClick = useCallback(() => {
    onItemClick(id);
  }, [id, onItemClick]);

  return (
    <Container>
      <TopBlock
        onClick={handleClick}
      >
        <Typography
          fontSize="14px"
        >
          {title}
          <Typography
            color={COLOR_GREY}
            fontSize="12px"
            noWrap
            ml="4px"
            component="span"
          >
            ({code})
          </Typography>
        </Typography>

      </TopBlock>

      <Tooltip
        title={description}
      >
        <Typography
          color={COLOR_GREY}
          fontSize="12px"
          noWrap
        >
          {description}
        </Typography>
      </Tooltip>
    </Container>
  );
};

ItemTitle.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  code: PropTypes.string,
  description: PropTypes.string,
  onItemClick: PropTypes.func,
};

export default ItemTitle;
