import { gql } from '@apollo/client';
import isPlainObject from 'lodash/isPlainObject';
import pick from 'lodash/pick';

import client from '../../../../services/apollo-client';

const INSIGHTS = gql`
  query Dashboards {
    dashboards {
      id
      url
      title
      displayLoading
      icon
      children {
        id
        url
        title
      }
    }
  }
`;

const SAVED_VIEWS = gql`
  query UserTableItemQueries {
    userTableItemQueries {
      id
      title
      tableItemQueryId
      dashboardId
      dashboardStateId
    }
  }
`;

const DASHBOARD_STATE = gql`
  query DashboardState($id: ID!) {
    dashboardState(id: $id)
  }
`;

const ITEM_QUERY = gql`
  query ItemQuery($id: ID!) {
    tableItemQuery(id: $id) {
      filters {
        createdTimestamp {
          from
          to
        }
        featureSliceByList {
          featureSliceBys {
            featureId
            boolean
            include
            linkItemIds
            quantityMin
            quantityMax
            textValues
            excludeMissingValueFlag
          }
        }
        creatorIds
        tableIds
      }
      search
      sort {
        featureId
        order
        param
      }
      tableHeaderFeatureIDs
    }
  }
`;

const recurseQueryInput = (value) => {
  if(isPlainObject(value)) {
    let _value;

    for(const [k, v] of Object.entries(value)) {
      if(k.startsWith('__') || v == null) continue;

      const _v = recurseQueryInput(v);

      if(_v != null)
        Object.assign(_value ??= {}, { [k]: _v });
    }

    return _value;
  } else if(Array.isArray(value)) {
    return value.map(_v => recurseQueryInput(_v));
  }

  return value;
};

const insights = async (searchParams) => {
  const dashboardId = searchParams.get('dashboardId');

  try {
    const response = await client.query({
      query: INSIGHTS
    });

    let insights = response.data?.dashboards ?? [];

    const insight = insights.find(({ id }) => id === dashboardId);

    return { insights, insight, error: !!(dashboardId && !insight) };
  } catch(e) {
    console.log(e.message);
  }
};

const savedView = async (searchParams) => {
  const viewId = searchParams.get('savedView');

  if(!viewId) return;

  const savedViewsResponse = await client.query({ query: SAVED_VIEWS });

  const views = savedViewsResponse.data?.userTableItemQueries ?? [];
  const view = views.find(({ id }) => id === viewId);

  if(!view) return;

  const itemQueryResponse = await client.query({
    query: ITEM_QUERY,
    variables: { id: view.tableItemQueryId }
  });

  const tableItemQuery = itemQueryResponse.data?.tableItemQuery;
  const queryInput = recurseQueryInput(tableItemQuery);

  let dashboardState = null;

  if(view.dashboardStateId) {
    const dashboardStateResponse = await client.query({
      query: DASHBOARD_STATE,
      variables: { id: view.dashboardStateId }
    });

    dashboardState = dashboardStateResponse.data?.dashboardState;
  }

  return {
    ...pick(view, ['id', 'tableItemQueryId', 'title', 'dashboardId', 'dashboardStateId']),
    queryInput: {
      ...queryInput,
      sort: queryInput.sort ?? { param: 'DATE_UPDATED', order: 'DESC' },
    },
    dashboardState
  };
};

const sharedView = async (searchParams) => {
  const itemQueryId = searchParams.get('itemQueryId');
  const dashboardId = searchParams.get('dashboardId');
  const dashboardStateId = searchParams.get('dashboardStateId');

  if(!itemQueryId) return;

  const itemQueryResponse = await client.query({
    query: ITEM_QUERY,
    variables: { id: itemQueryId }
  });

  return {
    tableItemQueryId: itemQueryId,
    dashboardId,
    dashboardStateId,
    queryInput: recurseQueryInput(itemQueryResponse.data?.tableItemQuery)
  };
};

export default async function initialSavedView({ request }) {
  const result = {
    dataErrors: []
  };
  const { searchParams } = new URL(request.url);

  try {
    const [
      insightsResult,
      savedViewResult,
      itemQueryResult
    ] = await Promise.allSettled([
      insights(searchParams),
      savedView(searchParams),
      sharedView(searchParams)
    ]);

    if(insightsResult.status === 'fulfilled') {
      result.insightsData = insightsResult.value;
    } else {
      result.dataErrors.push(insightsResult.reason);
    }

    if(savedViewResult.status === 'fulfilled') {
      result.view = savedViewResult.value;
    } else {
      result.dataErrors.push(savedViewResult.reason);
    }

    if(itemQueryResult.status === 'fulfilled') {
      result.sharedView = itemQueryResult.value;
    } else {
      result.dataErrors.push(itemQueryResult.reason);
    }
  } catch(e) {
    result.error = e;
  }

  return result;
}
