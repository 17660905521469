const getFiltersWithoutExtraData = (oldFilters, tablesData, itemsSorting) => {
  const oldTables = oldFilters.input.filters.tableIds;

  const activeTables = tablesData.filter(table => oldTables?.includes(table.id));

  let input = {
    ...oldFilters.input,
    tableHeaderFeatureIDs: undefined,
    filters: {}
  };

  if (activeTables.length) {
    const activeTablesFeatures = activeTables
      .reduce((prev, curr) => {
        const ids = curr.tableParameters || [];
        return [...prev, ...ids];
      }, []);

    const featureSliceBys = oldFilters.input.filters?.featureSliceByList?.featureSliceBys
      .filter(feature => activeTablesFeatures?.includes(feature.featureId)) || [];

    const tableHeaderFeatureIDs = oldFilters.input.tableHeaderFeatureIDs?.filter(id => activeTablesFeatures.includes(id));

    input = {
      ...oldFilters.input,
      tableHeaderFeatureIDs: tableHeaderFeatureIDs?.length ? tableHeaderFeatureIDs : undefined,
      filters: {
        ...oldFilters.input.filters,
        featureSliceByList: {
          featureSliceBys
        }
      }
    };
  }

  if(input.sort?.featureId && !input.tableHeaderFeatureIDs?.includes(input.sort.featureId)) {
    input.sort = itemsSorting.sortItems({ param: 'DATE_UPDATED', order: 'DESC' });
  }

  return input;
};

export default getFiltersWithoutExtraData;
