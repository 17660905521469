import mixpanel from 'mixpanel-browser';
import omit from 'lodash/omit';

/**
 * Analytics plugin: Mixpanel
 * @param  {Object} userConfig
 * @param  {string} userConfig.token
 * @param  {boolean} [userConfig.debug=true]
 * @return {Object} - plugin object
 */
export default function mixpanelPlugin(userConfig) {

  return {
    name: 'mixpanel',
    config: {
      token: userConfig.token,
      debug: userConfig.debug ?? true,
      enabled: userConfig.enabled
    },
    initialize: ({ config }) => {
      mixpanel.init(config.token, {
        debug: config.debug,
        opt_out_tracking_by_default: false
      });
    },

    page: ({ payload }) => {
      const { url, path } = payload.properties;

      mixpanel.track('page__view', {
        url,
        path,
        source: 'webapp',
        page: window.document.title,
        component: window.document.title
      });
    },

    track: ({ payload }) => {
      mixpanel.track(payload.event, {
        ...omit(payload.properties, ['additional_info']),
        ...payload.properties?.additional_info,
        source: 'webapp',
        url: document.URL,
        path: window.location.pathname,
        page: document.title
      });
    },

    identify: ({ payload }) => {
      const { authUser, user } = payload.traits;

      mixpanel.identify(payload.userId);
      mixpanel.people.set_once({
        $email: authUser.email,
        $userName: user.name,
        $firstName: user.firstName,
        $lastName: user.lastName,
        $organization_name: user.organization?.title,
        $perovskite: user.perovskiteUser
      });

      mixpanel.set_group('organization_id', user.organization?.id ?? null);

      if(user.organization) {
        mixpanel.get_group('organization_id', user.organization.id).set({
          'Name': user.organization.title
        });
      }
    },

    reset: () => {},

    loaded: () => {
      // return boolean so analytics knows when it can send data to third party
      return true;
    }
  };
}
