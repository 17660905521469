import { useQuery } from '@apollo/client';

import { GET_OPTIONS } from './gql';

const useFilterOptions = ({ tableIds }) => {
  const filtersOptions = useQuery(GET_OPTIONS, {
    variables: { tableIds },
    skip: !tableIds?.length
  });

  return {
    data: filtersOptions.data?.tableItemsFilterOptions,
    loading: filtersOptions.loading
  };
};

export default useFilterOptions;

