import React from 'react';
import PropTypes from 'prop-types';

import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { getChartTypeOptions } from './utils';

const ChartSettingsActions = ({
  disabled,
  onClose,
  onSubmit,
  onAddChart
}) => {
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const handleMenuOpen = ev => setMenuAnchor(ev.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);
  const handleAddChart = chartType => () => {
    handleMenuClose();
    onAddChart(chartType);
  };

  return (
    <DialogActions>
      <Button
        onClick={handleMenuOpen}
        color="primary"
        aria-owns={menuAnchor ? 'simple-menu' : undefined}
        aria-haspopup="true"
      >
        Add Chart
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        {getChartTypeOptions().map(i =>
          <MenuItem
            key={i.value}
            onClick={handleAddChart(i.value)}
          >
            {i.name}
          </MenuItem>
        )}
      </Menu>

      <Button
        onClick={onClose}
        color="primary"
      >
        Cancel
      </Button>

      <Button
        onClick={onSubmit}
        color="primary"
        variant="contained"
        disabled={disabled}
      >
        Save
      </Button>
    </DialogActions>
  );
};

ChartSettingsActions.propTypes = {
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAddChart: PropTypes.func.isRequired
};

export default ChartSettingsActions;
