import styled from 'styled-components';

import { ReactComponent } from './copy-item-url.svg';

export default styled(ReactComponent)`
  width: 1em;
  height: 1em;
  font-size: 24px;
  display: inline-block;
  fill: currentColor;
  user-select: none;
  flex-shrink: 0;
`;
