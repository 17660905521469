import React, { useCallback, useState, useMemo } from 'react';
import PT from 'prop-types';
import { useDispatch } from 'react-redux';
import { gql, useMutation, useQuery } from '@apollo/client';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

import Box from '@mui/material/Box';

import { openAppSnackbarNotification } from '../../../../services/snackbar-notifications/actions';
import useTable from '../../../../hooks/table';
import { hasAccess } from '../../../../utils/roles';
import UsersTable from '../../../../components/TablesAndFoldersSettings/UsersTable';
import usersTableColumns, { columnIdsToAccessKeysMap } from '../../../../components/TablesAndFoldersSettings/UsersTable/columns';
import AddUsers from '../../../../components/TablesAndFoldersSettings/AddUsers';

export const GET_VIEWER = gql`
  query ViewerBasicSessionProfile {
    viewer {
      id
    }
  }
`;

const UPDATE_FOLDER = gql`
  mutation UpdateFolder($id: ID!, $data: UpdateFolderInput!) {
    updateFolder(id: $id, data: $data) {
      id
      iam {
        organization
        users {
          name
          role
          userId
        }
      }
    }
  }
`;

const UsersTab = ({
  folderSettings = {},
  readOnly
}) => {
  const [sort, setSort] = useState({
    param: 'name',
    isAsc: true
  });

  const [updateFolder] = useMutation(UPDATE_FOLDER);

  const dispatch = useDispatch();

  const { data: { viewer } } = useQuery(GET_VIEWER);

  const {
    viewerMaxRole,
    id: folderId,
    title: folderTitle,
    iam: {
      organization: organizationRole,
      users = []
    } = {}
  } = folderSettings;

  const showOrganization = Boolean(organizationRole && hasAccess(organizationRole));
  const sortedUsers = useMemo(() => {
    const result = [...users]
      .map(i => ({
        ...i,
        fullName: `${i.firstName} ${i.lastName}`,
      }))
      .sort((a, b) => {
        const first = a[columnIdsToAccessKeysMap[sort.param]];
        const second = b[columnIdsToAccessKeysMap[sort.param]];

        return second.localeCompare(first);
      });

    if (sort.isAsc) {
      return result.reverse();
    }

    return result;
  }, [sort.isAsc, sort.param, users]);

  const handleSort = useCallback(columnId => {
    setSort(s => ({
      param: columnId,
      isAsc: columnId === s.param ? !s.isAsc : true
    }));
  }, []);

  const handleAddUserSubmit = useCallback(async (users, organization) => {
    try {
      await updateFolder({
        variables: {
          id: folderId,
          data: {
            iam: pickBy({
              organization,
              users
            }, identity)
          }
        }
      });

      if (users?.length) {
        dispatch(
          openAppSnackbarNotification({
            message: `${users.length} users added successfully`,
          })
        );
      }

      if (organization) {
        dispatch(
          openAppSnackbarNotification({
            message: `Organization added successfully`,
          })
        );
      }
    } catch (e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      }));
    }
  }, [dispatch, folderId, updateFolder]);

  const handleRemoveClick = useCallback(async (userId, organization) => {
    try {
      await updateFolder({
        variables: {
          id: folderId,
          data: {
            iam: {
              ...(
                userId
                  ? {
                    users: [{
                      id: userId,
                      role: 'NO_ACCESS'
                    }]
                  }
                  : {}
              ),
              ...(
                organization
                  ? { organization: 'NO_ACCESS' }
                  : {}
              ),
            }
          }
        }
      });
    } catch(e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      }));
    }
  }, [folderId, updateFolder, dispatch]);

  const handleRoleChange = useCallback(async (subjectId, role) => {
    const data = { iam: {} };

    if (subjectId === 'organization') {
      data.iam.organization = role;
    } else {
      data.iam.users = [{ id: subjectId, role }];
    }

    try {
      await updateFolder({
        variables: {
          id: folderId,
          data
        }
      });
    } catch(e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      }));
    }
  }, [folderId, updateFolder, dispatch]);

  const data = [
    showOrganization &&
      {
        isOrganization: true,
        fullName: 'Organization',
        role: organizationRole,
      },
    ...sortedUsers
  ].filter(Boolean);

  const table = useTable({
    columns: usersTableColumns,
    data,
    customState: {
      readOnly,
      tableTitle: folderTitle,
      viewerMaxRole,
      viewerId: viewer?.id,
      onRoleChange: handleRoleChange,
      onRemoveUser: handleRemoveClick,
      sorting: {
        state: sort,
        onSort: handleSort
      }
    }
  });

  return (
    <Box pt="16px">
      <AddUsers
        onSubmit={handleAddUserSubmit}
        defaultUserIds={sortedUsers.map(i => i.userId)}
        readOnly={readOnly}
      />

      <UsersTable table={table} />
    </Box>
  );
};

UsersTab.propTypes = {
  folderSettings: PT.object,
  readOnly: PT.bool
};

export default UsersTab;
