import React, { useCallback, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

import { PARAMETER_VALUE_TYPES } from '../constants';
import { filterTypes } from '../../FiltersPopper/utils';

const useStyles = makeStyles({
  chipsInput: {
    '&.MuiTextField-root': {
      minHeight: 32,

      '& .MuiInputBase-root': {
        paddingRight: '42px',
      }
    },

    '&.MuiTextField-root .MuiInputBase-root': {
      flexGrow: 1,
      padding: '2px',

      '& .MuiSvgIcon-root': {
        width: '18px',
        height: '18px'
      },

      '& .MuiInputBase-input': {
        padding: 0,
        paddingLeft: 7,
      },

      '& .MuiChip-root': {
        marginLeft: '4px',
        marginTop: '2px',
        marginBottom: '2px',
        borderRadius: '5px',
      }
    },
  },
  chip: {
    height: '22px',

    '& .MuiChip-label': {
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.6)'
    },
  },
  chipIcon: {
    '&.MuiSvgIcon-fontSizeSmall': {
      fontSize: '12px'
    }
  }
});

const GET_VIEWER = gql`
  query NewCommentViewerInfo {
    viewer {
      id
      firstName
      lastName
      userName
    }
  }
`;

const ChipsInput = ({ value, onChange, parameter, type }) => {
  const { data: viewerData } = useQuery(GET_VIEWER, {
    fetchPolicy: 'cache-first'
  });

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleChange = useCallback((event, newValue) => {
    onChange(newValue.map(i => {
      if (type === filterTypes.CREATOR_IDS)
        return i.value;

      return i.label;
    }));
  }, [onChange, type]);

  const parameterValuesKey = (data, type) => {
    switch(type) {
      case filterTypes.CREATOR_IDS: {
        const newData = data.map(i => ({ label: `${i.firstName} ${i.lastName}`, value: i.userId, userName: i.userName }))
          .toSpliced(data.findIndex(user => user.userId === viewerData?.viewer.id), 1)
          .sort((a, b) => a.label.localeCompare(b.label));

        return [{
          label: `${viewerData?.viewer.firstName} ${viewerData?.viewer.lastName}`,
          value: viewerData?.viewer.id,
          userName: viewerData?.viewer.userName
        },
        ...newData
        ];
      }
      case PARAMETER_VALUE_TYPES.TEXT:
        return data.textValues.map(i => ({ label: i }));

      case PARAMETER_VALUE_TYPES.BOOLEAN:
        return data.boolean.map(i => ({ label: capitalize(i) }));
    }
  };

  const values = parameterValuesKey(parameter, type);

  const handleToggleOpen = useCallback(() => {
    setOpen(s => !s);
  }, []);

  const chosenValues = useMemo(() => {
    if(!value){
      return [];
    } else if (type === PARAMETER_VALUE_TYPES.BOOLEAN) {
      return value.map(i => {
        return { label: capitalize(i) };
      });
    } else if (type === filterTypes.CREATOR_IDS) {
      return value.map(id => ({ value: id, label: values.find(i => id === i.value).label }));
    }

    return value.map(i => {
      return { label: i };
    });
  }, [type, value, values]);

  const filterOptions = useCallback((options, { inputValue }) => {
    if (!inputValue)
      return options;

    return options.filter(i => i.label.toLowerCase().includes(inputValue?.toLowerCase()) ||
      i.userName?.toLowerCase().includes(inputValue?.toLowerCase()));
  }, []);

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      open={open}
      limitTags={1}
      clearIcon={null}
      isOptionEqualToValue={(a, b) => {
        if (type === filterTypes.CREATOR_IDS)
          return a.value === b.value;

        return a.label.toLowerCase() === b.label.toLowerCase();
      }}
      options={(values ?? [])}
      filterOptions={filterOptions}
      onChange={handleChange}
      onFocus={handleToggleOpen}
      onBlur={handleToggleOpen}
      value={chosenValues}
      renderTags={(tagValue, getTagProps, ownerState) => {
        if (ownerState.focused)
          return null;

        if (tagValue.length === 1)
          return (
            <Typography
              sx={{
                paddingLeft: '4px'
              }}
              noWrap
            >
              {tagValue[0].label}
            </Typography>
          );

        return (
          <Typography
            sx={{
              paddingLeft: '4px'
            }}
          >
            {`${tagValue.length} selected`}
          </Typography>
        );
      }
      }
      renderInput={(params) => {
        let secondaryStyles = null;

        if (!open && value?.length)
          secondaryStyles = {
            '&.MuiTextField-root .MuiInputBase-root': {
              '& .MuiInputBase-input': {
                height: 0
              },
            }
          };

        return (
          <TextField
            {...params}
            className={classes.chipsInput}
            sx={
              secondaryStyles
            }
          />
        );
      }}
      getOptionLabel={(option) => {
        return option.value ?? option.label;
      }}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          key={option.value}
          style={{ padding: '4px' }}
        >
          <Checkbox
            style={{ marginRight: 4 }}
            checked={selected}
          />

          <Box>
            <Tooltip title={option.userName}>
              <Typography
                noWrap
              >
                {option.label}
              </Typography>
            </Tooltip>
          </Box>
        </li>
      )}
    />
  );
};

ChipsInput.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  parameter: PropTypes.shape({
    textValues: PropTypes.array
  })
};

export default ChipsInput;
