import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import withStyles from '@mui/styles/withStyles';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';

import ChartSettings from './ChartSettings';
import ChartSettingsActions from './ChartSettingsActions';

import {
  withDefaultsAndAxes,
  getGraphicsViewType
} from '../../services/graphics/utils';

import {
  mapDataByType,
  getDefaultGraphics,
  validateGraphics,
  mapGraphicsStateToArray,
  transformInitToStateGraphics
} from './utils';

import { SYMBOL_GRAPHICS_NEW } from './constants';

const styles = {
  divider: {
    marginTop: '24px',
    marginBottom: '24px'
  }
};

const ChartSettingsContainer = ({
  settingsOptions,
  classes,
  chartType,
  onClose,
  onSubmit,
  fetching,
  chartData: defaultChartData,
  graphics: initGraphics
}) => {
  const [graphics, setGraphics] = useState(
    initGraphics.length && initGraphics[0].config
      ? transformInitToStateGraphics(initGraphics)
      : getDefaultGraphics(chartType)
  );

  const chartData = useMemo(() =>
    initGraphics.length
      ? defaultChartData
      : mapDataByType(
        defaultChartData,
        chartType,
        { axisValues: { ...initGraphics } }
      ),
  [initGraphics, defaultChartData, chartType]
  );

  const isSubmitDisabled = useMemo(() => {
    fetching || validateGraphics(graphics);
  }, [fetching, graphics]);

  const handleChange = useCallback(({ id, name, value }) => {
    setGraphics(s => ({
      ...s,
      [id]: {
        ...s[id],
        config: {
          ...s[id].config,
          [name]: value
        }
      }
    }));
  }, []);

  const handleChangeGraphicsType = useCallback(({ id, value }) => {
    const viewType = getGraphicsViewType(value);

    setGraphics(s => ({
      ...s,
      [id]: {
        ...s[id],
        config: cloneDeep(withDefaultsAndAxes({}, viewType)),
        type: value,
      }
    }));
  }, []);

  const handleAddChart = useCallback(type => {
    setGraphics(s => ({
      ...s,
      [Date.now()]: {
        type,
        [Symbol.for(SYMBOL_GRAPHICS_NEW)]: true,
        config: withDefaultsAndAxes(
          {},
          getGraphicsViewType(type)
        )
      }
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit(
      mapGraphicsStateToArray(graphics)
    );
  }, [onSubmit, graphics]);

  return (
    <>
      <DialogContent>
        {Object.entries(graphics)
          .map(([id, { type, config }], index) => (
            <React.Fragment key={id}>
              {index ? <Divider classes={{ root: classes.divider }} /> : null}

              <ChartSettings
                id={id}
                graphicsType={type}
                chartType={getGraphicsViewType(type)}
                settingsOptions={settingsOptions}
                data={chartData}
                config={config}
                onChange={handleChange}
                onChangeGraphicsType={handleChangeGraphicsType}
              />
            </React.Fragment>
          ))
        }
      </DialogContent>

      <ChartSettingsActions
        onAddChart={handleAddChart}
        onClose={onClose}
        onSubmit={handleSubmit}
        disabled={isSubmitDisabled}
      />
    </>
  );
};

ChartSettingsContainer.propTypes = {
  settingsOptions: PropTypes.object,
  classes: PropTypes.object,
  chartType: PropTypes.string,
  chartData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetching: PropTypes.bool,
  graphics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      graphicsType: PropTypes.string.isRequired,
      config: PropTypes.object,
      alter: PropTypes.arrayOf(
        PropTypes.shape({
          target: PropTypes.string.isRequired,
          transform: PropTypes.string.isRequired,
        })
      )
    })
  ).isRequired
};

export default withStyles(styles)(ChartSettingsContainer);

