import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSuspenseQuery, useMutation } from '@apollo/client';

import Box from '@mui/material/Box';

import FolderTitle from './FolderTitle';
import TabsBlock from './TabsBlock/TabsBlock';

import { FOLDER_SETTINGS, UPDATE_FOLDER } from './gql';
import { hasAdminAccess } from '../../utils/roles';
import { openAppSnackbarNotification } from '../../services/snackbar-notifications/actions';
import useDocumentTitle from '../../hooks/document-title';
import FolderProperties from './FolderProperties';

const FolderSettings = () => {
  const dispatch = useDispatch();

  const { id: folderId } = useParams();

  const [updateFolder] = useMutation(UPDATE_FOLDER);
  const { data } = useSuspenseQuery(FOLDER_SETTINGS, {
    variables: {
      id: folderId
    }
  });

  useDocumentTitle(`${data.folder.title} Members`);

  const handleInputChange = useCallback((field) => async (value) => {
    try {
      await updateFolder({
        variables: {
          id: folderId,
          data: {
            [field]: value
          }
        }
      });
    } catch (e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      }));
    }
  }, [dispatch, folderId, updateFolder]);

  const readOnly = !hasAdminAccess(data.folder.viewerMaxRole);

  return (
    <Box width="100%" p="24px">
      <Box
        display="flex"
        flexDirection="column"
        gap="6px"
      >
        <FolderTitle
          readOnly={readOnly}
          title={data.folder.title}
          path={data.folder.path}
          onChange={handleInputChange('title')}
        />

        <FolderProperties
          dateUpdated={data.folder.dateUpdated}
        />

        <TabsBlock
          table={data.folder}
          readOnly={readOnly}
        />
      </Box>
    </Box>
  );
};

export default FolderSettings;
