import React from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';

import VerticalLine from './VerticalLine';
import SelectedItemsCounter from './SelectedItemsCounter';
import { COLOR_GREY_LIGHT } from '../../../../styles';

const FullBatchActions = ({
  totalSelected,
  displaySelected,
  onToggleDisplaySelected,
  onArchive,
  onDelete,
  onClear
}) => {
  const actionsList = [
    {
      tooltip: 'Archive selected items',
      Icon: ArchiveIcon,
      onClick: onArchive
    },
    {
      tooltip: 'Delete selected items',
      Icon: DeleteIcon,
      onClick: onDelete
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '3px'
      }}
    >
      <SelectedItemsCounter value={totalSelected} />

      <Tooltip
        title="Compare selected items"
        placement="top"
        enterTouchDelay={0}
      >
        <IconButton
          size="small"
          color="rgb(128, 128, 128)"
          onClick={onToggleDisplaySelected}
          sx={displaySelected ? { backgroundColor: COLOR_GREY_LIGHT } : {}}
        >
          <ListIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      {actionsList.map(({ tooltip, onClick, Icon }) =>
        <Tooltip
          key={tooltip}
          title={tooltip}
          placement="top"
          enterTouchDelay={0}
        >
          <IconButton
            color="rgb(128, 128, 128)"
            onClick={onClick}
            disableRipple
          >
            <Icon
              fontSize="small"
              sx={{ width: '18px', height: '18px' }}
            />
          </IconButton>
        </Tooltip>
      )}

      <VerticalLine />

      <Button
        sx={{
          color: 'rgb(128, 128, 128)',
          minWidth: 0,
          textTransform: 'capitalize',
          flexShrink: '0'
        }}
        startIcon={<CloseIcon fontSize="small" />}
        onClick={onClear}
      >
        Clear
      </Button>
    </Box>
  );
};

FullBatchActions.propTypes = {
  totalSelected: PT.number,
  displaySelected: PT.bool,
  onToggleDisplaySelected: PT.func,
  onArchive: PT.func,
  onDelete: PT.func,
  onClear: PT.func,
};

export default FullBatchActions;
