import firebase from 'firebase/app';
import 'firebase/auth';
import { firebase as config } from '../../configs';
import { routes } from './constants';

let auth;

export const firebaseInit = () => {
  firebase.initializeApp(config);

  auth = firebase.auth();

  return Promise.resolve({
    response: {
      socket: firebase
    }
  });
};

export const getCurrentUser = () => firebase.auth().currentUser;

export function isEmailVerified() {
  return new Promise( resolve => {
    const { currentUser } = auth;
    currentUser.emailVerified ? resolve(currentUser) : currentUser.reload();
  });
}

export async function sendEmailVerification(actionCodeSettings) {
  return auth.currentUser.sendEmailVerification(actionCodeSettings);
}

export const signUpWithPasswordProvider = (email, password) => auth
  .createUserWithEmailAndPassword(email, password)
  .then(user => sendEmailVerification({
    handleCodeInApp: true,
    url: `${window.location.origin}${routes.SIGN_IN}`
  })
    .then(() => user)
    .catch(() => user)
  )
  .then(user => ({ response: { userId: user.uid } }))
  .catch(error => {
    switch (error.code) {
      case 'auth/email-already-in-use':
      case 'auth/invalid-email':
        return { error: { ...error, source: 'email' } };
      case 'auth/weak-password':
        return { error: { ...error, source: 'password' } };
      default:
        return { error: { ...error, source: 'general' } };
    }
  });

export const signInWithPasswordProvider = (email, password, staySignedIn) => {
  const { LOCAL, SESSION } = firebase.auth.Auth.Persistence;

  return auth
    .setPersistence(staySignedIn ? LOCAL : SESSION)
    .then(() => auth.signInWithEmailAndPassword(email, password))
    .then(user => ({
      response: {
        userId: user.uid,
        isNewUser: user.additionalUserInfo.isNewUser
      }
    }))
    .catch(error => ({ error }));
};

export const signInWithOAuthProvider = (providerName, config) => {
  const provider = new firebase.auth.OAuthProvider(providerName);

  try {
    const { scope } = config;

    scope.forEach(val => provider.addScope(val));
  } catch(error) {
    console.error(error);

    return Promise.resolve({
      error: {
        message: 'Failed to parse auth provider scope config'
      }
    });
  }

  return firebase
    .auth()
    .signInWithPopup(provider)
    .then(result => {
      const { user, credential, additionalUserInfo } = result;

      return {
        response: {
          userId: user.uid,
          authCredential: credential,
          isNewUser: additionalUserInfo.isNewUser
        }
      };
    })
    .catch(error => ({ error }));
};

export const sendPasswordResetEmail = email => firebase
  .auth()
  .sendPasswordResetEmail(email, {
    handleCodeInApp: true,
    url: `${window.location.origin}${routes.SIGN_IN}`
  })
  .then(() => ({
    response: {
      message: `Please check your email ${email}.`
    }
  }))
  .catch(error => ({ error }));

export const logout = () => auth.signOut()
  .then(() => ({ response: { code: 'success' } }))
  .catch(error => ({ error }));

export function* linkProvider(providerId) {
  let provider;

  switch (providerId) {
    case 'google.com': {
      provider = new firebase.auth.GoogleAuthProvider();
      break;
    }

    case 'facebook.com': {
      provider = new firebase.auth.FacebookAuthProvider();
      break;
    }

    default:
      return { error: 'Unhandle provider id' };
  }

  try {
    const result = yield auth.currentUser.linkWithPopup(provider);
    return { response: result.user.providerData };
  } catch (error) {
    return { error };
  }
}

export function* unlinkProvider({ providerId, user }) {
  if (!providerId) return { error: 'Provider id is required' };

  try {
    yield user.unlink(providerId);
    return { response: 'ok' };
  } catch (error) {
    return { error };
  }
}

export const verifyPasswordResetCode = async (actionCode) => {
  try {
    return await auth.verifyPasswordResetCode(actionCode);
  } catch (e) {
    return null;
  }
};

export const resetPassword = async (email, actionCode, newPassword, continueUrl) => {
  try {
    await auth.verifyPasswordResetCode(actionCode);
    await auth.confirmPasswordReset(actionCode, newPassword);
    return continueUrl;
  } catch (e) {
    return null;
  }
};

export const recoverEmail = async(oobCode) => {
  try {
    const resp = await auth.checkActionCode(oobCode);
    const email = resp?.data?.email;
    await auth.applyActionCode(oobCode);
    return email;
  } catch (e) {
    return null;
  }
};

export const verifyEmail = async(oobCode) => {
  try {
    const resp = await auth.checkActionCode(oobCode);
    const email = resp?.data?.email;
    await auth.applyActionCode(oobCode);
    return email;
  } catch (e) {
    return null;
  }
};

export const sendPasswordReset = async (email) => {
  try {
    return await auth.sendPasswordResetEmail(email);
  } catch (e){
    return null;
  }
};
