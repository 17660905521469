import React, { useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';

import AllItems from './AllItems';
import LoadingBanner from '../../components/LoadingBanner';

import { GET_FOLDERS, GET_TABLES } from './services';

import { COLOR_PRIMARY, COLOR_WHITE } from '../../styles';

const AllItemsContainer = () => {
  const loaderData = useLoaderData();

  const { data: foldersData } = useQuery(GET_FOLDERS);
  const { data: tablesData } = useQuery(GET_TABLES);

  const tables = useMemo(() => {
    return tablesData?.tables.map(table => ({
      ...table,
      tableParameters: table.tableParameters.map(parameter => parameter.featureId)
    })
    );
  }, [tablesData?.tables]);

  const mergedLoaderData = useMemo(() => ({
    ...loaderData,
    tablesData: tables,
    foldersData: foldersData?.folders
  }), [foldersData?.folders, loaderData, tables]);

  if (!foldersData || !tablesData)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <LoadingBanner
          spinner
          bcgcolor={COLOR_WHITE}
          color={COLOR_PRIMARY}
        />
      </Box>
    );

  return <AllItems loaderData={mergedLoaderData} />;
};

export default AllItemsContainer;
