import React, { useCallback, useState, useMemo, useReducer, useEffect } from 'react';
import { useMutation, NetworkStatus } from '@apollo/client';
import difference from 'lodash/difference';

import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import SamplesTable from '../../components/SamplesTable';
import FilterBar from '../../components/FilterBar';
import EnabledFiltersList from '../../components/EnabledFiltersList';

import useQueryLazyLoading from '../../components/Sample/hooks/query-lazy-loading';

import useFetchingMoreWatcher from '../../components/Sample/hooks/fetching-more-watcher';

import ActionsPanel from './ActionsPanel';
import ItemActions from './ItemActions';
import DeleteItemsSubmitDialog from '../../components/DeleteItemsSubmitDialog';

import { ARCHIVE_SORT_SETTINGS, ITEM_FILTER_PARAMS } from '../../services/samples/constants';
import { DELETE_ITEM_OBJECTS, RESTORE_ITEM_OBJECTS } from '../../services/samples/gql';
import { FETCH_ITEMS } from './services/gql';

import useSamplesList from '../../hooks/items-list';
import { browserStorage } from '../../utils';
import { itemsResetAndDeletion } from '../../services/samples/utils';
import { useDidUpdateEffect } from '../../utils/hooks';
import { actions, initState, reducer } from '../../states/checkedItems/reducer';

import { PageContent } from './styles';
import {
  RESTRICT_LINKED,
  RESTRICT_NOT_ARCHIVED
} from '../../constants';
import useDocumentTitle from '../../hooks/document-title';
import ItemSidebar from '../../components/ItemSidebar';

const columnsMap = [
  {
    value: 'sample-name',
    label: 'Item Name',
    expandAction: true,
    sortProp: 'title',
    sortable: true,
    component: true,
  },
  {
    value: 'owner',
    label: 'Owner',
    component: true,
    sortProp: 'owner_user_id',
    sortable: true,
  },
  { value: 'action', label: '', component: true },
];

const ArchiveSamples = () => {

  useDocumentTitle('Archive');

  const [chosenItemId, setChosenItemId] = useState(null);

  const [
    openDeleteSamplesConfirm,
    setOpenDeleteSamplesConfirm
  ] = useState(false);

  const [sortSettings, setSortSettings] = useState(
    browserStorage.get(ARCHIVE_SORT_SETTINGS) || {}
  );
  const [pageRefElement, setPageRefElement] = useState(null);
  const [isSearchTextExist, setIsSearchTextExist] = useState(false);
  const [
    isCheckedItemsFilterActive,
    setIsCheckedItemsFilterActive
  ] = useState(false);

  const [total, setTotal] = useState(0);
  const [totalFiltered, setTotalFiltered] = useState(0);

  const [disabled, setDisabled] = useState(false);
  const [state, dispatch] = useReducer(reducer, initState);

  const itemsById = state.itemsById;
  const isCheckedAll = state.selectAll;

  const {
    data,
    updateQuery,
    fetchMore,
    search,
    searching,
    loading,
    sort,
    fetchingMore,
    sorting,
    networkStatus
  } = useQueryLazyLoading(
    FETCH_ITEMS,
    { param: ITEM_FILTER_PARAMS.get('ARCHIVED') },
    sortSettings,
    {
      withTotal: true,
      withFilter: isSearchTextExist
    }
  );

  const [restoreItems, {
    loading: isRestoring,
  }] = useMutation(RESTORE_ITEM_OBJECTS, {
    onCompleted(data) {
      const { restoreItemObjects } = data;

      setTotal(s => s - restoreItemObjects.length);

      if (isSearchTextExist) {
        setTotalFiltered(s => s - restoreItemObjects.length);
      }
    }
  });

  const [deleteItems, {
    loading: isDeleting
  }] = useMutation(DELETE_ITEM_OBJECTS, {
    onCompleted({ deleteTableItems }) {
      setTotal(s => s - deleteTableItems.length);

      if (isSearchTextExist) {
        setTotalFiltered(s => s - deleteTableItems.length);
      }
    }
  });

  const {
    mappedSamples,
    visibleSamples,
    filteredListCount,
    allSamples
  } = useSamplesList(data, {
    isCheckedItemsFilterActive,
    itemsById
  });

  const hasNextPage = data?.items?.pageInfo.hasNextPage ?? false;
  const totalNumberOfItems = data?.items?.searchInfo?.totalNumberOfItems;

  const { setItemVisibility } = useFetchingMoreWatcher({
    fetchMore,
    items: visibleSamples,
  }, [hasNextPage, !isCheckedItemsFilterActive, !loading]);

  useEffect(() => {
    const itemsToAdd = difference(mappedSamples.map(sample => sample.id), Object.keys(itemsById));

    if (itemsToAdd.length){
      dispatch({
        type: actions.ADD_ITEMS,
        payload: mappedSamples
          .map(sample => sample.id)
          .filter(id => !Object.keys(itemsById).includes(id))
      });
    }
  }, [mappedSamples, itemsById]);

  useEffect(() => {
    setTotal(totalNumberOfItems);
  }, [totalNumberOfItems]);

  useDidUpdateEffect(() => {
    if (!searching) {
      setTotalFiltered(
        data?.items?.searchInfo?.totalNumberOfFilteredItems ?? 0
      );
    }
  }, [searching]);

  const onItemsListReset = useCallback((ids) => {
    dispatch({
      type: actions.REMOVE_ITEMS,
      payload: ids
    });
  }, []);

  const handleRestoreItem = useCallback(async (item) => {
    const response = await restoreItems({
      variables: {
        input: [{
          itemId: item.id,
          withItem: true
        }]
      }
    });

    const isDeleted = response.data.restoreItemObjects[0];

    if (isDeleted){
      itemsResetAndDeletion({
        updateQuery,
        onItemsListReset,
        itemIdsToDelete: [item.id]
      });
    }

  }, [onItemsListReset, restoreItems, updateQuery]);

  const handleDeleteItemCancel = useCallback(() => {
    setOpenDeleteSamplesConfirm(false);
  }, []);

  const handleDeleteItem = useCallback(async (itemId) => {
    setDisabled(true);

    const response = await deleteItems({
      variables: {
        ids: [itemId]
      }
    });

    if(response.data.deleteTableItems[0]) {
      itemsResetAndDeletion({
        updateQuery,
        onItemsListReset,
        itemIdsToDelete: [itemId]
      });
    }

    setDisabled(false);
  }, [deleteItems, updateQuery, onItemsListReset]);

  const checkedItemsData = useMemo(() => {
    return allSamples.filter(item => {
      return itemsById[item.id] &&
                (itemsById[item.id].checked);
    });
  }, [allSamples, itemsById]);

  const deleteForbidden = useMemo(() => {
    const result = [];

    checkedItemsData.forEach(({ id, title, isUsedInProtocols, archived }) => {
      if(itemsById[id].checked) {
        if(isUsedInProtocols)
          result.push({ id, itemTitle: title, reason: RESTRICT_LINKED });

        if(!archived)
          result.push({ id, itemTitle: title, reason: RESTRICT_NOT_ARCHIVED });
      }
    });

    return result;
  }, [checkedItemsData, itemsById]);

  const handleDeleteItemSubmit = useCallback(async() => {
    setDisabled(true);

    const itemsToIgnore = deleteForbidden.map(({ id }) => id);
    const ids = Object.keys(itemsById)
      .filter((id) =>
        (itemsById[id].checked) &&
                !itemsToIgnore.includes(id));

    const response = await deleteItems({
      variables: {
        ids
      }
    });

    const itemIdsToDelete = response.data.deleteTableItems
      .map((isDeleted, i) => isDeleted ? ids[i] : null)
      .filter(Boolean);

    itemsResetAndDeletion({
      updateQuery,
      onItemsListReset,
      itemIdsToDelete
    });

    setDisabled(false);
    setOpenDeleteSamplesConfirm(false);
  }, [deleteForbidden, deleteItems, itemsById, onItemsListReset, updateQuery]);

  const handleDeleteAction = useCallback(() => {
    setOpenDeleteSamplesConfirm(true);
  }, []);

  const handleSearchTextChange = useCallback(text => {
    search(text, { withFilter: true });

    setIsSearchTextExist(Boolean(text));
  }, [search]);

  const handleRestoreAction = useCallback(async () => {
    setDisabled(true);

    const fullyChecked = [];

    const data = Object
      .entries(itemsById)
      .filter(([, { checked }]) => checked)
      .map(([itemId, { checked }]) => {
        const payload = {
          itemId,
          withItem: checked
        };

        fullyChecked.push(itemId);

        return payload;
      });

    const response = await restoreItems({
      variables: {
        input: data
      },
    });

    const itemsToClear = response.data.restoreItemObjects
      .map((isDeleted, i) => isDeleted ? data[i].itemId : null)
      .filter(Boolean);

    const itemIdsToDelete = fullyChecked
      .filter(id => itemsToClear.includes(id));

    itemsResetAndDeletion({
      updateQuery,
      onItemsListReset,
      itemIdsToDelete
    });

    setDisabled(false);
  }, [itemsById, onItemsListReset, restoreItems, updateQuery]);

  const handleCheck = useCallback((sampleId, checked) => {
    dispatch({
      type: actions.CHECK_ITEM,
      payload: {
        id: sampleId,
        checked
      }
    });
  }, []);

  const handleCheckAll = useCallback((checked) => {
    dispatch({
      type: actions.CHECK_ALL_ITEMS,
      payload: {
        checked
      }
    });
  }, []);

  const renderOptionColumn = useCallback(item => (
    <ItemActions
      item={item}
      onRestore={handleRestoreItem.bind(null, item)}
      onDelete={handleDeleteItem}
      disableDelete={item.isUsedInProtocols || !item.archived}
    />
  ), [handleRestoreItem, handleDeleteItem]);

  const handleVisibilityChange = useCallback((idx, value) => {
    setItemVisibility(idx, value);
  }, [setItemVisibility]);

  const handleSort = useCallback(sortSettings => {
    setSortSettings(sortSettings);

    sort(sortSettings.sortAsc, sortSettings.sortBy);
  }, [sort]);

  const handleFilterSearchRemove = useCallback(() => {
    handleSearchTextChange('');
  }, [handleSearchTextChange]);

  const handleFilterSelectedItemsRemove = useCallback(() => {
    setIsCheckedItemsFilterActive(false);
  }, [setIsCheckedItemsFilterActive]);

  const handleSelectedItemsChipClick = useCallback(() => {
    setIsCheckedItemsFilterActive(true);
  }, [setIsCheckedItemsFilterActive]);

  const handleToggleSidebar = useCallback((id) => {
    setChosenItemId(id ?? null);
  }, []);

  const isAnyItemSelected = useMemo(() => {
    return Object.keys(itemsById)
      .some(id => itemsById[id].checked);
  }, [itemsById]);

  const handleDeleteItemInSydebar = useCallback((id) => {
    itemsResetAndDeletion({
      updateQuery,
      onItemsListReset,
      itemIdsToDelete: [id]
    });
  }, [onItemsListReset, updateQuery]);

  return (
    <Page ref={setPageRefElement}>
      <PageContent loading={networkStatus === NetworkStatus.loading}>
        <PageTitle>Archived Items</PageTitle>

        <FilterBar
          isSearching={loading}
          isSearchTextExist={isSearchTextExist}
          onTextChange={handleSearchTextChange}
        />

        <ActionsPanel
          onDelete={handleDeleteAction}
          onRestore={handleRestoreAction}
          isItemsRestoring={isRestoring}
          isDeleting={isDeleting}
          onSelectedItemsChipClick={handleSelectedItemsChipClick}
          totalNumberOfItems={total}
          totalNumberOfFilteredItems={totalFiltered}
          isSearchTextExist={isSearchTextExist}
          hideCounter={searching}

          itemsById={itemsById}
        />

        <EnabledFiltersList
          isSearchTextExist={isSearchTextExist}
          isAnyItemSelected={isAnyItemSelected}
          isSelectedIdsActive={isCheckedItemsFilterActive}
          onSearchRemove={handleFilterSearchRemove}
          onSelectedItemsRemove={handleFilterSelectedItemsRemove}
        />

        <SamplesTable
          archivePage
          totalListCount={filteredListCount}
          sortSettings={sortSettings}
          list={visibleSamples}
          loading={fetchingMore ? false : loading}
          disabled={disabled}
          onSort={handleSort}
          columnsMap={columnsMap}
          hasNextPage={hasNextPage}
          renderItemActions={renderOptionColumn}
          scrollElement={pageRefElement}
          onVisibilityChange={handleVisibilityChange}
          hideTable={sorting || searching}

          onCheckAll={handleCheckAll}
          onCheck={handleCheck}

          itemsById={itemsById}
          isCheckedAll={isCheckedAll}

          onItemClick={handleToggleSidebar}
        />
      </PageContent>

      <DeleteItemsSubmitDialog
        open={openDeleteSamplesConfirm}
        onClose={handleDeleteItemCancel}
        onSubmit={handleDeleteItemSubmit}
        forbidden={deleteForbidden}
        loading={isDeleting}
      />

      <ItemSidebar
        itemId={chosenItemId}
        onClose={handleToggleSidebar}
        onRemove={handleDeleteItemInSydebar}
        readOnly
      />
    </Page>
  );
};

ArchiveSamples.propTypes = {};

export default ArchiveSamples;
