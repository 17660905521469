import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import RangeInput from './RangeInput';
import ChipsInput from './ChipsInput';
import LinkChipsInput from './LinkChipsInput';
import DateInput from './DateInput';

import { PARAMETER_VALUE_TYPES } from '../constants';
import { filterTypes } from '../../FiltersPopper/utils';

const ValuesGroup = ({ type, filterType, value, onChange, parameter, onError }) => {
  const InputComponent = useMemo(() => {
    if(filterType === filterTypes.CREATOR_IDS){
      return ChipsInput;
    }

    if(filterType === filterTypes.CREATED_DATE){
      return DateInput;
    }

    if(type === PARAMETER_VALUE_TYPES.QUANTITY){
      return RangeInput;
    }

    if(type === PARAMETER_VALUE_TYPES.LINK){
      return LinkChipsInput;
    }

    return ChipsInput;
  }, [filterType, type]);

  return (
    <Box mt="0px">
      <InputComponent
        value={value}
        type={filterType || type}
        filterType={filterType}
        disabled={!parameter}
        onChange={onChange}
        onError={onError}
        parameter={parameter}
      />
    </Box>
  );
};

ValuesGroup.propTypes = {
  showLabel: PropTypes.bool,
  parameter: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  type: PropTypes.oneOf(
    Object.values(PARAMETER_VALUE_TYPES)
  ),
  filterType: PropTypes.oneOf(
    Object.values(filterTypes)
  ),
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string,
    })
  ]),
};

export default ValuesGroup;
