import React, { useCallback, useState } from 'react';
import PT from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import AddFolderDialog from '../../../../../Tables/AddFolderDialog';
import AddTableDialog from '../../../../../Tables/AddTableDialog';
import DeleteFolderDialog from '../../../../../Tables/cells/ActionsCell/DeleteFolderDialog';
import RenameFolderDialog from '../../../../../Tables/cells/ActionsCell/RenameFolderDialog';
import MoveFolderDialog from '../../../../../Tables/cells/ActionsCell/MoveFolderDialog';

import { GET_ITEMS, GET_FOLDERS, GET_TABLES } from '../../../../services';
import { routes } from '../../../../../../services/session/constants';
import { TABS } from '../../../../../TableSettings/constants';

const FolderMenu = ({ anchorEl, onClose, data, onFilterDelete }) => {

  const [addTableDialogOpen, setAddTableDialogOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [addFolderDialogOpen, setAddFolderDialogOpen] = React.useState(false);
  const [openMoveDialog, setOpenMoveDialog] = useState(false);

  const handleAddFolder = useCallback(() => {
    setAddFolderDialogOpen(state => !state);
    onClose();
  }, [onClose]);

  const handleAddTable = useCallback(() => {
    setAddTableDialogOpen(state => !state);
    onClose();
  }, [onClose]);

  const handleMoveFolder = useCallback(() => {
    setOpenMoveDialog(state => !state);
    onClose();
  }, [onClose]);

  const handleRename = useCallback(() => {
    setOpenRenameDialog(state => !state);
    onClose();
  }, [onClose]);

  const handleDelete = useCallback(() => {
    setOpenDeleteDialog(state => !state);
    onClose();
  }, [onClose]);

  const actions = [
    {
      label: 'add folder',
      actionFn: handleAddFolder
    },
    {
      label: 'add table',
      actionFn: handleAddTable
    },
    {
      label: 'move folder',
      actionFn: handleMoveFolder
    },
    {
      label: 'manage members',
      link: generatePath(routes.FOLDER_SETTINGS, {
        id: data.id,
      }),
      state: {
        tab: TABS.MEMBERS.value
      }
    },
    {
      label: 'rename',
      actionFn: handleRename
    },
    {
      label: 'delete',
      actionFn: handleDelete
    }
  ];

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        MenuListProps={{ dense: true }}
      >
        {actions.map(i => (
          <MenuItem
            key={i.label}
            onClick={i.actionFn}
            component={i.link ? Link : MenuItem}
            to={i.link ?? null}
            state={i.state}
          >
            <ListItemText sx={{ textTransform: 'capitalize' }}>
              {i.label}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>

      {addFolderDialogOpen ?
        <AddFolderDialog
          onClose={handleAddFolder}
          defaultFolder={data.id}
          refetchList={[GET_FOLDERS, GET_ITEMS]}
        />
        : null
      }

      {addTableDialogOpen ?
        <AddTableDialog
          onClose={handleAddTable}
          defaultFolder={data.id}
          refetchList={[GET_TABLES, GET_ITEMS]}
        /> : null
      }

      {openMoveDialog ?
        <MoveFolderDialog
          onClose={handleMoveFolder}
          id={data.id}
          defaultFolder={data.parentFolderId}
          refetchList={[GET_ITEMS]}
        /> :
        null
      }

      {openDeleteDialog ?
        <DeleteFolderDialog
          id={data.id}
          onClose={handleDelete}
          refetchList={[GET_ITEMS]}
          onDelete={onFilterDelete}
        />
        : null
      }

      {openRenameDialog ?
        <RenameFolderDialog
          id={data.id}
          onClose={handleRename}
          defaultName={data.title}
          refetchList={[GET_ITEMS]}
        />
        : null
      }
    </>
  );
};

FolderMenu.propTypes = {
  anchorEl: PT.instanceOf(Element),
  data: PT.shape({
    id: PT.string.isRequired,
    title: PT.string.isRequired,
    parentFolderId: PT.string,
  }).isRequired,
  onClose: PT.func.isRequired,
  onFilterDelete: PT.func.isRequired
};

export default FolderMenu;
