import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import MenuIcon from '@mui/icons-material/Menu';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';

import DeleteItemSubmitDialog from '../../../components/DeleteItemSubmitDialog';

import { ItemActionsMenu } from './styles';

import {
  RESTRICT_LINKED,
  RESTRICT_NOT_ARCHIVED
} from '../../../constants';

const ItemActions = ({ item, onRestore, onDelete }) => {
  const { id, title } = item;

  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleToggleMenu = useCallback(e => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRestore = useCallback(() => {
    onRestore(id);
    handleClose();
  }, [onRestore, id, handleClose]);

  const handleDelete = useCallback(() => {
    handleClose();
    setDeleteDialogOpen(true);
  }, [handleClose]);

  const handleDeleteDialogClose = useCallback(() => {
    setDeleteDialogOpen(false);
  }, []);

  const handleDeleteDialogSubmit = useCallback(() => {
    setDeleteDialogOpen(false);
    onDelete(id);
  }, [onDelete, id]);

  const handleButtonHovered = useCallback(() => {
    setTooltipOpen(true);
  }, []);

  const handleButtonUnHovered = useCallback(() => {
    setTooltipOpen(false);
  }, []);

  const deleteForbidden = useMemo(() => {
    if(item.isUsedInProtocols)
      return { reason: RESTRICT_LINKED };

    if(!item.archived)
      return { reason: RESTRICT_NOT_ARCHIVED };
  }, [item]);

  const elementId = `simple-menu-${id}`;

  return (
    <>
      <div
        className="action-container"
      >
        <Tooltip
          title="Item Options"
          open={tooltipOpen}
        >
          <Button
            aria-owns={anchorEl ? elementId : null}
            aria-haspopup="true"
            onClick={handleToggleMenu}
            onMouseOver={handleButtonHovered}
            onMouseLeave={handleButtonUnHovered}
          >
            <MenuIcon />
          </Button>
        </Tooltip>

        <ItemActionsMenu
          id={elementId}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleRestore}>
            <UnarchiveIcon />
            Restore from Archive
          </MenuItem>

          <Paper component="span">
            <MenuItem
              onClick={handleDelete}
            >
              <DeleteIcon />
              Delete
            </MenuItem>
          </Paper>
        </ItemActionsMenu>
      </div>

      <DeleteItemSubmitDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onSubmit={handleDeleteDialogSubmit}
        itemName={title}
        forbidden={deleteForbidden}
      />
    </>
  );
};

ItemActions.propTypes = {
  item: PropTypes.object.isRequired,
  onRestore: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default ItemActions;
