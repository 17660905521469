import React, { useCallback } from 'react';
import PT from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import { sidebarSources } from '../../../../../../../analytics/constants';
import { MEDIA_QUERIES_MOBILE } from '../../../../../../../styles';
import { rolesIds } from '../../../../../../../utils/roles';

import DesktopLink from './DesktopLink';
import MobileLink from './MobileLink';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',

    '& .edit-icon': {
      opacity: 0,
      width: 14,
      height: 14,
      marginLeft: 8,
      cursor: 'pointer'
    },

    '&:hover': {
      '& .edit-icon': {
        opacity: 1
      }
    }
  }
});

const ViewLinkValue = ({
  data,
  onClick,
  onLink,
  readOnly
}) => {
  const {
    id: linkId,
    title: linkTitle,
    viewerMaxRole,
  } = data ?? {};
  const classes = useStyles();
  const isMobileView = useMediaQuery(`(${MEDIA_QUERIES_MOBILE})`);

  const handleLink = useCallback(() => {
    onLink(linkId, null, sidebarSources.TABLE_VALUE_LINK);
  }, [onLink, linkId]);

  const noAccess = viewerMaxRole === rolesIds.NO_ACCESS;
  const canEdit = !readOnly;

  return (
    <Box
      display="flex"
      alignItems="center"
      flexGrow="1"
      width="100%"
      className={classes.root}
    >
      {isMobileView
        ? (
          <MobileLink
            linkTitle={linkTitle}
            onLink={handleLink}
            noAccess={noAccess}
            canEdit={canEdit}
            onEdit={onClick}
          />
        )
        : (
          <DesktopLink
            linkTitle={linkTitle}
            onLink={handleLink}
            onEdit={onClick}
            noAccess={noAccess}
            canEdit={canEdit}
          />
        )
      }
    </Box>
  );
};

ViewLinkValue.propTypes = {
  readOnly: PT.bool.isRequired,
  onClick: PT.func,
  onLink: PT.func,
  data: PT.shape({
    id: PT.string,
    title: PT.string,
    viewerMaxRole: PT.string
  })
};

export default ViewLinkValue;
