import React, { useEffect, memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import Divider from '@mui/material/Divider';

import TableRowContent from './TableRowContent';

import { hasAdminAccess } from '../../utils/roles';

import {
  TableRowStyled,
  TableCell,
  Checkbox,
} from './styles';

const TableRow = ({
  data,
  isChecked,
  isDisabled,
  mobile,
  columnsMap,
  onArchive,
  onDelete,
  onAddProtocol,
  onAddMeasurement,
  onCheck,
  archivePage,
  renderItemActions,
  onVisibilityChange,
  listItemIndex,
  objectsById = {},
  onItemClick
}) => {

  const { ref, inView } = useInView({
    rootMargin: '-140px 0px -40px', // handling a sticky-header margin
  });

  useEffect(() => {
    onVisibilityChange(listItemIndex, inView);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [inView]);

  const handleCheck = useCallback(() => {
    onCheck(data.id, !isChecked);
  }, [onCheck, data.id, isChecked]);

  const handleArchive = useCallback(withItem => {
    onArchive(data.id, withItem);
  }, [data.id, onArchive]);

  const handleDelete = useCallback(() => {
    onDelete(data.id);
  }, [data.id, onDelete]);

  const isIndeterminate = useMemo(
    () => !isChecked &&
      Object.values(objectsById || {}).some(({ checked }) => checked),
    [objectsById, isChecked]
  );

  return (
    <TableRowStyled>
      {isDisabled && <div className="table-cell-disable-banner" />}

      <div className="table-cell-main" ref={ref}>
        <TableCell
          className="checkbox-cell"
          mobile={mobile}
        >
          <Checkbox
            color="primary"
            checked={isChecked}
            disabled={isDisabled}
            onChange={handleCheck}
            indeterminate={isIndeterminate}
            className={isIndeterminate ? 'indeterminate' : ''}
            disableRipple
          />
        </TableCell>

        {columnsMap.map(c =>
          <TableCell
            mobile={mobile}
            className={`${c.value}`}
            key={`${c.value}${data.id}`}
          >
            <TableRowContent
              type={c.value}
              data={data}
              onArchive={handleArchive}
              onDelete={handleDelete}
              onAddMeasurement={onAddMeasurement}
              onAddProtocol={onAddProtocol}
              renderItemActions={renderItemActions}
              adminAccess={archivePage || hasAdminAccess(data.viewerMaxRole)}
              onItemClick={onItemClick}
            />
          </TableCell>
        )}
      </div>

      <Divider />
    </TableRowStyled>
  );
};

TableRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    viewerMaxRole: PropTypes.string,
    iam: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    objectsStat: PropTypes.object.isRequired
  }).isRequired,
  isChecked: PropTypes.bool.isRequired,
  isCheckedAll: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
  mobile: PropTypes.bool,
  columnsMap: PropTypes.array.isRequired,
  objectsById: PropTypes.object,
  archivePage: PropTypes.bool.isRequired,
  onArchive: PropTypes.func,
  onDelete: PropTypes.func,
  onAddProtocol: PropTypes.func.isRequired,
  onAddMeasurement: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  renderItemActions: PropTypes.func,
  onUpdateDescription: PropTypes.func,
  onVisibilityChange: PropTypes.func.isRequired,
  listItemIndex: PropTypes.number.isRequired,
  itemsById: PropTypes.object.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default memo(TableRow);
