import React, { useCallback, useMemo } from 'react';
import PT from 'prop-types';
import differenceBy from 'lodash/differenceBy';

import ArchiveItemsSubmitDialog from '../ArchiveItemsSubmitDialog';

import { isRoleEqualOrHigher, rolesIds } from '../../utils/roles';
import { RESTRICT_PERMISSION } from '../../constants';

const ArchiveItemsDialog = ({ items, onClose, onSubmit }) => {
  const excludeItems = useMemo(() => {
    const result = [];

    if(!items.length)
      return result;

    items.forEach(({ id, title, table }) => {
      if(!isRoleEqualOrHigher(table.viewerMaxRole, rolesIds.WRITE))
        result.push({ id, itemTitle: title, reason: RESTRICT_PERMISSION });
    });

    return result;
  }, [items]);

  const handleArchive = useCallback(() => {
    const toArchive = differenceBy(items, excludeItems, 'id');

    onSubmit(toArchive);
  }, [items, excludeItems, onSubmit]);

  if(!items.length)
    return null;

  return (
    <ArchiveItemsSubmitDialog
      open
      onClose={onClose}
      onSubmit={handleArchive}
      forbidden={excludeItems}
    />
  );
};

ArchiveItemsDialog.propTypes = {
  items: PT.arrayOf(PT.object),
  onClose: PT.func,
  onSubmit: PT.func
};

export default ArchiveItemsDialog;
