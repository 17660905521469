import styled, { css } from 'styled-components';
import {
  COLOR_PAGE_BACKGROUND,
  MEDIA_QUERIES_XS,
  COLOR_WHITE
} from '../../styles';

export default styled.div`
  width: 100%;
  padding: 24px;
  padding-top: 0;
  background-color: ${COLOR_PAGE_BACKGROUND};
  overflow-y: auto;

  .page-title {
    padding: 0;
  }

  .pane {
    height: 24px;
    background-color: ${COLOR_PAGE_BACKGROUND};
  }

  @media (${props => props.mediaQueries || MEDIA_QUERIES_XS}) {
    padding: 0;

    .pane {
      height: 0;
    }

    .page-title {
      padding: 8px;
    }
  }
`;

export const PageContentMixin = () =>
  css`
    position: relative;
    width: calc(98% - 46px);
    padding: 16px 30px;
    background-color: ${COLOR_WHITE};

    @media (${props => props.mediaQueries || MEDIA_QUERIES_XS}) {
      padding: 0;
      padding-bottom: 70px;
      width: 100%;
    }
  `;

export const TablePageMixin = () =>
  css`
    width: 100%;
    overflow-x: hidden;
    overflow-y: ${props => props.loading ? 'hidden' : 'auto'};

    @media (${props => props.mediaQueries || MEDIA_QUERIES_XS}) {
      ::-webkit-scrollbar {
        width: 0px;  /* remove scrollbar space */
        background: transparent;  /* optional: just make scrollbar invisible */
      }
      ::-webkit-scrollbar-thumb {
          background: transparent;
      }
    }
  `;
