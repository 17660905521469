import React, { useCallback, useState } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import SortIcon, { SORTING_PARAMS } from '../../SortIcon';
import ColumnHeaderMenu from './ColumnHeaderMenu';
import { COLOR_GREY_DARKER, COLOR_GREY_LIGHTER } from '../../../styles';
import { COL_HEADER_HEIGHT_PX } from '../constants';
import { sidebarSources } from '../../../analytics/constants';

const PrimaryHeader = ({
  id,
  children,
  className,
  order,
  linkId,
  noAccess,
  onLinkClick,
  onSort,
  onRemove,
  sortParam
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleLinkClick = useCallback(ev => {
    // prevent sorting action to be called
    // when user clicks on the link
    ev.stopPropagation();

    onLinkClick(linkId, null, sidebarSources.TABLE_COLUMN_NAME_LINK);
  }, [onLinkClick, linkId]);

  const handleActionsClick = useCallback(e => {
    e.stopPropagation();

    setMenuAnchorEl(e.currentTarget);
  }, []);

  const handleMenuClose = useCallback((e) => {
    e.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const handleSort = useCallback(value => (e) => {
    e.stopPropagation();

    setMenuAnchorEl(null);

    onSort({
      order: value,
      featureId: id,
      param: sortParam
    });
  }, [id, onSort, sortParam]);

  const handleRemove = useCallback(e => {
    e.stopPropagation();

    setMenuAnchorEl(null);

    onRemove();
  }, [onRemove]);

  const linkProps = noAccess ?
    {
      sx: {
        color: '#9e9e9e',
        textDecorationColor: '#9e9e9e'
      }
    } :
    {
      onClick: handleLinkClick
    };

  const isActive = Boolean(order);

  return (
    <Box className={className}>
      {linkId ?
        <Link {...linkProps}>{children}</Link> :
        children
      }

      {isActive && <SortIcon order={order} />}

      <MoreVertIcon
        onClick={handleActionsClick}
        sx={{
          fontSize: 16,
          color: isActive ? COLOR_GREY_DARKER : COLOR_GREY_LIGHTER,
          ':hover': {
            color: COLOR_GREY_DARKER
          }
        }}
      />

      <ColumnHeaderMenu
        anchorEl={menuAnchorEl}
        onClose={handleMenuClose}
        onSortAsc={sortParam && handleSort(SORTING_PARAMS.ASC)}
        onSortDesc={sortParam && handleSort(SORTING_PARAMS.DESC)}
        onRemove={handleRemove}
      />
    </Box>
  );
};

PrimaryHeader.propTypes = {
  id: PT.string,
  sortParam: PT.string,
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
  isDefaultColumn: PT.bool,
  className: PT.string,
  sortable: PT.bool,
  order: PT.oneOf(Object.keys(SORTING_PARAMS)),
  linkId: PT.string,
  noAccess: PT.bool,
  onLinkClick: PT.func,
  onSort: PT.func,
  onRemove: PT.func,
};

export default styled(PrimaryHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  line-height: ${COL_HEADER_HEIGHT_PX / 2}px;

  a {
    overflow: hidden;
  }
`;
