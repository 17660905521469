import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import { Container } from './styles';

import { parseToExponential } from '../../../../../../utils';
import Tooltip from '@mui/material/Tooltip';

const QuantityValue = ({ data }) => {

  const shortenedQuantity = useMemo(() => {
    return typeof data.quantity === 'number' ?
      parseToExponential(data.quantity)  : '';
  }, [data.quantity]);

  return (
    <Container>
      <Tooltip title={data.quantity}>
        <Typography
          sx={{
            fontSize: '14px'
          }}
        >
          {shortenedQuantity}
        </Typography>
      </Tooltip>

      {typeof data.quantity === 'number' ?
        <Typography
          sx={{
            fontSize: '14px'
          }}
        >
          {data.unit?.name}
        </Typography> :
        null
      }
    </Container>
  );
};

QuantityValue.propTypes = {
  data: PropTypes.object.isRequired
};

export default QuantityValue;
