import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import format from 'date-fns/format';
import { gql, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Draggable } from 'react-beautiful-dnd';

import ConfirmationDialog from './ConfirmationDialog';
import EditProtocolForm from './EditProtocolForm';

import { openAppSnackbarNotification } from '../../../../../services/snackbar-notifications/actions';

const UPDATE_PROTOCOL = gql`
  mutation UpdateTableProtocol($id: ID!, $hash: String!, $data: TableProtocolInput!) {
    updateTableProtocol(id: $id, hash: $hash, data: $data) {
      hash
      id
      title
      description
      creator {
        id
        name
      }
      dateCreated
      dateUpdated
      tableParameters {
        id
        title
        titleTableItem {
          id
          title
          code
        }
        valueType
        unit {
          id
          name
        }
      }
    }
  }
`;

const Protocol = ({ index, data, table, readOnly, onDelete, onUpdated }) => {
  const { id, title, creator } = data;

  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteProtocolDialogOpen, setDeleteProtocolDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [updateTableProtocol, { loading, error }] = useMutation(UPDATE_PROTOCOL);

  const dispatch = useDispatch();

  const handleExpand = useCallback(() => {
    setExpanded(true);
  }, []);

  const handleCollapse = useCallback(() => {
    setExpanded(false);
  }, []);

  const handleOpenMenu = useCallback((ev) => {
    setAnchorEl(ev.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDelete = useCallback(() => {
    setAnchorEl(null);
    setDeleteProtocolDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDeleteProtocolDialogOpen(false);
  }, []);

  const handleSubmitDialog = useCallback(() => {
    setDeleteProtocolDialogOpen(false);
    onDelete(data.id);
  }, [data.id, onDelete]);

  const handleEditProtocol = useCallback(async (payload) => {
    try {
      await updateTableProtocol({
        variables: {
          hash: data.hash,
          id: data.id,
          data: payload
        }
      });

      setExpanded(false);
      onUpdated();
      dispatch(openAppSnackbarNotification({
        message: 'The protocol has been updated',
        variant: 'INFO'
      }));
    } catch(e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      }));
    }
  }, [onUpdated, data, updateTableProtocol, dispatch]);

  const dateCreated = format(data.dateCreated, '\'Created on \'dd/MM/yyyy \'at\' HH:mm');
  const dateUpdated = format(data.dateUpdated, '\'Updated on \'dd/MM/yyyy \'at\' HH:mm');

  return (
    <>
      <Draggable
        draggableId={id}
        index={index}
        isDragDisabled={readOnly}
      >
        {provided => (
          <Box
            borderRadius={expanded ? '4px' : null}
            borderBottom={expanded ? '.5px solid #B9B9B9' : null}
            sx={{
              backgroundColor: expanded ? '#f6f6f6' : '#F5FCFD'
            }}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <Box
              p="10px"
              display="flex"
              alignItems="center"
              gap="10px"
              borderBottom=".5px solid #B9B9B9"
            >
              <IconButton
                aria-label="drag"
                size="small"
                disabled={readOnly}
                {...provided.dragHandleProps}
              >
                <DragIndicatorIcon fontSize="inherit" />
              </IconButton>

              <Box
                flexGrow="1"
                onClick={handleExpand}
                sx={{
                  cursor: 'pointer'
                }}
              >
                <Typography
                  variant="body2"
                  lineHeight="24px"
                  fontWeight="500"
                >
                  {title}
                </Typography>

                <Box
                  display="flex"
                  alignItems="center"
                  gap="12px"
                >
                  <Typography
                    variant="caption"
                    lineHeight="16px"
                    sx={{ color: '#666' }}
                  >
                    {creator.name}
                  </Typography>

                  <Typography
                    variant="caption"
                    lineHeight="16px"
                    sx={{ color: '#666' }}
                  >
                    {dateCreated}
                  </Typography>

                  {data.dateCreated !== data.dateUpdated ?
                    <Typography
                      variant="caption"
                      lineHeight="16px"
                      sx={{ color: '#666' }}
                    >
                      {dateUpdated}
                    </Typography> :
                    null
                  }
                </Box>
              </Box>

              {readOnly ?
                null :
                <IconButton
                  aria-label="drag"
                  size="small"
                  onClick={handleOpenMenu}
                >
                  <MoreVertIcon fontSize="inherit" />
                </IconButton>
              }

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
              </Menu>
            </Box>

            <Collapse in={expanded}>
              {expanded ?
                <EditProtocolForm
                  initialData={data}
                  protocols={table.tableProtocols}
                  readOnly={readOnly}
                  loading={loading}
                  error={error}
                  onCancel={handleCollapse}
                  onSubmit={handleEditProtocol}
                /> :
                null
              }
            </Collapse>
          </Box>
        )}
      </Draggable>

      <ConfirmationDialog
        open={deleteProtocolDialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitDialog}
      />
    </>
  );
};

const protocolShape = PT.shape({
  hash: PT.string,
  id: PT.string,
  title: PT.string,
  creator: PT.shape({
    name: PT.string
  }),
  dateCreated: PT.number,
  dateUpdated: PT.number
});

Protocol.propTypes = {
  index: PT.number,
  data: protocolShape,
  table: PT.shape({
    tableProtocols: PT.arrayOf(protocolShape)
  }),
  readOnly: PT.bool,
  onDelete: PT.func,
  onUpdated: PT.func
};

export default Protocol;
