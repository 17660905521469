import styled from 'styled-components';
import { COLOR_PRIMARY } from '../../../styles';

export const Container = styled('div')`
  max-width: 100%;
`;

export const TopBlock = styled('div')({
  '& .MuiTypography-root': {
    display: 'inline-block',
    verticalAlign: 'bottom',
    lineHeight: 'normal',
  },
  '&:hover': {
    cursor: 'pointer',
    '& .MuiTypography-root': {
      textDecoration: 'underline',
      color: COLOR_PRIMARY
    }
  }
});
