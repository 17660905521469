import React, { useCallback } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const FetchMore = ({
  className,
  sx,
  rootRef,
  loading,
  hasMore,
  onFetchMore
}) => {
  const onVisibilityChange = useCallback(inView => {
    if(inView) {
      onFetchMore();
    }
  }, [onFetchMore]);

  const { ref } = useInView({
    root: rootRef?.current,
    rootMargin: '0px 0px 1500px 0px',
    skip: !rootRef?.current || !hasMore,
    onChange: onVisibilityChange
  });

  return (
    <Box className={className} sx={sx}>
      {loading ?
        <CircularProgress size={32} /> :
        <div className="visibility-placeholder" ref={ref}>&nbsp;</div>
      }
    </Box>
  );
};

FetchMore.propTypes = {
  className: PT.string,
  sx: PT.object,
  rootRef: PT.oneOfType([
    PT.func,
    PT.shape({ current: PT.instanceOf(Element) })
  ]),
  loading: PT.bool,
  hasMore: PT.bool,
  onFetchMore: PT.func
};

export default styled(FetchMore)`
  height: 50px;
  position: sticky;
  left: 0;
  display: flex;
  align-items: center;

  .visibility-placeholder {
    line-height: 20px;
  }
`;
