import React from 'react';
import PT from 'prop-types';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const ConfirmationContent = ({
  itemName,
  onCancel,
  onSubmit,
  partial
}) => (
  <>
    <DialogTitle>Confirm the archive action</DialogTitle>

    <DialogContent>
      <DialogContentText>
        {partial ?
          'Are you sure you want to archive all selected protocols and measurements?' :
          `Are you sure you want to archive "${itemName}"`
        }
      </DialogContentText>
    </DialogContent>

    <DialogActions sx={{ p: 2 }}>
      <Button
        onClick={onCancel}
        color="primary"
      >
        Cancel
      </Button>

      <Button
        onClick={onSubmit}
        variant="contained"
        color="primary"
      >
        Yes, archive
      </Button>
    </DialogActions>
  </>
);

ConfirmationContent.propTypes = {
  itemName: PT.string.isRequired,
  onCancel: PT.func.isRequired,
  onSubmit: PT.func.isRequired,
  partial: PT.bool
};

export default ConfirmationContent;
