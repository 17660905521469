import { gql } from '@apollo/client';

export const GET_UNREAD_NOTIFICATIONS_COUNT = gql`
  query UnreadNotifications {
    unreadNotifications
  }
`;

export const GET_NOTIFICATIONS = gql`
  query Notifications($first: Int, $after: String) {
    notifications(first: $first, after: $after) {
      edges {
        cursor
        node {
          id
          read
          timestamp
          eventType

          ... on CommentNotification {
            user {
              name
              ... on CurrentUser {
                avatarColor
                picture {
                  downloadURL
                }
              }
            }
            item {
              id
              type
              title
              archived
            }
          }

          ... on ShareViewNotification {
            url
            icon
            title
            user {
              name

              ... on CurrentUser {
                avatarColor
                picture {
                  downloadURL
                }
              }
            }
          }

          ... on ShareFolderNotification {
            role
            folder {
              id
              title
            }
            user {
              id
              name
            }
          }

          ... on ShareTableNotification {
            role
            table {
              id
              title
            }
            user {
              id
              name
            }
          }
        }
      }

      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: ID!) {
    deleteNotification(id: $id)
  }
`;

export const SET_READ_NOTIFICATION = gql`
  mutation SetReadNotification($id: ID!, $read: Boolean!) {
    setReadNotification(id: $id, read: $read) {
      id
    }
  }
`;

export const DELETE_ALL_NOTIFICATIONS = gql`
  mutation DeleteAllNotifications {
    deleteAllNotifications
  }
`;

export const SET_READ_ALL_NOTIFICATIONS = gql`
  mutation SetReadAllNotifications {
    setReadAllNotifications
  }
`;
