import React, { useCallback } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import CheckIcon from '@mui/icons-material/Check';

import SelectedItemsCounter from './SelectedItemsCounter';

const ShortBatchActions = ({
  totalSelected,
  displaySelected,
  onArchive,
  onDelete,
  onToggleDisplaySelected,
  onClear
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((e) => {
    e.preventDefault(); // In order to avoid further "handleClick" execution
    setAnchorEl(null);
  }, []);

  const actionsList = [
    {
      label: 'Archive',
      Icon: ArchiveIcon,
      onClick: onArchive,
    },
    {
      label: 'Delete',
      Icon: DeleteIcon,
      onClick: onDelete,
    },
    {
      label: 'Compare',
      Icon: ListIcon,
      onClick: onToggleDisplaySelected,
      selected: displaySelected
    }
  ];

  return (
    <Box display="flex" width="100%" justifyContent="space-between">
      <Box flexGrow="1">
        <SelectedItemsCounter value={totalSelected} />
      </Box>

      <Button
        sx={{
          color: 'rgb(128, 128, 128)',
          minWidth: 0,
          textTransform: 'capitalize',
          paddingRight: '8px',
          paddingLeft: '8px',
          marginRight: '16px',
          height: '28px'
        }}
        startIcon={<CloseIcon sx={{ width: '18px', height: '18px' }} />}
        onClick={onClear}
      >
        Clear
      </Button>

      <Button
        variant="contained"
        disableElevation
        onClick={handleClick}
        size="small"
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          textTransform: 'none',
          height: '28px'
        }}
      >
        Actions
      </Button>

      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-start"
      >
        <Paper sx={{ mt: '4px', width: '160px' }}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              onClick={handleClose}
              autoFocusItem={open}
              id="composition-menu"
              aria-labelledby="composition-button"
            >
              {actionsList.map(({ Icon, onClick, label, selected }) =>
                <MenuItem
                  key={label}
                  onClick={onClick}
                  disableRipple
                  sx={{
                    display: 'flex',
                    gap: '8px'
                  }}
                >
                  <Icon sx={{ width: '18px', height: '18px' }} />

                  <Typography fontSize="small">
                    {label}
                  </Typography>

                  {selected &&
                    <Box
                      display="flex"
                      flexGrow="1"
                      justifyContent="flex-end"
                    >
                      <CheckIcon
                        sx={{
                          width: '14px',
                          height: '14px'
                        }}
                      />
                    </Box>
                  }
                </MenuItem>
              )}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Box >
  );
};

ShortBatchActions.propTypes = {
  onDownload: PT.func.isRequired,
  onArchive: PT.func.isRequired,
  onDelete: PT.func.isRequired,
  onClear: PT.func.isRequired,
  onToggleDisplaySelected: PT.func.isRequired,
  totalSelected: PT.number,
  displaySelected: PT.bool
};

export default ShortBatchActions;
