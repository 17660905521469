import { useCallback, useRef, useEffect, useMemo, useState } from 'react';

import { useDidUpdateEffect } from '../../../utils/hooks';
import pickBy from 'lodash/pickBy';
import forEach from 'lodash/forEach';
import { isEqual } from 'lodash';

const THRESHOLD_ITEM_TAIL_INDEX = 4;

function useFetchingMoreWatcher({
  fetchMore,
  items
}, dependenciesProp = []) {
  const [dependencies, setDependencies] = useState(dependenciesProp);

  const isProcessing = useRef(false);
  const visibilityByIndex = useRef({});

  const itemsCount = items?.length;

  useEffect(() => {
    /* perform shallow compare for passed dependencies to "tryFetchMore" useCallback hook. */
    if (!isEqual(dependencies, dependenciesProp)) {
      setDependencies(dependenciesProp);
    }
  }, [dependenciesProp, setDependencies, dependencies]);

  const tryFetchMore = useCallback(async (itemsCount) => {
    const visibleIndexes = Object.keys(pickBy(visibilityByIndex.current));
    const maxVisibleIndex = Math.max(...visibleIndexes);
    const maxIndex = Number.isFinite(maxVisibleIndex) ? maxVisibleIndex : null;

    if(!itemsCount || isThresholdReached(itemsCount, maxIndex)) {
      const shouldLoad = !isProcessing.current && dependencies.every(Boolean);

      if (shouldLoad) {
        isProcessing.current = true;

        await fetchMore();

        isProcessing.current = false;
      }
    }
  }, [dependencies, fetchMore]);

  const setItemVisibility = useCallback((id, val) => {
    visibilityByIndex.current[id] = val;

    tryFetchMore(itemsCount);
  }, [itemsCount, tryFetchMore]);

  useEffect(() => {
    forEach(visibilityByIndex.current, (v, k) => {
      setItemVisibility(k, items[k] ? v : false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsCount, setItemVisibility]);

  useDidUpdateEffect(() => {
    tryFetchMore(itemsCount);
  }, [itemsCount]);

  return useMemo(() => ({
    visibilityByIndex,
    setItemVisibility
  }), [visibilityByIndex, setItemVisibility]);
}

export default useFetchingMoreWatcher;

function isThresholdReached(itemsCount, target) {
  if(target === null)
    return false;

  return itemsCount - target < THRESHOLD_ITEM_TAIL_INDEX;
}
