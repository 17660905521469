import styled from 'styled-components';

import InputBase from '@mui/material/InputBase';

const IdleSignOutContainer = styled.div`
  display: flex;
  align-items: center;

  .control-suffix-text {
    margin-right: 16px;
  }
`;

export const OutlinedInput = styled(InputBase)`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 2px;
  padding-left: 6px;
`;

export default IdleSignOutContainer;
