import { useMemo } from 'react';
import get from 'lodash/get';
import pick from 'lodash/pick';

function useSamplesList(data, {
  isCheckedItemsFilterActive,
  itemsById,
}) {
  const edges = get(data, 'items.edges', []);

  const filteredList = useMemo(() =>
    getFilteredSamples(
      edges,
      isCheckedItemsFilterActive,
      itemsById
    ), [
    edges,
    isCheckedItemsFilterActive,
    itemsById
  ]
  );

  const list = useMemo(() =>
    filteredList.map(i => i.node),
  [filteredList]
  );

  const mappedSamples = useMemo(() =>
    list.map(i => pick(i, [
      'id',
      'title',
      'description',
      'privacy',
      'owner',
      'hasAssignedObjects',
      'objectsStat',
      'viewerMaxRole'
    ])),
  [list]
  );

  return {
    mappedSamples,
    visibleSamples: list,
    filteredListCount: filteredList.length,
    allSamples: edges.map(i => i.node),
  };
}

export default useSamplesList;


function filterBySelectedIds(samples, ids) {
  return samples.filter(i => ids.includes(i.node.id));
}

function getFilteredSamples(
  listCollection,
  isSelectedIdsActive,
  itemsById
) {
  if (isSelectedIdsActive) {
    const checkedItems = Object.keys(itemsById).filter(id => itemsById[id].checked);

    listCollection = filterBySelectedIds(listCollection, checkedItems);
  }

  return listCollection;
}
