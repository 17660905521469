import React, { useCallback } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { Draggable } from 'react-beautiful-dnd';

import TypeSelect from './TypeSelect';
import ContentItemTitle from './ContentItemTitle';
import ContentItemValue from './ContentItemValue';

const ContentItem = ({
  id,
  index,
  data,
  readOnly,
  error,
  onRemove,
  onTitleTypeChange,
  onTitleChange,
  onValueTypeChange,
  onValueChange
}) => {
  const handleRemove = useCallback(() => {
    onRemove(id);
  }, [id, onRemove]);

  const handleTitleTypeChange = useCallback(type => {
    onTitleTypeChange(id, type);
  }, [id, onTitleTypeChange]);

  const handleTitleChange = useCallback(value => {
    onTitleChange(id, value);
  }, [id, onTitleChange]);

  const handleValueTypeChange = useCallback(type => {
    onValueTypeChange(id, type);
  }, [id, onValueTypeChange]);

  const handleValueChange = useCallback(value => {
    onValueChange(id, value);
  }, [id, onValueChange]);

  return (
    <Draggable
      draggableId={id}
      index={index}
      isDragDisabled={readOnly}
    >
      {provided => (
        <Box
          display="flex"
          gap="8px"
          mt="8px"
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <Box
            flexBasis="50%"
            display="flex"
            alignItems="flex-start"
            flexShrink="1"
            gap="8px"
          >
            <IconButton
              aria-label="drag"
              size="small"
              disabled={readOnly}
              {...provided.dragHandleProps}
            >
              <DragIndicatorIcon fontSize="inherit" />
            </IconButton>

            <TypeSelect
              options={['TEXT', 'LINK']}
              selected={data.title.type}
              disabled={readOnly}
              onChange={handleTitleTypeChange}
            />

            <Box flexGrow="1">
              <ContentItemTitle
                data={data.title}
                disabled={readOnly}
                error={error}
                onChange={handleTitleChange}
              />
            </Box>
          </Box>

          <Box
            flexBasis="50%"
            display="flex"
            alignItems="flex-start"
            flexShrink="1"
            gap="8px"
          >
            <TypeSelect
              options={['TEXT', 'LINK', 'QUANTITY', 'BOOLEAN']}
              selected={data.value.type}
              disabled={readOnly || !!data.id} // disallow changing the value type of existing parameters
              onChange={handleValueTypeChange}
            />

            <Box flexGrow="1">
              <ContentItemValue
                data={data.value}
                disabled={readOnly}
                onChange={handleValueChange}
              />
            </Box>

            {readOnly ?
              null :
              <IconButton aria-label="drag" size="small" onClick={handleRemove}>
                <DeleteOutlineIcon fontSize="inherit" />
              </IconButton>
            }
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

ContentItem.propTypes = {
  id: PT.string.isRequired,
  index: PT.number,
  data: PT.shape({
    id: PT.string,
    title: PT.shape({
      type: PT.oneOf(['TEXT', 'LINK']),
      value: PT.oneOfType([PT.string, PT.object])
    }),
    value: PT.shape({
      type: PT.oneOf(['TEXT', 'BOOLEAN', 'QUANTITY', 'LINK']),
      value: PT.oneOfType([
        PT.string,
        PT.shape({
          id: PT.string,
          name: PT.string
        })
      ])
    })
  }).isRequired,
  readOnly: PT.bool,
  error: PT.object,
  onRemove: PT.func,
  onTitleTypeChange: PT.func,
  onTitleChange: PT.func,
  onValueTypeChange: PT.func,
  onValueChange: PT.func
};

export default ContentItem;
