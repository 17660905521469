import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import { VALUE_TYPE } from '../../../../../../services/samples/constants';

import QuantityValue from './QuantityValue';
import LinkValue from './LinkValue';
import TextValue from './TextValue';

const FeatureValue = ({ data }) => {

  switch (data.valueType){
    case VALUE_TYPE.LINK: {
      return (
        <LinkValue
          data={data}
        />
      );
    }
    case VALUE_TYPE.QUANTITY: {
      return (
        <QuantityValue
          data={data}
        />
      );
    }
    case VALUE_TYPE.TEXT: {
      return (
        <TextValue
          text={data.text}
        />
      );
    }
    case VALUE_TYPE.BOOLEAN: {
      return capitalize(`${typeof data.boolean === 'boolean' ? data.boolean : ''}`);
    }
    default:
      return null;
  }
};

FeatureValue.propTypes = {
  data: PropTypes.object.isRequired
};

export default FeatureValue;
