import AppBarComponent from '@mui/material/AppBar';
import styled, { css } from 'styled-components';
import Toolbar from '@mui/material/Toolbar';

import {
  COLOR_PRIMARY_CONTRAST,
  MEDIA_QUERIES_XS,
  COLOR_WHITE,
  MEDIA_CREATE_ACTIONS,
  COLOR_PRIMARY_LIGHT,
  APP_BAR_HEIGHT,
  HEADER_BREAKPOINT
} from '../../../styles';
import BackIconSvg from '@mui/icons-material/KeyboardBackspace';

const AppBar = styled(AppBarComponent).attrs({ className: 'app-bar' })`
  && {
    color: ${COLOR_PRIMARY_CONTRAST};
    
    ${props => props.sidebaropened !== 'true' && css`
      z-index: 4;
    `}

    ${props => props.mobile !== 'true' && css`
      box-shadow: none;
    `}
  }

  & > div {
    justify-content: space-between;
    padding: 0;
  }

  .left-block {
    display: flex;
    align-items: center;
    padding-left: 24px;

    .toggler {
      height: 46px;
      width: 46px;
      font-size: 2rem;
      margin-right: 10px;
    }

    .title {
      font-size: 2rem;
    }
  }

  .center-block {
    display: flex;
    position: relative;
    align-items: center;
    flex-basis: 33%;
    margin: auto;

    &.search-view {
      padding: 0 10px;
    }
  }

  .right-block {
    display: flex;
    align-items: center;
    height: 56px;
    padding-right: 8px;

    > *:not(:last-child) {
      margin-right: 8px;
    }

    a {
      display: flex;
      align-items: center;
      height: 100%;
    }

    button {
      box-shadow: none;
      width: auto;
      height: auto;
      z-index: 3;

      &.hovered,
      &:hover {
        background-color: ${COLOR_PRIMARY_LIGHT};
      }

      &.app-menu-btn,
      &.help-link {
        & > span {
          width: 38px;
          height: 38px;
        }
      }


      a {
        width: 38px;
        height: 38px;
        justify-content: center;

        svg {
          color: ${COLOR_WHITE};
        }
      }
    }


    .notification-icon-tooltip {
      display: flex;
      cursor: pointer;
    }
  }

  @media (${MEDIA_CREATE_ACTIONS}) {
    .center-block {
      .create-actions-menu {
        display: none;
      }
    }
  }

  @media (max-width: 925px) {
    .center-block {
      margin-left: 310px;
    }
  }

  @media (${MEDIA_QUERIES_XS}) {
    .title {
      display: none;
    }
    
    .left-block {
      padding-left: 5px;
    }

    .center-block {
      margin: 0;
      flex-basis: auto;
      flex-grow: 1;
    }

    .right-block, .left-block {
      position: static;
    }

    .right-block {
      button {
        width: 40px;
        height: 40px;
      }
    }
  }

  @media (max-width: 1210px) {
    .create-actions-menu {
      right: -175px;

      .open-menu {
        & > span > span {
          font-size: 1rem;
        }
      }
    }
  }

  @media (min-width: 600px) {
    .left-block {
      padding-left: 24px;

      .toggler {
        height: 46px;
        margin-right: 10px;
      }
    }

    .right-block {
      height: 64px;
    }
  }
`;

export default AppBar;

export const Logo = styled.div`
  height: ${APP_BAR_HEIGHT}px;
  width: 255px;
  background: transparent url(/img/logo.v2.svg) no-repeat left center;

  @media (${HEADER_BREAKPOINT}) {
    background: transparent url(/img/logo.svg) no-repeat left center;
    width: 45px;
    height: 56px;
  }
`;

export const BackIcon = styled(BackIconSvg)`
  width: 30px;
  height: 30px;
  margin-right: 8px;
`;

export const ToolbarStyled = styled(Toolbar)`
  @media (${HEADER_BREAKPOINT}) {
     position: static;
  }
`;
