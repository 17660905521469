import React from 'react';
import PT from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

import ItemSelect from '../../../../../ItemSelect';

const toValue = data => ({
  id: data?.id,
  label: data?.title ?? data?.label,
  code: data?.code
});

const EditLinkValue = ({ onChange, data, onCancel }) => {
  return (
    <Box
      display="flex"
      gap="8px"
      alignItems="center"
      flexGrow="1"
      width="100%"
    >
      <ItemSelect
        value={toValue(data)}
        onChange={onChange}
        styles={{
          placeholder: {
            height: '30px',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            lineHeight: '30px',
            color: 'rgb(170, 170, 170)'
          },
          control: { border: 'none !important' },
          indicators: { display: 'none' },
          menu: { width: '235px' },
          root: { width: '100%' }
        }}
      />

      <CloseIcon
        onClick={onCancel}
        size="small"
        sx={{
          color: '#8C8C8C',
          width: 14,
          height: 14,
          marginRight: '6px',
          cursor: 'pointer'
        }}
      />
    </Box>
  );
};

EditLinkValue.propTypes = {
  onCancel: PT.func.isRequired,
  onChange: PT.func.isRequired,
  data: PT.oneOfType([
    PT.string,
    PT.shape({
      id: PT.string,
      label: PT.string,
      title: PT.string,
      code: PT.string
    }).isRequired,
  ])
};

export default EditLinkValue;
