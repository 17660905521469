import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const renderValue = (items, placeholder) => selected => {
  if(!Array.isArray(selected)) {
    const item = items.find(({ value }) => selected === value);

    return get(item, 'name', '');
  }

  return selected.length ?
    items
      .reduce(
        (result, { name, value }) =>
          selected.includes(value) ? result.concat(name) : result,
        []
      )
      .join(', ') :
    placeholder;
};

class OutlinedSelect extends React.Component {
  shouldComponentUpdate(nextProps) {
    return [
      nextProps.value !== this.props.value,
    ].some(Boolean);
  }

  render() {
    const {
      id,
      items,
      multiple,
      displayEmpty,
      label,
      placeholder,
      value: selected,
      onChange,
      name,
      required
    } = this.props;

    const displayLabel = placeholder || label;

    return (
      <React.Fragment>
        <InputLabel
          htmlFor={id}
          shrink
        >
          {label}
        </InputLabel>

        <Select
          multiple={multiple}
          value={selected}
          onChange={onChange}
          input={
            <OutlinedInput
              id={id}
              name={name || id}
              label={displayLabel}
              notched
              required={required}
            />
          }
          renderValue={renderValue(items, <em>{displayLabel}</em>)}
          displayEmpty={displayEmpty}
          variant="outlined"
        >
          {displayEmpty &&
            <MenuItem value="">
              <em>{displayLabel}</em>
            </MenuItem>
          }

          {items.map(({ value, name }) => (
            <MenuItem key={value} value={value}>
              {multiple &&
                <Checkbox
                  checked={selected ? selected.indexOf(value) > -1 : false}
                  disableRipple
                  color="primary"
                  tabIndex={-1}
                />
              }

              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </React.Fragment>
    );
  }
}

OutlinedSelect.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array,
  multiple: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool
};

OutlinedSelect.defaultProps = {
  items: []
};

export default OutlinedSelect;
