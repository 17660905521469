/* eslint-disable react/no-multi-comp */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { replace } from 'lodash';
import { Link } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import makeStyles from '@mui/styles/makeStyles';

import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

import QRCodeIcon from '../../Icons/QRCodeIcon';
import AddMeasurementIcon from '../../Icons/AddMeasurementIcon';
import AddProtocolIcon from '../../Icons/AddProtocolIcon';
import QRCodeDialog from '../../QRCodeDialog';
import CloneItemDialog from '../../Sample/CloneItemDialog';
import CopyItemUrlAction from './CopyItemUrlAction';
import ArchiveItemSubmitDialog from '../../ArchiveItemSubmitDialog';
import DeleteItemSubmitDialog from '../../DeleteItemSubmitDialog';

import { rolesIds, isRoleEqualOrHigher } from '../../../utils/roles';

import { MENU_ITEM_MIN_HEIGHT, MENU_ITEM_ICON_MIN_WIDTH } from './constants';
import { routes } from '../../../services/session/constants';
import {
  RESTRICT_PERMISSION,
  RESTRICT_LINKED
} from '../../../constants';

const useStyles = makeStyles({
  menuItem: {
    minHeight: MENU_ITEM_MIN_HEIGHT
  },

  menuItemIcon: {
    minWidth: MENU_ITEM_ICON_MIN_WIDTH
  }
});

const ItemActions = ({
  onAddMeasurement,
  onAddProtocol,
  onArchive,
  onDelete,
  id,
  userRole,
  sample
}) => {
  const [anchorEl, setAnchor] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [openQRCodeDialog, setOpenQRCodeDialog] = useState(false);
  const [openSubmitSamplesArchiving, setOpenSubmitSamplesArchiving] = useState(false);
  const [openDeleting, setOpenDeleting] = useState(false);
  const [openCloneDialog, setOpenCloneDialog] = useState(false);

  const classes = useStyles();

  const handleToggleMenu = useCallback((e) => {
    setAnchor(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchor(null);
  }, []);

  const handleAddMeasurement = useCallback(() => {
    onAddMeasurement(id);
    handleClose();
  }, [onAddMeasurement, id, handleClose]);

  const handleAddProtocol = useCallback(() => {
    onAddProtocol(id);
    handleClose();
  }, [onAddProtocol, handleClose, id]);

  const handleTryArchive = useCallback(() => {
    setOpenSubmitSamplesArchiving(true);
    handleClose();
  }, [handleClose]);

  const handleOpenDelete = useCallback(() => {
    setOpenDeleting(true);
    handleClose();
  }, [handleClose]);

  const handlesubmitSamplesArchivingClose = useCallback(() => {
    setOpenSubmitSamplesArchiving(false);
  }, []);

  const handleSamplesArchivingSubmit = useCallback(() => {
    onArchive(true);
    setOpenSubmitSamplesArchiving(false);
  }, [onArchive]);

  const handleButtonHovered = useCallback(() => {
    setTooltipOpen(true);
  }, []);

  const handleButtonUnHovered = useCallback(() => {
    setTooltipOpen(false);
  }, []);

  const handleQRCodeDialogOpen = useCallback(() => {
    setOpenQRCodeDialog(true);
    handleClose();
  }, [handleClose]);

  const handleQRCodeDialogClose = useCallback(() => {
    setOpenQRCodeDialog(false);
  }, []);

  const handleClone = useCallback(() => {
    setOpenCloneDialog(true);
  }, []);

  const handleCloneDialogClose = useCallback(() => {
    setOpenCloneDialog(false);
  }, []);

  const handleDeletionClose = useCallback(() => {
    setOpenDeleting(false);
  }, []);

  const handleDeletionSubmit = useCallback(() => {
    setOpenDeleting(false);
    onDelete();
  }, [onDelete]);

  const archiveForbidden = useMemo(() => {
    if(!isRoleEqualOrHigher(userRole, rolesIds.ADMIN))
      return true;
  }, [userRole]);

  const deleteForbidden = useMemo(() => {
    if(sample.isUsedInProtocols)
      return { reason: RESTRICT_LINKED };

    if(!isRoleEqualOrHigher(userRole, rolesIds.ADMIN))
      return { reason: RESTRICT_PERMISSION };
  }, [sample, userRole]);

  const elementId = `simple-menu-${id}`;
  const itemUrlPath = replace(routes.ITEMS, ':sampleId', id);
  const itemLink = `${window.location.origin}${itemUrlPath}`;

  return (
    <div className="action-container">
      <Tooltip title="Item Options" open={tooltipOpen}>
        <Button
          aria-owns={anchorEl ? elementId : null}
          aria-haspopup="true"
          onClick={handleToggleMenu}
          onMouseOver={handleButtonHovered}
          onMouseLeave={handleButtonUnHovered}
        >
          <MenuIcon />
        </Button>
      </Tooltip>

      <Menu
        id={elementId}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={handleClose}
          component={Link}
          to={`${itemUrlPath}`}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <OpenInNewIcon />
          </ListItemIcon>

          <ListItemText>
            Open Item Page
          </ListItemText>
        </MenuItem>

        <CopyItemUrlAction
          onCopied={handleClose}
          text={itemLink}
        />

        <MenuItem
          className={classes.menuItem}
          onClick={handleQRCodeDialogOpen}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <QRCodeIcon />
          </ListItemIcon>

          <ListItemText>
            View QR Code
          </ListItemText>
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={handleClone}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <FileCopyOutlinedIcon />
          </ListItemIcon>

          <ListItemText>
            Duplicate Item
          </ListItemText>
        </MenuItem>

        <Divider />

        {isRoleEqualOrHigher(userRole, rolesIds.WRITE) &&
        <>
          <MenuItem
            className={classes.menuItem}
            onClick={handleAddProtocol}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <AddProtocolIcon />
            </ListItemIcon>

            <ListItemText>
              Add Protocol
            </ListItemText>
          </MenuItem>

          <MenuItem
            className={classes.menuItem}
            onClick={handleAddMeasurement}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <AddMeasurementIcon />
            </ListItemIcon>

            <ListItemText>
              Add Measurement
            </ListItemText>
          </MenuItem>

          <Divider />
        </>}

        <MenuItem
          className={classes.menuItem}
          onClick={handleTryArchive}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <ArchiveIcon />
          </ListItemIcon>

          <ListItemText>
            Archive Item
          </ListItemText>
        </MenuItem>

        <MenuItem
          className={classes.menuItem}
          onClick={handleOpenDelete}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <DeleteIcon />
          </ListItemIcon>

          <ListItemText>
            Delete Item
          </ListItemText>
        </MenuItem>
      </Menu>

      {
        openQRCodeDialog ?
          <QRCodeDialog
            open={openQRCodeDialog}
            itemId={id}
            primaryTitle={sample.title}
            secondaryTitle={sample.code}
            onClose={handleQRCodeDialogClose}
            itemLink={itemLink}
          /> :
          null
      }

      {openCloneDialog &&
        <CloneItemDialog
          data={sample}
          onClose={handleCloneDialogClose}
        />
      }

      <ArchiveItemSubmitDialog
        open={openSubmitSamplesArchiving}
        onClose={handlesubmitSamplesArchivingClose}
        onSubmit={handleSamplesArchivingSubmit}
        itemName={sample.title}
        forbidden={archiveForbidden}
      />

      <DeleteItemSubmitDialog
        open={openDeleting}
        onClose={handleDeletionClose}
        onSubmit={handleDeletionSubmit}
        itemName={sample.title}
        forbidden={deleteForbidden}
      />
    </div>
  );
};

ItemActions.propTypes = {
  id: PropTypes.string.isRequired,
  onArchive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAddMeasurement: PropTypes.func.isRequired,
  onAddProtocol: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  sample: PropTypes.object.isRequired,
};

export default ItemActions;
