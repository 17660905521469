import { gql } from '@apollo/client/core';

export const UPDATE_FEATURE_VALUE = gql`
  mutation Mutation($input: UpdateTableValueInput!) {
    updateTableValue(input: $input) {
      tableParameterId
      tableItemId
      quantity
      text
      boolean
      linkedTableItem {
        id
        title
        code
        viewerMaxRole
      }
    }
  }
`;

