import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import DELETE_FOLDER from './gql';
import { FOLDERS } from '../../../gql';

import { openAppSnackbarNotification } from '../../../../../services/snackbar-notifications/actions';

const styles = {
  dialog: {
    width: '530px'
  },
};

const DeleteFolderDialog = ({ onClose, id, refetchList, onDelete }) => {

  const [deleteFolder, { loading }] = useMutation(DELETE_FOLDER, {
    onCompleted: onDelete,
    refetchQueries: refetchList ?? [FOLDERS]
  });

  const dispatch = useDispatch();

  const handleSubmit = useCallback(async () => {
    try {
      await deleteFolder({
        variables: {
          deleteFolderId: id,
        }
      });

    } catch (e) {
      dispatch(
        openAppSnackbarNotification({
          variant: 'ERROR',
          message: e.message
        })
      );
    }

    onClose();
  }, [onClose, deleteFolder, id, dispatch]);

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': styles.dialog
      }}
    >
      <DialogTitle>
        Confirm Delete Folder
      </DialogTitle>

      <DialogContent>
        <Typography>
          Are you sure you want to delete this folder? This will delete all the tables and their items.
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          variant="contained"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteFolderDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchList: PropTypes.array,
  onDelete: PropTypes.func,
};

export default DeleteFolderDialog;
