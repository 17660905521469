import { VALUE_TYPE } from '../services/samples/constants';

export const defaultCompositionItem = {
  id: null,
  type: 'PARAMETER',
  value: '',
  quantity: '',
  unit: {
    id: ''
  },
  text: '',
  boolean: null,
  valueType: VALUE_TYPE.QUANTITY,
  error: null,
  erroredFields: [],
};
