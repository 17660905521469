import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query Notifications($first: Int, $after: String) {
    notifications(first: $first, after: $after) {
      edges {
        node {
          id
          read
          timestamp
          eventType

          ... on CommentNotification {
            user {
              name

              ... on CurrentUser {
                avatarColor
                picture {
                  downloadURL
                }
              }
            }
            item {
              id
              title
              archived
            }
          }

          ... on ShareViewNotification {
            icon
            title
            url
            user {
              name

              ... on CurrentUser {
                avatarColor
                picture {
                  downloadURL
                }
              }
            }
          }

          ... on ShareFolderNotification {
            role
            folder {
              id
              title
            }
            user {
              id
              name
            }
          }

          ... on ShareTableNotification {
            role
            table {
              id
              title
            }
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`;
