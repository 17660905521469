import get from 'lodash/get';
import { createSelector } from 'reselect';

import firebase from 'firebase/app';

import {
  currentUser,
  getAuthUser,
  getEmail,
  getCurrentUser,
  getDefaultAccountId,
  getUserDefaultAccountId
} from '../common/selectors';

export {
  currentUser,
  getAuthUser,
  getEmail,
  getCurrentUser,
  getDefaultAccountId,
  getUserDefaultAccountId
};
// general
export const isFetching = state => state.session.isFetching;
export const firebaseSocket = state => state.session.firebaseSocket;
export const isSidebarOpen = state => state.session.sidebarOpen;
export const isMobileView = state => state.session.isMobileView;
export const getRouteName = state => state.session.routeName;
export const getLastPage = state => state.session.lastPage;

// user
export const isSignedIn = createSelector(getAuthUser, Boolean);
export const isEmailVerified = createSelector(getAuthUser, authUser => get(authUser, 'emailVerified', false));
export const getUserEmails = createSelector(getAuthUser, ({ providerData }) =>
  providerData.map(i => i.email)
);
export const isNewUser = state => state.session.isNewUser;
export const currentUserInit = state => state.session.currentUserInit;
export const isUserFetching = state => state.session.isUserFetching;
export const getError = state => state.session.error;
export const getErrorMsg = state => get(state.session.error, 'message');
export const getResetPasswordResponse = state => get(
  state.session.resetPasswordResponse,
  'message'
);
export const isUserExist = createSelector(
  currentUser,
  user => Boolean(user)
);
export const isAutoLogoutChecking = state => state.session.autoLogoutChecking;
export const getAvatar = state => get(getCurrentUser(state), 'avatarUrl');
export const getAvatarColor = state => get(getCurrentUser(state), 'avatarColor');
export const getUserid = state => get(getCurrentUser(state), 'userId');
export const getSampleAssetsSort = (state, sampleId) => get(
  getCurrentUser(state),
  `sort_settings.samples_actions_sort.${sampleId}.sortBy`
);
export const getSamplesPageRowsCount = state => get(
  getCurrentUser(state),
  'samplesPageRowsCount'
);
export const getFiles = state => state.session.files;

const mapAccountIdsByRole = (minRole = 1, maxRole = 15, accountsCollection = {}) => {
  const accountIds = [];

  for(let accountId in accountsCollection) {
    if(!accountsCollection.hasOwnProperty(accountId))
      continue;

    let { role = 0 } = accountsCollection[accountId];

    if(role >= minRole && role <= maxRole)
      accountIds.push(accountId);
  }

  return accountIds;
};
export const getAllAccountsIds = (minRole = 1, maxRole = 15) =>
  createSelector(currentUser, ({ accounts = {} }) =>
    mapAccountIdsByRole(minRole, maxRole, accounts)
  );
export const getAllAccounts = state => get(currentUser(state), 'accounts');
export const getCurrentUserName = createSelector(getCurrentUser, ({ name }) => name);
export const getCurrentUserCode = createSelector(getCurrentUser, ({ code }) => code);
export const getCurrentUserNextSampleId = createSelector(
  getCurrentUser,
  user => get(user, 'next_sample_id')
);

export const isAuthBySocialProviders = createSelector(
  getAuthUser,
  authUser => {
    if (!authUser) {
      return false;
    }

    const { providerData } = authUser;

    return providerData.every(i =>
      i.providerId !== firebase.auth.EmailAuthProvider.PROVIDER_ID
    );
  });
