import { createRequestTypes, action } from '../../actions';
import * as constants from './constants';

const {
  USER_FORGOT_PASSWORD,
  USER_LOG_OUT,
  REQUEST,
  SUCCESS,
  FAILURE,
  FIREBASE_INIT,
  TOGGLE_MOBILE_VIEW,
  SET_MOBILE_VIEW,
  USER_WATCH,
  CHILD_REMOVED,
  ROUTE_NAME_UPDATE,
  CHILD_ADDED,
  PASSWORD_PROVIDER,
  OAUTH_PROVIDER,
  FETCH_SIGN_IN,
  FETCH_SIGN_UP,
  CREATE_USER,
  SAMPLES_SORT_SETTINGS_UPDATE,
  USER_UPDATE,
  SAVE_CLIENT_LOG,
  USER_FIELD_UPDATE,
  USER_EMAIL_UPDATE,
  USER_PASSWORD_UPDATE,
  LINK_PROVIDER,
  UNLINK_PROVIDER,
  SAMPLE_ASSETS_SORT_SETTINGS_UPDATE,
  CLEAN_PASSWORD_RESET,
  SAMPLES_ARCHIVE_SORT_SETTINGS_UPDATE,
  RESET_ERROR
} = constants;

export const fetchSignUpTypes = createRequestTypes(FETCH_SIGN_UP);
export const fetchCreateUserTypes = createRequestTypes(CREATE_USER);
export const fetchSignInTypes = createRequestTypes(FETCH_SIGN_IN);
export const userForgotPasswordTypes = createRequestTypes(USER_FORGOT_PASSWORD);
export const userLogoutTypes = createRequestTypes(USER_LOG_OUT);
export const firebaseInitTypes = createRequestTypes(FIREBASE_INIT);
export const userWatchTypes = createRequestTypes(USER_WATCH,
  { listenList: true }
);
export const samplesSortSettingsUpdateTypes = createRequestTypes(
  SAMPLES_SORT_SETTINGS_UPDATE
);
export const userUpdateTypes = createRequestTypes(USER_UPDATE);
export const saveClientLogTypes = createRequestTypes(SAVE_CLIENT_LOG);
export const updateUserFieldTypes = createRequestTypes(USER_FIELD_UPDATE);
export const updateEmailTypes = createRequestTypes(USER_EMAIL_UPDATE);
export const updatePasswordTypes = createRequestTypes(USER_PASSWORD_UPDATE);
export const linkProviderTypes = createRequestTypes(LINK_PROVIDER);
export const unlinkProviderTypes = createRequestTypes(UNLINK_PROVIDER);
export const sampleAssetsSortSettingsUpdateTypes = createRequestTypes(
  SAMPLE_ASSETS_SORT_SETTINGS_UPDATE
);

export const resetError = () => ({
  type: RESET_ERROR
});

export const toggleMobileView = () => ({
  type: TOGGLE_MOBILE_VIEW
});

export const setMobileView = value => ({
  type: SET_MOBILE_VIEW,
  value
});

export const firebaseInitHandlers = {
  request: () => action(firebaseInitTypes[REQUEST]),
  success: (response) => action(firebaseInitTypes[SUCCESS], { response }),
  failure: (error) => action(firebaseInitTypes[FAILURE], { error })
};

export const signUpHandlers = {
  request: authProviderType => (...params) => action(fetchSignUpTypes[REQUEST], { authProviderType, params }),
  success: response => action(fetchSignUpTypes[SUCCESS], { response }),
  failure: error => action(fetchSignUpTypes[FAILURE], { error })
};

export const createUserHandlers = {
  request: user => action(fetchCreateUserTypes[REQUEST], { user }),
  success: response => action(fetchCreateUserTypes[SUCCESS], { response }),
  failure: error => action(fetchCreateUserTypes[FAILURE], { error })
};

export const signInHandlers = {
  request: authProviderType => (...params) => action(fetchSignInTypes[REQUEST], { authProviderType, params }),
  success: response => action(fetchSignInTypes[SUCCESS], { response }),
  failure: error => action(fetchSignInTypes[FAILURE], { error })
};

export const forgotPasswordHandlers = {
  request: email =>
    action(userForgotPasswordTypes[REQUEST], { email }),
  success: (response) => action(userForgotPasswordTypes[SUCCESS], { response }),
  failure: (error) => action(userForgotPasswordTypes[FAILURE], { error })
};

export const logoutHandlers = {
  request: () => action(userLogoutTypes[REQUEST]),
  success: (response) => action(userLogoutTypes[SUCCESS], { response }),
  failure: (error) => action(userLogoutTypes[FAILURE], { error })
};

export const userWatchHandlers = {
  request: (response) => action(userWatchTypes[REQUEST], { response }),
  added: response => action(userWatchTypes[CHILD_ADDED], { response }),
  removed: response => action(userWatchTypes[CHILD_REMOVED], { response }),
  success: response => action(userWatchTypes[SUCCESS], { response }),
  failure: error => action(userWatchTypes[FAILURE], { error }),
};

export const userUpdateHandlers = {
  request: data => action(userUpdateTypes[REQUEST], { data }),
  success: response => action(userUpdateTypes[SUCCESS], { response }),
  failure: error => action(userUpdateTypes[SUCCESS], { error: error.message })
};

export const saveClientLogHandlers = {
  request: data => action(saveClientLogTypes[REQUEST], { data }),
  success: response => action(saveClientLogTypes[SUCCESS], { response }),
  failure: error => action(saveClientLogTypes[FAILURE], { error: error.message })
};

export const updateUserFieldHandlers = {
  request: data => action(updateUserFieldTypes[REQUEST], { data }),
  success: response => action(updateUserFieldTypes[SUCCESS], { response }),
  failure: error => action(updateUserFieldTypes[FAILURE], { error: error.message })
};

export const updateEmailHandlers = {
  request: data => action(updateEmailTypes[REQUEST], { data }),
  success: response => action(updateEmailTypes[SUCCESS], { response }),
  failure: error => action(updateEmailTypes[FAILURE], { error: error.message })
};

export const updatePasswordHandlers = {
  request: data => action(updatePasswordTypes[REQUEST], { data }),
  success: response => action(updatePasswordTypes[SUCCESS], { response }),
  failure: error => action(updatePasswordTypes[FAILURE], { error: error.message })
};

export const linkProviderHandlers = {
  request: provider => action(linkProviderTypes[REQUEST], { provider }),
  success: response => action(linkProviderTypes[SUCCESS], { response }),
  failure: error => action(linkProviderTypes[FAILURE], { error: error.message })
};

export const unlinkProviderHandlers = {
  request: (providerId, user) => action(unlinkProviderTypes[REQUEST], { providerId, user }),
  success: response => action(unlinkProviderTypes[SUCCESS], { response }),
  failure: error => action(unlinkProviderTypes[FAILURE], { error: error.message })
};

export const sampleAssetsSortSettingsUpdateHandlers = {
  request: data => action(sampleAssetsSortSettingsUpdateTypes[REQUEST], { data }),
  success: response => action(sampleAssetsSortSettingsUpdateTypes[SUCCESS], { response }),
  failure: error => action(sampleAssetsSortSettingsUpdateTypes[FAILURE], { error: error.message })
};

export const cleanPassResetResponse = () => action(CLEAN_PASSWORD_RESET);

export const firebaseInit = firebaseInitHandlers.request;
export const signUpWithEmailAndPassword = signUpHandlers.request(PASSWORD_PROVIDER);
export const signUpWithOAuth = signUpHandlers.request(OAUTH_PROVIDER);
export const createUser = createUserHandlers.request;
export const onCreateUser = createUserHandlers.success;
export const signInWithEmailAndPassword = signInHandlers.request(PASSWORD_PROVIDER);
export const signInWithOAuth = signInHandlers.request(OAUTH_PROVIDER);
export const samplesSortSettingsUpdateHandlers = {
  request: params =>
    action(samplesSortSettingsUpdateTypes[REQUEST], { params }),
  success: response =>
    action(samplesSortSettingsUpdateTypes[SUCCESS], { response }),
  failure: error =>
    action(samplesSortSettingsUpdateTypes[FAILURE], { error })
};
export const sendPasswordRecoveryEmail = forgotPasswordHandlers.request;
export const logout = logoutHandlers.request;
export const userWatch = userWatchHandlers.request;
export const routeChange = name => action(ROUTE_NAME_UPDATE, { name });
export const samplesSortSettingsUpdate =
  samplesSortSettingsUpdateHandlers.request;
export const userUpdate = userUpdateHandlers.request;
export const updateUserField = updateUserFieldHandlers.request;
export const updateEmail = updateEmailHandlers.request;
export const updatePassword = updatePasswordHandlers.request;
export const linkProvider = linkProviderHandlers.request;
export const unlinkProvider = unlinkProviderHandlers.request;
export const sampleAssetsSortSettingsUpdate =
  sampleAssetsSortSettingsUpdateHandlers.request;
export const updatePageRowsCount = (value, userId) =>
  updateUserFieldHandlers.request({
    value,
    userId,
    name: 'samplesPageRowsCount'
  });

// export const addUserAccount = data => action(ADD_USER_ACCOUNT, { data });
export const samplesArchiveSortSettingsUpdate = options => action(
  SAMPLES_ARCHIVE_SORT_SETTINGS_UPDATE,
  { options }
);
export const removeUserAccount = id => action(constants.REMOVE_USER_ACCOUNT, { id });
export const setAuthPersistence = staySignedIn => action(constants.SET_AUTH_PERSISTENCE, { staySignedIn });
export const setCurrentUser = data => action(constants.SET_CURRENT_USER, { data });
