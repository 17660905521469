import { createRequestTypes, action, createHandlers } from '../../actions';
import * as constants from './constants';

export const measurementParseByIdTypes = createRequestTypes(constants.MEASUREMENT_PARSE_BY_ID);
export const measurementsParseTypes = createRequestTypes(constants.MEASUREMENTS_PARSE);
export const measurementProcessedDataLoadByIdTypes = createRequestTypes(constants.MEASUREMENT_PROCESSED_DATA_LOAD_BY_ID);

export const measurementParseByIdHandlers = createHandlers(measurementParseByIdTypes);
export const measurementsParseHandlers = createHandlers(measurementsParseTypes);
export const measurementProcessedDataLoadByIdHandlers = createHandlers(measurementProcessedDataLoadByIdTypes);

export const parseMeasurementById = measurementParseByIdHandlers.request;
export const parseMeasurements = measurementsParseHandlers.request;
export const removeParsedDataById = id => action(constants.MEASUREMENT_PARSED_DATA_BY_ID_REMOVE, { id });
export const addParsedData = (id, data) => action(constants.MEASUREMENT_PROCESSED_DATA_ADD, { id, data });
export const processedDataLoadById = measurementProcessedDataLoadByIdHandlers.request;
export const parsedDataResetById = id => action(constants.PARSED_DATA_RESET_BY_ID, { id });
