import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import PT from 'prop-types';
import styled from 'styled-components';

import { COL_MAX_WIDTH_PX } from '../../../../constants';
import EditLinkValue from './EditLinkValue';
import ViewLinkValue from './ViewLinkValue';
import EditableValueContainer from '../../EditableValueContainer';
import { UPDATE_FEATURE_VALUE } from '../gql';
import { openAppSnackbarNotification } from '../../../../../../services/snackbar-notifications/actions';

const LinkValue = ({ data, readOnly, itemId, tableParameterId, ...props }) => {
  const { linkedTableItem = {} } = data;

  const dispatch = useDispatch();

  const [updateFeatureValue] = useMutation(UPDATE_FEATURE_VALUE);

  const handleUpdate = useCallback(async (value) => {
    const valuesInput = { linkedTableItemId: value?.value ?? null };
    const variablesInput = {
      tableParameterId,
      tableItemId: itemId,
      ...valuesInput
    };

    try {
      await updateFeatureValue({
        variables: { input: variablesInput },
        optimisticResponse: {
          updateTableValue: {
            __typename: 'TableValue',
            boolean: null,
            text: null,
            quantity: null,
            linkedTableItem: value
              ? {
                __typename: 'TableItemBasic',
                viewerMaxRole: null,
                id: value?.value,
                title: value.label,
                code: value.code
              }
              : null,
            ...variablesInput,
          }
        }
      });
    } catch (e) {
      dispatch(
        openAppSnackbarNotification({
          variant: 'ERROR',
          message: e.message
        })
      );
    }
  }, [tableParameterId, itemId, updateFeatureValue, dispatch]);

  const { handleToggleSidebar } = props.table.customState;

  const viewComponentProps = useMemo(() => ({
    onLink: handleToggleSidebar,
    readOnly
  }), [handleToggleSidebar, readOnly]);

  return (
    <EditableValueContainer
      {...props}

      defaultValue={linkedTableItem}
      onUpdate={handleUpdate}
      viewComponent={ViewLinkValue}
      viewComponentProps={viewComponentProps}
      editComponent={EditLinkValue}
    />
  );
};

LinkValue.propTypes = {
  readOnly: PT.bool.isRequired,
  itemId: PT.string.isRequired,
  tableParameterId: PT.string.isRequired,
  data: PT.shape({
    linkedTableItem: PT.shape({
      id: PT.string.isRequired
    })
  }),
  table: PT.shape({
    customState: PT.shape({
      handleToggleSidebar: PT.func
    })
  })
};

export default styled(LinkValue)`
  max-width: ${COL_MAX_WIDTH_PX}px;
`;
