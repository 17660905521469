import { gql } from '@apollo/client';

export const ARCHIVE_ITEM_OBJECTS = gql`
  mutation ArchiveItemObjects($input: [BatchItemObjectsUpdateInput!]!) {
    archiveItemObjects(input: $input)
  }
`;

export const DELETE_ITEM_OBJECTS = gql`
  mutation DeleteTableItem($id: ID!) {
    deleteTableItem(id: $id)
  }
`;
