import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import SingleProtocol from './SingleProtocol';

import ContainerStyled from './styles';

import toProtocols from './services/toProtocols';
import { isRoleEqualOrHigher, rolesIds } from '../../../../utils/roles';
import useExpandList from '../hooks/useExpandList';

const SIDEBAR_SHOW_EMPTY_STATUS = 'SIDEBAR_SHOW_EMPTY_STATUS';

const ProtocolsTab = ({ id, activeProtocolId, maxRole, table, values }) => {
  const data = toProtocols({ table, values });

  const [showEmptyValues, setShowEmptyValues] = useState(() => {
    return JSON.parse(localStorage.getItem(SIDEBAR_SHOW_EMPTY_STATUS)) || false;
  });

  const filteredProtocols = useMemo(() => {
    if (!showEmptyValues){
      return data.filter(protocol => {
        const features = protocol.features;
        if (!features)
          return false;

        return features.some(feature =>
          feature.link || typeof feature.quantity === 'number' || feature.text || typeof feature.boolean === 'boolean'
        );
      });
    }

    return data;
  }, [data, showEmptyValues]);

  const listWithoutEmptyFeatures = useMemo(() => {
    return filteredProtocols.filter(protocol => protocol.features.length);
  }, [filteredProtocols]);

  const {
    isAllExpanded,
    handleToggleExpand,
    handleToggleSingleObject,
    expandedList
  } = useExpandList(listWithoutEmptyFeatures, activeProtocolId);

  const handleShowEmptyValues = useCallback(() => {
    localStorage.setItem(SIDEBAR_SHOW_EMPTY_STATUS, JSON.stringify(!showEmptyValues));

    setShowEmptyValues(state => !state);
  }, [showEmptyValues]);

  const readOnly = !isRoleEqualOrHigher(maxRole, rolesIds.WRITE);

  return (
    <ContainerStyled>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={showEmptyValues}
              onChange={handleShowEmptyValues}
            />
          }
          label={
            <Typography
              sx={{
                fontSize: '12px'
              }}
            >
              Show empty values
            </Typography>
          }
        />

        {filteredProtocols.length ?
          <Tooltip
            title={isAllExpanded ? 'Collapse All' : 'Expand All'}
            placement="top"
          >
            <IconButton
              onClick={handleToggleExpand}
              sx={{
                '&:hover': {
                  backgroundColor: 'white'
                }
              }}
            >
              {isAllExpanded ?
                <UnfoldLessIcon
                  sx={{
                    fontSize: '14px'
                  }}
                />
                :
                <UnfoldMoreIcon
                  sx={{
                    fontSize: '14px'
                  }}
                />
              }
            </IconButton>
          </Tooltip> :
          null
        }
      </Box>

      {filteredProtocols.map(object => (
        <SingleProtocol
          key={object.id}
          id={id}
          isOpen={expandedList.includes(object.id)}
          onClose={handleToggleSingleObject}
          data={object}
          showEmptyValues={showEmptyValues}
          readOnly={readOnly}
        />
      ))}
    </ContainerStyled>
  );
};

ProtocolsTab.propTypes = {
  id: PropTypes.string,
  activeProtocolId: PropTypes.string,
  maxRole: PropTypes.string,
  table: PropTypes.object,
  values: PropTypes.array
};

export default ProtocolsTab;
