import React from 'react';
import PT from 'prop-types';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const WarningContent = ({
  itemName,
  onClose
}) => (
  <>
    <DialogTitle>Cannot archive the item</DialogTitle>

    <DialogContent>
      <DialogContentText>
        "{itemName}" cannot be archived because you don’t have the necessary permissions
      </DialogContentText>
    </DialogContent>

    <DialogActions sx={{ p: 2 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={onClose}
      >
        ok
      </Button>
    </DialogActions>
  </>
);

WarningContent.propTypes = {
  itemName: PT.string.isRequired,
  onClose: PT.func.isRequired
};

export default WarningContent;
