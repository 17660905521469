import React, { useCallback, useMemo, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PT from 'prop-types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import MoveTableDialog from '../../../../../Tables/cells/ActionsCell/MoveTableDialog';
import DeleteTableDialog from '../../../../../Tables/cells/ActionsCell/DeleteTableDialog/DeleteTableDialog';
import RenameTableDialog from '../../../../../Tables/cells/ActionsCell/RenameTableDialog';
import CreateItemDialog from '../../../../../../components/CreateItemDialog';

import { routes } from '../../../../../../services/session/constants';
import { TABS } from '../../../../../TableSettings/constants';
import { hasAdminAccess, hasWriteAccess } from '../../../../../../utils/roles';

import { GET_ITEMS, GET_TABLES } from '../../../../services';
import { openAppSnackbarNotification } from '../../../../../../services/snackbar-notifications/actions';
import client from '../../../../../../services/apollo-client';
import DuplicateTableDialog from '../../../../../Tables/cells/ActionsCell/DuplicateTableDialog';
import { SNACKBAR_TYPES } from '../../../../../../components/AppSnackbar';
import { CreateItemSuccessMessage } from '../../../../../../components/Sample/CreateItemMessages';

const TableMenu = ({ anchorEl, data, onClose, onFilterDelete }) => {
  const [openCreateItemDialog, setOpenCreateItemDialog] = useState(false);
  const [openMoveDialog, setOpenMoveDialog] = useState(false);
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);

  const dispatch = useDispatch();

  const handleCreateItem = useCallback(() => {
    setOpenCreateItemDialog(state => !state);
    onClose();
  }, [onClose]);

  const handleCreateItemDialogSuccess = useCallback((item) => {
    dispatch(
      openAppSnackbarNotification({
        variant: SNACKBAR_TYPES.SUCCESS,
        message: (
          <CreateItemSuccessMessage
            id={item.id}
            title={item.title}
            code={item.code}
          />
        )
      })
    );

    client.refetchQueries({
      include: [GET_ITEMS]
    });

    setOpenCreateItemDialog(state => !state);
  }, [dispatch]);

  const handleMove = useCallback(() => {
    setOpenMoveDialog(state => !state);
    onClose();
  }, [onClose]);

  const handleDuplicate = useCallback(() => {
    setOpenDuplicateDialog(state => !state);
    onClose();
  }, [onClose]);

  const handleRename = useCallback(() => {
    setOpenRenameDialog(state => !state);
    onClose();
  }, [onClose]);

  const handleDelete = useCallback(() => {
    setOpenDeleteDialog(state => !state);
    onClose();
  }, [onClose]);

  const handleTableDelete = useCallback(() => {
    onFilterDelete(data.id);
  }, [data.id, onFilterDelete]);

  const isAdmin = hasAdminAccess(data.viewerMaxRole);
  const hasWrite = hasWriteAccess(data.viewerMaxRole);

  const actions = [
    hasWrite ? {
      label: 'Add item',
      actionFn: handleCreateItem
    } : null,
    isAdmin ? {
      label: 'move table',
      actionFn: handleMove
    } : null,
    {
      label: 'duplicate table',
      actionFn: handleDuplicate
    },
    isAdmin ? {
      label: 'edit structure',
      link: generatePath(routes.TABLE_SETTINGS, {
        id: data.id,
      }),
      state: {
        tab: TABS.STRUCTURE.value
      }
    } : null,
    isAdmin ? {
      label: 'manage members',
      link: generatePath(routes.TABLE_SETTINGS, {
        id: data.id,
      }),
      state: {
        tab: TABS.MEMBERS.value
      }
    } : null,
    isAdmin ? {
      label: 'rename',
      actionFn: handleRename
    } : null,
    isAdmin ? {
      label: 'Delete',
      actionFn: handleDelete
    } : null
  ].filter(Boolean);

  const initialTable = useMemo(() => ({
    id: data.id,
    title: data.title,
  }), [data.id, data.title]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        MenuListProps={{
          dense: true
        }}
      >
        {actions.map(i => (
          <MenuItem
            key={i.label}
            onClick={i.actionFn}
            component={i.link ? Link : MenuItem}
            to={i.link ?? null}
            state={i.state}
          >
            <ListItemText sx={{ textTransform: 'capitalize' }}>
              {i.label}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>

      {openCreateItemDialog ?
        <CreateItemDialog
          initialTable={initialTable}
          onClose={handleCreateItem}
          onSuccess={handleCreateItemDialogSuccess}
        />
        : null
      }
      {openMoveDialog ?
        <MoveTableDialog
          onClose={handleMove}
          id={data.id}
          hash={data.hash}
          refetchList={[GET_ITEMS]}
        />
        : null
      }

      {openDuplicateDialog ?
        <DuplicateTableDialog
          tableId={data.id}
          defaultName={data.title}
          defaultDescription={data.description}
          onClose={handleDuplicate}
          refetchList={[GET_ITEMS, GET_TABLES]}
        />
        : null
      }

      {openDeleteDialog ?
        <DeleteTableDialog
          id={data.id}
          onClose={handleDelete}
          refetchList={[GET_ITEMS]}
          onDelete={handleTableDelete}
        />
        : null
      }

      {openRenameDialog ?
        <RenameTableDialog
          id={data.id}
          hash={data.hash}
          defaultName={data.title}
          onClose={handleRename}
          refetchList={[GET_ITEMS]}
        />
        : null
      }
    </>
  );
};

TableMenu.propTypes = {
  anchorEl: PT.instanceOf(Element),
  data: PT.shape({
    title: PT.string.isRequired,
    hash: PT.string,
    description: PT.string,
    id: PT.string.isRequired,
    viewerMaxRole: PT.string.isRequired,
  }).isRequired,
  onClose: PT.func.isRequired,
  onFilterDelete: PT.func.isRequired,
};

export default TableMenu;
