export const VIEW_TYPE_GRAPHICS = 'graphics';

export const VIEW_TYPE_IMAGE = 'Image';
export const VIEW_TYPE_2D = '2D';
export const VIEW_TYPE_2D_CUSTOM_AXES = '2D (Customized axes)';
export const VIEW_TYPE_3D = '3D';
export const VIEW_TYPE_HEATMAP = 'Heatmap';
export const VIEW_TYPE_DOCX = 'DOCX';
export const VIEW_TYPE_SCATTER_PLOT = 'Scatter plot';
export const VIEW_TYPE_BOX_PLOT = 'Box plot';
export const VIEW_TYPE_HISTOGRAM_SINGLE = 'Histogram (one column)';
export const VIEW_TYPE_WAFER_MAP = 'Wafer Map';
export const VIEW_TYPE_VECTOR_WAFER_MAP = 'Vector Wafer Map';
export const VIEW_TYPE_CORRELATION_MATRIX = 'Correlation Matrix';

export const RESPONSE_TYPE_DOWNLOAD_URL = 'downloadURL';
export const RESPONSE_TYPE_JSON = 'json';
export const RESPONSE_TYPE_TEXT = 'text';

export const PARSER_DATA_TYPE_FILE = 'file';
export const PARSER_DATA_TYPE_JSON = 'json';
export const PARSER_DATA_TYPE_TEXT = 'text';
export const PARSER_DATA_TYPE_COMPLEX = 'PARSER_DATA_TYPE_COMPLEX';

export const ASSET_CONTENT_NOT_PARSE = 'notParse';

export const HEATMAP = {
  DEFAULT_MARGINS: {
    top: 40,
    right: 20,
    bottom: 50,
    left: 60
  },
  CHART_GUTTERS: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 10
  }
};
