import * as linechartUtils from './linechart';
import initSurfaceChart, * as surfaceChartUtils from './surface-chart';
import mapLinechartData, * as mapLinechartDataUtils from './map-linechart-data';
import mapSurfaceData from './map-surface-data';
import * as constants from './constants';

export * from './get-data-by-type';

const graphicsUtils = {
  linechartUtils,
  initSurfaceChart,
  surfaceChartUtils,
  mapLinechartData,
  mapLinechartDataUtils,
  mapSurfaceData,
  constants
};

export default graphicsUtils;
