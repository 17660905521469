import { useRef, useMemo, useCallback } from 'react';
import { gql, useQuery, NetworkStatus } from '@apollo/client';

import { rolesIds } from '../../../utils/roles';

const TABLE_ITEMS_LIST = gql`
  query TableItemsList($input: TableItemsListInput, $first: Int, $after: String) {
    tableItemsList(input: $input, first: $first, after: $after) {
      edges {
        node {
          id
          title
          code
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export default function useItemsList() {
  const queryVariables = useRef({
    input: {
      minimumRole: rolesIds.READ,
      search: null
    },
    first: 100
  });

  const {
    data,
    fetchMore,
    refetch,
    loading,
    networkStatus,
    updateQuery,
    error
  } = useQuery(TABLE_ITEMS_LIST, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: queryVariables.current,
  });

  const endCursor = data?.tableItemsList?.pageInfo?.endCursor;
  const hasMore = data?.tableItemsList?.pageInfo?.hasNextPage;

  const items = useMemo(() => {
    if(!Array.isArray(data?.tableItemsList?.edges))
      return [];

    return data.tableItemsList.edges.map(item => item.node);
  }, [data]);

  const loadMore = useCallback(() => {
    if(!hasMore) return;

    return fetchMore({
      variables: {
        ...queryVariables.current,
        after: endCursor
      }
    });
  }, [fetchMore, endCursor, hasMore]);

  const search = useCallback((value) => {
    queryVariables.current.input.search = value;

    refetch({
      ...queryVariables.current
    });
  }, [refetch]);

  return {
    items,
    networkStatus: {
      loading,
      loadingMore: networkStatus === NetworkStatus.fetchMore,
      refetching: networkStatus === NetworkStatus.refetch
    },
    loadMore,
    search,
    updateQuery,
    error
  };
}
