import { gql } from '@apollo/client';

export const RESTORE_ITEM_OBJECTS = gql`
  mutation RestoreItemObjects($input: [BatchItemObjectsUpdateInput!]!) {
    restoreItemObjects(input: $input)
  }
`;

export const DELETE_ITEM_OBJECTS = gql`
  mutation DeleteTableItems($ids: [ID!]!) {
    deleteTableItems(ids: $ids)
  }
`;
