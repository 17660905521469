import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Box from '@mui/material/Box';

import { PageSection } from '../Page';
import Button from '../Button';
import LoadingBanner from '../LoadingBanner';

import { COLOR_PRIMARY_LIGHT, COLOR_WHITE } from '../../styles';

const ActionsSection = ({
  onSubmit,
  onCancel,
  onSaveAsTemplate,
  disabled,
  loading,
  submitDisabled,
  isTemplateCreateDisabled,
  submitLabel,
  className,
  isProtocol
}) => (
  <PageSection className={`actions-section ${className}`}>

    {isProtocol &&
      <Button
        onClick={onSaveAsTemplate}
        variant="outlined"
        color="primary"
        disabled={loading || disabled || isTemplateCreateDisabled}
      >
        Save as Template
      </Button>
    }

    <Box>
      {loading &&
      <LoadingBanner
        spinner
        height={35}
        color={COLOR_PRIMARY_LIGHT}
        bcgcolor={COLOR_WHITE}
      />
      }

      <Button
        className="cancel-btn"
        label="cancel"
        onClick={onCancel}
        variant="text"
        disabled={loading || disabled}
      />

      <Button
        className="submit-btn"
        label={submitLabel}
        onClick={onSubmit}
        variant="contained"
        color="primary"
        disabled={loading || disabled || submitDisabled}
      />
    </Box>
  </PageSection>
);

ActionsSection.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaveAsTemplate: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  isTemplateCreateDisabled: PropTypes.bool,
  submitLabel: PropTypes.string,
  className: PropTypes.string,
  isProtocol: PropTypes.bool,
};

ActionsSection.defaultProps = {
  submitDisabled: false,
  submitLabel: 'save'
};

export default styled(ActionsSection)`
  .content-area {
    flex-direction: row;
    justify-content: space-between;

    button {
      min-width: 90px;

      &.cancel-btn {
        margin: 0 15px;
        background-color: transparent;
      }
    }
  }
`;
