import { useReducer, useCallback, useMemo } from 'react';

const actions = {
  TOGGLE_VISIBILITY: 'TOGGLE_VISIBILITY'
};

const createInitialState = options => {
  const state = {
    parentOptions: [],
    optionsById: {}
  };

  for(const _option of options) {
    const option = {
      id: _option.id,
      title: _option.title,
      parentId: null,
      columns: []
    };

    state.parentOptions.push(option.id);
    state.optionsById[option.id] = option;
    for(const _column of _option.columns) {
      const column = {
        id: _column.id,
        title: _column.title,
        parentId: option.id,
        visible: _column.visible,
        notActive: _column.notActive
      };

      option.columns.push(column.id);
      state.optionsById[column.id] = column;
    }
  }

  return state;
};

const reducer = (state, action) => {
  switch(action.type) {
    case actions.TOGGLE_VISIBILITY: {
      const { ids, val } = action.payload;
      const updatedState = {};

      for(const id of ids) {
        updatedState[id] = {
          ...state.optionsById[id],
          visible: val
        };
      }

      return {
        ...state,
        optionsById: {
          ...state.optionsById,
          ...updatedState
        }
      };
    }

    default:
      return state;
  }
};

const useVisibleColumnsState = ({ options }) => {
  const [state, dispatch] = useReducer(reducer, options, createInitialState);

  const toggleVisibility = useCallback((ids, val) => {
    dispatch({
      type: actions.TOGGLE_VISIBILITY,
      payload: { ids, val }
    });
  }, []);

  const computedState = useMemo(() => {
    return state.parentOptions.map(id => {
      const { columns: columnIds, title } = state.optionsById[id];
      const columns = columnIds.map(id => state.optionsById[id]);
      const visible = columns.every(({ visible }) => visible);
      const notActive = columns.every(({ notActive }) => notActive);

      return {
        id,
        title,
        columns,
        visible,
        notActive
      };
    });
  }, [state]);

  return [computedState, toggleVisibility];
};

export default useVisibleColumnsState;
