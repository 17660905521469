import React, { useMemo, useState, useCallback } from 'react';
import PT from 'prop-types';
import { generatePath } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';

import CopyToClipboard from './CopyToClipboard';

import QRCodeDialog from '../../../QRCodeDialog';
import CloneItemDialog from '../../../Sample/CloneItemDialog/CloneItemDialog';

import { routes } from '../../../../services/session/constants';

const MenuListProps = {
  dense: true
};

const ActionsMenu = ({
  item,
  open,
  onClose,
  anchorEl,
  onArchive,
  onDelete,
}) => {
  const { id, title, code } = item;
  const [qrCodeDialogOpen, setQrCodeDialogOpen] = useState(false);
  const [cloneItemDialogOpen, setCloneItemDialogOpen] = useState(false);

  const handleQRCodeDialogOpen = useCallback(() => {
    setQrCodeDialogOpen(true);
    onClose();
  }, [onClose]);

  const handleQRCodeDialogClose = useCallback(() => {
    setQrCodeDialogOpen(false);
  }, []);

  const handleCloneItemDialogOpen = useCallback(() => {
    setCloneItemDialogOpen(true);
    onClose();
  }, [onClose]);

  const handleCloneItemDialogClose = useCallback(() => {
    setCloneItemDialogOpen(false);
  }, []);

  const handleArchive = useCallback(() => {
    onArchive(id);
    onClose();
  }, [id, onArchive, onClose]);

  const handleDelete = useCallback(() => {
    onDelete(id);
    onClose();
  }, [id, onDelete, onClose]);

  const itemPagePath = useMemo(() => {
    return generatePath(routes.ITEMS, {
      sampleId: id
    });
  }, [id]);

  const itemPageURL = window.location.origin + itemPagePath;

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={MenuListProps}
      >
        <CopyToClipboard
          text={itemPageURL}
          onCopied={onClose}
        >
          <MenuItem>
            <ListItemIcon>
              <FileCopyIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText>
              Copy Item URL
            </ListItemText>
          </MenuItem>
        </CopyToClipboard>

        <MenuItem
          onClick={handleQRCodeDialogOpen}
        >
          <ListItemIcon>
            <QrCode2Icon fontSize="small" />
          </ListItemIcon>

          <ListItemText>
            View QR code
          </ListItemText>
        </MenuItem>

        <MenuItem
          onClick={handleCloneItemDialogOpen}
        >
          <ListItemIcon>
            <FileCopyOutlinedIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText>
            Duplicate Item
          </ListItemText>
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={handleArchive}
        >
          <ListItemIcon>
            <ArchiveIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText>
            Archive
          </ListItemText>
        </MenuItem>

        <MenuItem
          onClick={handleDelete}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>

          <ListItemText>
            Delete
          </ListItemText>
        </MenuItem>
      </Menu>

      {qrCodeDialogOpen ?
        <QRCodeDialog
          open
          itemId={id}
          primaryTitle={title}
          secondaryTitle={code}
          onClose={handleQRCodeDialogClose}
          itemLink={itemPageURL}
        /> :
        null
      }

      {
        cloneItemDialogOpen ?
          <CloneItemDialog
            data={item}
            onClose={handleCloneItemDialogClose}
          /> :
          null
      }
    </>
  );
};

ActionsMenu.propTypes = {
  item: PT.object,
  open: PT.bool.isRequired,
  onClose: PT.func,
  anchorEl: PT.instanceOf(Element),
  onArchive: PT.func,
  onDelete: PT.func,
};

export default ActionsMenu;
