import { gql } from '@apollo/client';

export const FETCH_ITEMS = gql`
  query FetchItems($query: ItemsListInput, $first: Int, $after: String, $withFilter: Boolean!, $withTotal: Boolean!) {
    items(query: $query, first: $first, after: $after) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      
      searchInfo {
        totalNumberOfItems @include(if: $withTotal)
        totalNumberOfFilteredItems @include(if: $withFilter)
      } 

      edges {
        cursor
        node {
          id
          code
          title
          description
          hasAssignedObjects
          isUsedInProtocols
          viewerMaxRole
          objectsStat {
            measurements
            preparation
            observation
          }
          archived
          isUsedInProtocols
          owner {
            id
            name

            ... on CurrentUser {
              userId
              avatarColor
              picture {
                downloadURL
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_VIEWER = gql`
  query ArchiveItemsViewerInfo {
    viewer {
      id
      userId
      name
      avatarColor
      picture {
        downloadURL
      }
    }
  }
`;
