import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

import LinkedItemsTab from './LinkedItemsTab';
import ProtocolsTab from './ProtocolsTab';
import CommentsTab from './CommentsTab';
import MeasurementsTab from './MeasurementsTab/MeasurementsTab';

import TabStyled, { TabPanelStyled } from './styles';

import { TABS_VALUES } from '../../../scenes/AllItems/constants';
import { OPEN_STATE } from '../constants';

const TabsBlock = ({
  data,
  linkedItems,
  onItemRefetchRequest,
  defaultTab,
  sidebarState = OPEN_STATE.FULLSCREEN
}) => {
  const [currentTab, setCurrentTab] = useState(defaultTab || TABS_VALUES.PROTOCOLS);
  const [activeProtocolId, setActiveProtocolId] = useState(null);

  const handleChange = useCallback((e, value) => {
    setCurrentTab(value);
  }, []);

  const handleOpenProtocol = useCallback((id) => {
    setCurrentTab(TABS_VALUES.PROTOCOLS);
    setActiveProtocolId(id);
  }, []);

  const protocolsAmount = data?.table.numberOfTableProtocols || 0;
  const measurementsAmount = data?.numberOfTableItemFiles || 0;
  const linkedItemsAmount = linkedItems ? linkedItems.to.length + linkedItems.from.length : 0;
  const commentsAmount = data?.interactions.comments || 0;

  return (
    <Box>
      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: 'black'
              }
            }}
            variant="scrollable"
            scrollButtons={false}
          >
            <TabStyled label={`Protocols (${protocolsAmount})`} value={TABS_VALUES.PROTOCOLS} />
            <TabStyled label={`Measurements (${measurementsAmount})`} value={TABS_VALUES.MEASUREMENTS} />
            {linkedItemsAmount || currentTab === TABS_VALUES.LINKED_ITEMS ?
              <TabStyled label={`Linked Items (${linkedItemsAmount})`} value={TABS_VALUES.LINKED_ITEMS} />
              : null
            }
            <TabStyled label={`Comments (${commentsAmount})`} value={TABS_VALUES.COMMENTS} />
          </TabList>
        </Box>

        <TabPanelStyled value={TABS_VALUES.PROTOCOLS}>
          <ProtocolsTab
            id={data?.id}
            activeProtocolId={activeProtocolId}
            maxRole={data?.table.viewerMaxRole}
            table={data?.table}
            values={data?.values}
          />
        </TabPanelStyled>

        <TabPanelStyled value={TABS_VALUES.MEASUREMENTS}>
          <MeasurementsTab
            itemId={data?.id}
            maxRole={data?.table.viewerMaxRole}
            measurements={data?.files ?? []}
            sidebarState={sidebarState}
            onItemRefetchRequest={onItemRefetchRequest}
          />
        </TabPanelStyled>

        <TabPanelStyled value={TABS_VALUES.LINKED_ITEMS}>
          <LinkedItemsTab
            data={linkedItems}
            onProtocolClick={handleOpenProtocol}
          />
        </TabPanelStyled>

        <TabPanelStyled value={TABS_VALUES.COMMENTS}>
          <CommentsTab
            data={data}
          />
        </TabPanelStyled>
      </TabContext>
    </Box>
  );
};

TabsBlock.propTypes = {
  linkedItems: PropTypes.object,
  onItemRefetchRequest: PropTypes.func.isRequired,
  data: PropTypes.object,
  defaultTab: PropTypes.string,
  sidebarState: PropTypes.string
};

export default TabsBlock;
