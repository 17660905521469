export const createColumn = (table, columnDef, depth, parent = null) => {
  const {
    id,
    title,
    accessorKey,
    accessorFn
  } = columnDef;

  let accessor;

  if(accessorFn) {
    accessor = accessorFn;
  } else if(accessorKey) {
    accessor = row => {
      // TODO: should be rewritten a "accessorKey" prop as string only
      if (typeof accessorKey === 'string') {
        return row[accessorKey];
      } else {
        if (row[accessorKey[0]] || !accessorKey[1])
          return row[accessorKey[0]];

        return row[accessorKey[1]];
      }
    };
  }

  const column = {
    id,
    title,
    accessor,
    parent,
    depth,
    columnDef,
    columns: [],
    isVisible: () => {
      return table.isColumnVisible(id);
    },
    isCollapsed: () => {
      return table.isColumnCollapsed(id);
    },
    toggleVisibility: (val) => {
      table.toggleColumnVisibility(id, val);
    },
    toggleCollapse: (val) => {
      table.toggleColumnCollapse(id, val);
    },
    getFlatColumns: () => {
      return [
        column,
        ...column.columns.flatMap(c => c.getFlatColumns())
      ];
    },
    getLeafColumns: () => {
      if(column.columns?.length)
        return column.columns.flatMap(column => column.getLeafColumns());

      return [column];
    }
  };

  return column;
};
