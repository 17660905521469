import React, { useRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAnalytics } from 'use-analytics';
import withStyles from '@mui/styles/withStyles';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import OutlinedInput from '@mui/material/OutlinedInput';

import LoadingBanner from '../LoadingBanner';

import FilterBarStyled from './styles';
import { COLOR_PRIMARY, COLOR_WHITE, COLOR_GREY_LIGHT } from '../../styles';
import Button from '@mui/material/Button';
import { componentNames, TRACK_SINGLE_ITEM } from '../../analytics/constants';

const styles = {
  searchRoot: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box'
  },
  searchInput: {
    padding: 0,
    height: '100%'
  },
  clearIcon: {
    cursor: 'pointer',
    color: COLOR_GREY_LIGHT
  }
};

const FilterBar = ({
  isSearching,
  onTextChange,
  classes: {
    searchRoot,
    searchInput,
    clearIcon
  },
}) => {
  const [value, setValue] = useState('');

  const { track } = useAnalytics();

  const inputRef = useRef();

  const handleSearch = useCallback(() => {
    onTextChange(value);

    track(TRACK_SINGLE_ITEM.search, {
      component: componentNames.ITEM_SEARCH,
      additional_info: {
        search_string: value
      }
    });
  }, [onTextChange, track, value]);

  const handleKeyDown = useCallback((ev) => {
    const { key } = ev;

    if (key === 'Enter') {
      handleSearch();
    }
  }, [handleSearch]);

  const handleSearchClear = useCallback(async() => {
    if (value) {
      setValue('');
      onTextChange('');
    }
  }, [onTextChange, value]);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  useEffect(() => {
    if (isSearching){
      inputRef.current.blur();
    }
  }, [isSearching]);

  return (
    <FilterBarStyled>
      <div
        className="search-container"
      >
        <OutlinedInput
          value={value}
          className="search-input"
          inputRef={inputRef}
          disabled={isSearching}
          onKeyDown={handleKeyDown}
          placeholder="Search items"
          classes={{
            root: searchRoot,
            input: searchInput
          }}
          onChange={handleChange}
          endAdornment={
            <div className="actions">
              {isSearching
                ? <LoadingBanner
                  spinner
                  height={20}
                  color={COLOR_PRIMARY}
                  bcgcolor={COLOR_WHITE}
                />
                : null
              }

              {value && <ClearIcon
                className={clearIcon}
                onClick={isSearching ?  undefined : handleSearchClear}
              />}
            </div>
          }
        />
        <Button
          onClick={handleSearch}
          className="search-button"
          color="primary"
          variant="contained"
          size="small"
        >
          <SearchIcon />
        </Button>
      </div>
    </FilterBarStyled>
  );
};

FilterBar.propTypes = {
  classes: PropTypes.object,
  isSearching: PropTypes.bool,
  loading: PropTypes.bool,
  onTextChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(FilterBar);
