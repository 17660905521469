import client from '../../services/apollo-client';
import { gql } from '@apollo/client';

export const GET_VIEWER = gql`
  query ViewerBasicSessionProfile {
    viewer {
      id
      name
    }
  }
`;

const fetchUser = async () => {
  try {
    const viewerResponse = await client.query({
      query: GET_VIEWER,
      fetchPolicy: 'network-only'
    });

    return  viewerResponse.data?.viewer;
  } catch (e) {
    return null;
  }
};

const sendEvent = async (userConfig, data) => {
  if(navigator.sendBeacon) {
    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });

    navigator.sendBeacon(userConfig.path, blob);
  } else {
    try {
      await fetch(userConfig.path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
    } catch(e) {
      console.error(e);
    }
  }
};

export default function activityLog (userConfig) {
  let userData;

  const getUser = async () => {
    let user = userData;

    if(!user) {
      let viewer = await fetchUser();

      user = {
        id: viewer?.id,
        name: viewer?.name
      };
    }

    return user;
  };

  return {
    name: 'activityLog',
    config: {
      path: userConfig.path,
      enabled: userConfig.enabled
    },
    identify: ({ payload }) => {
      const { user } = payload.traits;

      userData = {
        id: user?.id,
        name: user?.name
      };
    },
    page: async () => {
      const user = await getUser();

      const data = {
        source: 'webapp',
        event_name: 'page__view',
        user_id: user.id,
        user_name: user.name,
        timestamp: Date.now(),
        page: window.document.title,
        path: window.location.pathname,
        url: window.location.href,
        component: window.document.title
      };

      await sendEvent(userConfig, data);
    },
    track: async ({ payload }) => {
      const user = await getUser();

      const data = {
        source: 'webapp',
        page: document.title,
        event_name: payload.event,
        component: payload.properties.component,
        additional_info: payload.properties?.additional_info ?
          JSON.stringify(payload.properties?.additional_info) : undefined,
        user_id: user.id,
        user_name: user.name,
        timestamp: Date.now(),
        path: window.location.pathname,
        url: window.location.href
      };

      await sendEvent(userConfig, data);
    }
  };
}
