import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';
import defaults from 'lodash/defaults';

export const initState = {
  selectAll: false,
  itemsById: {}
};

export const actions = {
  RESET: 'RESET',

  ADD_ITEMS: 'ADD_ITEMS',
  REMOVE_ITEMS: 'REMOVE_ITEMS',

  CHECK_ITEM: 'CHECK_ITEM',
  CHECK_ALL_ITEMS: 'CHECK_ALL_ITEMS',
};

const itemDefaults = {
  checked: false,
};

const checkItem = (item, checked) => {

  return {
    ...item,
    checked,
  };
};

export const reducer = (state, action) => {
  switch (action.type){
    case actions.RESET:
      return {
        selectAll: false,
        itemsById: {}
      };

    case actions.ADD_ITEMS: {
      const ids = action.payload;
      const selectAll = state.selectAll;

      const newItems = ids.reduce((prev, id) => ({
        ...prev,
        [id]: defaults({ checked: selectAll }, itemDefaults)
      }), {});

      return {
        ...state,
        itemsById: {
          ...newItems,
          ...state.itemsById
        }
      };
    }

    case actions.REMOVE_ITEMS: {
      const ids = action.payload;
      const filteredItems = omit(state.itemsById, ids);

      return {
        ...state,
        itemsById: filteredItems
      };
    }

    case actions.CHECK_ITEM: {
      const { checked, id } = action.payload;
      const currentItem = state.itemsById[id];
      const updatedItem = checkItem(currentItem, checked);

      return {
        ...state,
        selectAll: !checked ? false : state.selectAll,
        itemsById: {
          ...state.itemsById,
          [id]: updatedItem
        }
      };
    }

    case actions.CHECK_ALL_ITEMS: {
      const { checked } = action.payload;
      let items = state.itemsById;
      const updatedItems = mapValues(items, item => checkItem(item, checked));

      return {
        ...state,
        selectAll: checked,
        itemsById: updatedItems
      };
    }

    default:
      return state;
  }
};
