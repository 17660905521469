import { useCallback, useEffect, useMemo, useState } from 'react';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useAnalytics } from 'use-analytics';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';

import { SNACKBAR_TYPES } from '../../../../components/AppSnackbar';

import { openAppSnackbarNotification } from '../../../../services/snackbar-notifications/actions';

import { componentNames, TRACK_SAVED_VIEW } from '../../../../analytics/constants';
import { TABLE_VIEW } from '../../constants';

const SAVED_VIEWS = gql`
  query UserTableItemQueries {
    userTableItemQueries {
      id
      tableItemQueryId
      dashboardId
      dashboardStateId
      title
    }
  }
`;

const CREATE_VIEW = gql`
  mutation CreateUserTableItemQuery($input: CreateUserTableItemQueryInput!) {
    createUserTableItemQuery(input: $input) {
      id
      title
      tableItemQueryId
      dashboardId
      dashboardStateId
    }
  }
`;

const UPDATE_VIEW = gql`
  mutation UpdateUserTableItemQuery($id: ID!, $input: UpdateUserTableItemQueryInput!) {
    updateUserTableItemQuery(id: $id, input: $input) {
      id
      title
      tableItemQueryId
      dashboardId
      dashboardStateId
    }
  }
`;

const DELETE_VIEW = gql`
  mutation DeleteUserTableItemQuery($id: ID!) {
    deleteUserTableItemQuery(id: $id)
  }
`;

const ITEM_QUERY = gql`
  query ItemQuery($id: ID!) {
    tableItemQuery(id: $id) {
      filters {
        createdTimestamp {
          from
          to
        }
        featureSliceByList {
          featureSliceBys {
            featureId
            boolean
            include
            linkItemIds
            quantityMin
            quantityMax
            textValues
            excludeMissingValueFlag
          }
        }
        creatorIds
        tableIds
      }
      search
      sort {
        featureId
        order
        param
      }
      tableHeaderFeatureIDs
    }
  }
`;

const DASHBOARD_STATE = gql`
  query DashboardState($id: ID!) {
    dashboardState(id: $id)
  }
`;

const CREATE_DASHBOARD_STATE = gql`
  mutation CreateDashboardState($data: String!) {
    createDashboardState(data: $data)
  }
`;

const recurseQueryInput = (value) => {
  if(isPlainObject(value)) {
    let _value;

    for(const [k, v] of Object.entries(value)) {
      if(k.startsWith('__') || v == null) continue;

      const _v = recurseQueryInput(v);

      if(_v != null)
        Object.assign(_value ??= {}, { [k]: _v });
    }

    return _value;
  } else if(Array.isArray(value)) {
    return value.map(_v => recurseQueryInput(_v));
  }

  return value;
};

/**
 * @param {object} params
 * @param {string} params.queryId - The ID of the query associated with the current view.
 * @param {string} params.displayView
 * @param {Object} params.insightsData - Data containing insights information.
 * @param {Object} params.queryVariables - Variables for the tableItems query.
 * @param {Object} params.pageRef
 * @param {Object} params.dashboardRef
 * @param {Object} params.actions - Actions that can be performed on the saved view.
 * @param {Object} params.savedView
 * @param {Function} params.setSearchText
 * @param {Function} params.setDisableParamsFilterSync
 * @param {Function} params.refetchQuery - Function to refetch the tableItems query.
 * @param {Function} params.setActiveFilters
 * @param {Object} params.itemsSorting - Object containing function for item sorting.
 *
 * @returns {{
 *     selectedView: Object,
 *     onSelect: Function,
 *     onUpdate: Function,
 *     onSave: Function,
 *     onRename: Function,
 *     onDelete: Function,
 *     onDiscard: Function,
 * }}
 */
const useSavedViews = ({
  queryId,
  displayView,
  insightsData,
  queryVariables,
  pageRef,
  dashboardRef,
  actions,
  savedView,

  setSearchText,
  setDisableParamsFilterSync,
  refetchQuery,
  setActiveFilters,
  itemsSorting
}) => {
  const { data, refetch } = useQuery(SAVED_VIEWS, {
    fetchPolicy: 'cache-and-network'
  });
  const [fetchItemQuery] = useLazyQuery(ITEM_QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const [fetchDashboardState] = useLazyQuery(DASHBOARD_STATE, {
    fetchPolicy: 'cache-and-network'
  });

  const [createDashboardState] = useMutation(CREATE_DASHBOARD_STATE);
  const [createView] = useMutation(CREATE_VIEW);
  const [updateView] = useMutation(UPDATE_VIEW);
  const [deleteView] = useMutation(DELETE_VIEW);

  const [dashboardState, setDashboardState] = useState(savedView?.dashboardState ?? null);

  const dispatch = useDispatch();

  const { track } = useAnalytics();

  const selectedView = useMemo(() => {
    const viewsList = data?.userTableItemQueries;

    return savedView && viewsList.find(({ id }) => id === savedView.id);
  }, [data?.userTableItemQueries, savedView]);

  const handleDashboardMessage = useCallback(ev => {
    if(ev.source !== dashboardRef.current?.contentWindow)
      return;

    const message = JSON.parse(ev.data);

    if(message.hasOwnProperty('event') && message.event === 'DASHBOARD_STATE') {
      setDashboardState(message.payload);
    }
  }, [dashboardRef]);

  useEffect(() => {
    if(displayView !== TABLE_VIEW) {
      window.addEventListener('message', handleDashboardMessage);
    }

    return () => {
      window.removeEventListener('message', handleDashboardMessage);
    };
  }, [displayView, handleDashboardMessage]);

  const viewChanged = savedView ? [
    !isEqual(queryVariables.input, savedView.queryInput),
    !isEqual(JSON.parse(savedView.dashboardState), JSON.parse(dashboardState))
  ].some(Boolean) : false;

  const insightsLabels = useMemo(() => {
    return insightsData.insights.reduce((acc, insight) => ({
      ...acc,
      [insight.id]: insight.title
    }), {});
  }, [insightsData.insights]);

  const handleSelectView = useCallback(async (data, preventDashboardReload) => {
    const { id, title, tableItemQueryId, dashboardId, dashboardStateId } = data;

    const payload = {
      id,
      displayView: dashboardId ?? TABLE_VIEW,
      queryInput: null,
      dashboardStateId: dashboardStateId ?? null,
      dashboardState: null,
      tableItemQueryId,
      title
    };

    try {
      const [itemQueryResponse, dashboardStateResponse] = await Promise.all([
        fetchItemQuery({ variables: { id: tableItemQueryId } }),
        dashboardStateId ? fetchDashboardState({ variables: { id: dashboardStateId } }) : null
      ]);

      if(itemQueryResponse.error)
        throw itemQueryResponse.error;

      if(dashboardStateResponse?.error)
        throw dashboardStateResponse.error;

      payload.queryInput = recurseQueryInput(itemQueryResponse.data.tableItemQuery);
      payload.dashboardState = dashboardStateResponse?.data?.dashboardState ?? null;

      setDashboardState(payload.dashboardState);

      if (!data) {
        actions.setSavedView(null);
        return;
      }
      // When trying to set the 'input' value to `queryInput`
      // the query is triggered with `networkStatus === 2` ('setVariables').
      // To 'refetch' the query, all existing fields of the input must be deleted
      // and new fields should be assigned to the exact same 'input' object
      for (const k of Object.keys(queryVariables.input))
        delete queryVariables.input[k];

      for (const [k, v] of Object.entries(payload.queryInput)) {
        queryVariables.input[k] = v;
        setSearchText(queryVariables?.input.search);
      }

      setDisableParamsFilterSync(true);

      await refetchQuery(queryVariables);

    } catch (e) {
      console.error(e);

      dispatch(
        openAppSnackbarNotification({
          variant: SNACKBAR_TYPES.ERROR,
          message: e.message
        })
      );
    }

    track(TRACK_SAVED_VIEW.change, {
      component: componentNames.DATA_VIEW,
      additional_info: {
        ai_saved_view_name: payload.title
      }
    });

    // Set active filters after refetch because the input values
    // may not exist in current data
    setActiveFilters(queryVariables.input.filters ?? {});
    setDisableParamsFilterSync(false);
    actions.setSavedView(payload, preventDashboardReload);
    itemsSorting.sortItems(queryVariables.input.sort ?? { param: null, order: null });

    pageRef.current.scrollTo(0, 0);
  }, [
    track,
    setActiveFilters,
    queryVariables,
    setDisableParamsFilterSync,
    actions,
    itemsSorting,
    pageRef,
    fetchItemQuery,
    refetchQuery,
    fetchDashboardState,
    setSearchText,
    dispatch
  ]);

  const handleUpdateView = useCallback(async (id, payload) => {
    try {
      return await updateView({
        variables: {
          id,
          input: payload
        }
      });
    } catch(e) {
      console.error(e);

      dispatch(
        openAppSnackbarNotification({
          variant: SNACKBAR_TYPES.ERROR,
          message: e.message
        })
      );
    }
  }, [dispatch, updateView]);

  const handleSaveNewView = useCallback(async (title) => {
    try {
      const variables = {
        input: {
          title,
          tableItemQueryId: queryId
        }
      };

      if(displayView !== TABLE_VIEW) {
        variables.input.dashboardId = displayView;

        if(dashboardState) {
          const { data } = await createDashboardState({
            variables: {
              data: dashboardState
            }
          });

          variables.input.dashboardStateId = data?.createDashboardState;
        }
      }

      const response = await createView({ variables });

      track(TRACK_SAVED_VIEW.save, {
        component: componentNames.DATA_VIEW,
        additional_info: {
          ai_view_type: insightsLabels[displayView] ?? 'table'
        }
      });

      await refetch();

      await handleSelectView({
        id: response.data.createUserTableItemQuery.id,
        title,
        tableItemQueryId: queryId,
        dashboardId: displayView,
        dashboardStateId: variables.input.dashboardStateId
      }, true);
    } catch(e) {
      console.error(e);

      dispatch(
        openAppSnackbarNotification({
          variant: SNACKBAR_TYPES.ERROR,
          message: e.message
        })
      );
    }
  }, [createDashboardState, createView, dashboardState, dispatch, displayView, handleSelectView, insightsLabels, queryId, refetch, track]);

  const handleSaveChangedView = useCallback(async () => {
    try {
      const payload = {
        tableItemQueryId: queryId
      };

      if(displayView !== TABLE_VIEW) {
        if(dashboardState) {
          const { data } = await createDashboardState({
            variables: {
              data: dashboardState
            }
          });

          payload.dashboardStateId = data?.createDashboardState;
          payload.dashboardId = displayView;
        }
      }

      const response = await handleUpdateView(savedView?.id, payload);

      track(TRACK_SAVED_VIEW.save, {
        component: componentNames.DATA_VIEW,
        additional_info: {
          ai_view_type: insightsLabels[displayView] ?? 'table'
        }
      });

      if(response) {
        actions.updateSavedView({
          queryInput: { ...queryVariables.input },
          dashboardState,
          dashboardStateId: payload.dashboardStateId ?? null,
          tableItemQueryId: response.data.updateUserTableItemQuery.tableItemQueryId
        });
      }
    } catch(e) {
      console.error(e);

      dispatch(
        openAppSnackbarNotification({
          variant: SNACKBAR_TYPES.ERROR,
          message: e.message
        })
      );
    }
  }, [
    actions,
    createDashboardState,
    dashboardState,
    dispatch,
    displayView,
    handleUpdateView,
    insightsLabels,
    queryId,
    queryVariables,
    savedView?.id,
    track,
  ]);

  const handleDeleteView = useCallback(async (id) => {
    try {
      await deleteView({
        variables: { id }
      });

      if(savedView?.id === id) {
        actions.setSavedView(null);
      }

      refetch();
    } catch(e) {
      console.error(e);

      dispatch(
        openAppSnackbarNotification({
          variant: SNACKBAR_TYPES.ERROR,
          message: e.message
        })
      );
    }
  }, [actions, deleteView, dispatch, refetch, savedView?.id]);

  const handleDiscardChanges = useCallback(() => {
    setDashboardState(savedView.dashboardState);

    handleSelectView({ ...savedView, dashboardId: savedView.displayView });
  }, [handleSelectView, savedView]);

  return {
    selectedView: selectedView ? {
      ...selectedView,
      changed: viewChanged
    } : null,
    onSelect: handleSelectView,
    onUpdate: handleSaveChangedView,
    onSave: handleSaveNewView,
    onRename: handleUpdateView,
    onDelete: handleDeleteView,
    onDiscard: handleDiscardChanges,
  };
};

export default useSavedViews;
