import React from 'react';
import PT from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import WarningBlock from '../WarningBlock';

const ArchiveItemsSubmitDialog = ({
  open,
  onSubmit,
  onClose,
  forbidden
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Confirm the archive action</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to archive all selected items, protocols, and measurements?
        </DialogContentText>

        {forbidden?.length ?
          <WarningBlock sx={{ mt: 2 }}>
            {forbidden.map(({ itemTitle }) => `"${itemTitle}"`).join(', ')} cannot be archived
            because you don’t have the necessary permissions
          </WarningBlock> :
          null
        }
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={onClose}
          color="primary"
        >
          Cancel
        </Button>

        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
        >
          Yes, archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ArchiveItemsSubmitDialog.propTypes = {
  open: PT.bool.isRequired,
  onSubmit: PT.func.isRequired,
  onClose: PT.func.isRequired,
  forbidden: PT.arrayOf(PT.shape({
    itemTitle: PT.string
  }))
};

export default ArchiveItemsSubmitDialog;
