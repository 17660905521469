import { gql } from '@apollo/client';

export const UPDATE_FEATURE_VALUE = gql`
  mutation UpdateFeatureValue($input: UpdateTableValueInput!) {
    updateTableValue(input: $input) {
      tableParameterId
      tableItemId
      quantity
      text
      boolean
      linkedTableItem {
        id
        code
        title
        viewerMaxRole
      }
    }
  }
`;
