import React, { useRef, useCallback } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

const PanelResizeControl = ({ className, panelWidth, onResize }) => {
  const ref = useRef();

  const handleStartResize = useCallback(ev => {
    ref.current.addEventListener('pointermove', onResize);
    ref.current.setPointerCapture(ev.pointerId);
  }, [onResize]);

  const handleStopResize = useCallback(ev => {
    ref.current.removeEventListener('pointermove', onResize);
    ref.current.releasePointerCapture(ev.pointerId);

    window.localStorage.setItem('FILTER_PANEL_WIDTH', panelWidth);
  }, [onResize, panelWidth]);

  return (
    <div
      className={className}
      ref={ref}
      onPointerDown={handleStartResize}
      onPointerUp={handleStopResize}
    >
      &nbsp;
    </div>
  );
};

PanelResizeControl.propTypes = {
  className: PT.string.isRequired,
  panelWidth: PT.number.isRequired,
  onResize: PT.func.isRequired
};

export default styled(PanelResizeControl)`
  position: absolute;
  width: 2px;
  height: 100%;
  left: ${({ panelWidth }) => `${panelWidth}px`};
  top: 0;
  cursor: col-resize;
`;
