import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { generatePath } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import PropTypes from 'prop-types';

import TableCell from '@mui/material/TableCell';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import TextWithLink from '../../../../../components/TextWithLink';
import LinkedProtocolFromItem from './LinkedProtocolFromItem';
import LinkedProtocolToItem from './LinkedProtocolToItem';
import Avatar from '../../../../Avatar';

import { CellStyled } from './styles';

import { hasAccess } from '../../../../../utils/roles';
import { generateHash } from '../../../../../utils/hash-manager';

import { routes } from '../../../../../services/session/constants';
import { TABS } from '../constants';
import { componentNames, TRACK_ITEM_LINKAGE } from '../../../../../analytics/constants';

const LinkedItem = ({ data, onProtocolClick, activeTab }) => {
  const {
    id,
    title,
    code,
    viewerMaxRole,
    archived,
    linkingProtocols
  } = data;

  const { track } = useAnalytics();

  const handleTitleClick = useCallback(() => {
    const direction = activeTab === TABS.FROM ? 'from' : 'to';

    track(TRACK_ITEM_LINKAGE.click, {
      additional_info: {
        ai_item_linkage_type: direction
      },
      component: componentNames.ITEM_LINKAGE
    });
  }, [activeTab, track]);

  const accessibleData = useMemo(() => {
    return !archived && hasAccess(viewerMaxRole);
  }, [archived, viewerMaxRole]);

  const buildItemLink = useCallback((itemId, objectId) => {
    const base = generatePath(routes.ITEMS, { sampleId: itemId });
    const hash = generateHash('itemObject', objectId);

    return `${base}#${hash}`;
  }, []);

  const titleLink = useMemo(() =>
    accessibleData ?
      generatePath(routes.ITEMS, { sampleId: id })
      : null,
  [accessibleData, id]);

  const protocolLink = useCallback((protocol) => {
    if (!protocol.archived && activeTab === TABS.FROM){
      return '#';
    }

    if (protocol.archived)
      return null;

    return buildItemLink(id, protocol.id);
  },
  [buildItemLink, id, activeTab]);

  const handleProtocolClick = useCallback((protocol) => {
    onProtocolClick(protocol.id);
  }, [onProtocolClick]);

  const tooltipTitle = accessibleData ? title : 'Inaccessible item';

  return (
    <TableRow
      sx={{
        height: '32px'
      }}
    >
      <TableCell
        align="left"
        sx={{
          padding: 0
        }}
      >
        <CellStyled>
          {!archived && titleLink ?
            <OpenInNewIcon
              color="primary"
              sx={{
                height: '14px',
                width: '14px'
              }}
            /> :
            null
          }

          <TextWithLink
            link={titleLink}
            linkProps={{
              onClick: handleTitleClick,
              target: '_blank'
            }}
          >
            <Tooltip
              title={tooltipTitle}
              placement="top"
            >
              <Typography
                noWrap
                color={accessibleData ? 'inherit' : '#9e9e9e'}
                sx={{
                  textOverflow: 'ellipsis',
                  maxWidth: '132px',
                  textDecoration: accessibleData ? 'inherit' : 'underline'
                }}
              >
                {title}
              </Typography>
            </Tooltip>
          </TextWithLink>

          <Typography
            noWrap
            sx={{
              textOverflow: 'initial',
              overflow: 'initial'
            }}
          >
            ({code})
          </Typography>
        </CellStyled>
      </TableCell>

      <TableCell
        align="left"
        sx={{
          padding: '0 16px'
        }}
      >
        <CellStyled
          isprotocol="true"
        >
          {linkingProtocols?.map(protocol => (
            <React.Fragment key={protocol.id}>
              {activeTab === TABS.FROM ?
                <LinkedProtocolFromItem
                  onClick={handleProtocolClick}
                  protocol={protocol}
                  link={protocolLink(protocol)}
                  title={protocol.title}
                />
                :
                <LinkedProtocolToItem
                  protocol={protocol}
                  title={protocol.title}
                  link={protocolLink(protocol)}
                />
              }
            </React.Fragment>
          )
          )}
        </CellStyled>
      </TableCell>

      <TableCell
        align="right"
        sx={{ padding: 0 }}
      >
        <Avatar
          name={data.owner?.name}
          src={get(data.owner, 'picture.downloadURL')}
          size={24}
          color={data.owner?.avatarColor}
        />
      </TableCell>
    </TableRow>
  );
};

LinkedItem.propTypes = {
  data: PropTypes.object,
  onProtocolClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired
};

export default LinkedItem;
