import React, { useCallback } from 'react';
import PT from 'prop-types';
import isNumber from 'lodash/isNumber';
import { useMutation } from '@apollo/client';

import EditQuantityValue from './EditQuantityValue';
import ViewQuantityValue from './ViewQuantityValue';
import EditableValueContainer from '../../EditableValueContainer';
import { UPDATE_FEATURE_VALUE } from '../gql';

const QuantityValue = ({ data, itemId, tableParameterId, ...props }) => {
  const { quantity } = data;

  const [updateFeatureValue] = useMutation(UPDATE_FEATURE_VALUE);

  const handleUpdate = useCallback(async (value) => {
    const validValue = value ? parseFloat(value) : null;
    const valuesInput = { quantity: validValue };
    const variablesInput = {
      tableParameterId,
      tableItemId: itemId,
      ...valuesInput
    };

    try {
      await updateFeatureValue({
        variables: { input: variablesInput },
        optimisticResponse: {
          updateTableValue: {
            __typename: 'TableValue',
            boolean: null,
            text: null,
            linkedTableItem: null,
            ...variablesInput,
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, [tableParameterId, itemId, updateFeatureValue]);

  return (
    <EditableValueContainer
      {...props}

      defaultValue={isNumber(quantity) ? quantity : ''}
      onUpdate={handleUpdate}
      viewComponent={ViewQuantityValue}
      editComponent={EditQuantityValue}
    />
  );
};

QuantityValue.propTypes = {
  itemId: PT.string.isRequired,
  tableParameterId: PT.string.isRequired,
  data: PT.shape({
    quantity: PT.number
  })
};

export default QuantityValue;
