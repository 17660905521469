import toString from 'lodash/toString';

import { PARAMETER_VALUE_TYPES } from '../../constants';

const getParameterInitValueMap = data => {
  switch (data.type) {
    case PARAMETER_VALUE_TYPES.QUANTITY: {
      return {
        min: toString(data?.quantityMin),
        max: toString(data?.quantityMax)
      };
    }
    case PARAMETER_VALUE_TYPES.BOOLEAN: {
      return data?.boolean?.map(v => `${v}`) || [];
    }
    case PARAMETER_VALUE_TYPES.LINK: {
      return data?.linkItemIds ?? [];
    }
    case PARAMETER_VALUE_TYPES.TEXT: {
      return data.textValues ?? [];
    }
    default:
      return data.textValues ?? [];
  }
};

export default getParameterInitValueMap;
