import React, { Suspense } from 'react';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate
} from 'react-router-dom';

// Service components that make access validation
import Root from './Root';
import Authenticated from './Authenticated';
import NotAuthenticated from './NotAuthenticated';
import EmailVerified from './EmailVerified';
import EmailNotVerified from './EmailNotVerified';
import NoViewerRecordExist from './NoViewerRecordExist';
import ViewerRecordExist from './ViewerRecordExist';
import RegularUser from './RegularUser';

// Screens and pages
import Main from '../Main';
import NotFound from '../../NotFound';
import EmailsActions from '../../EmailsActions';
import Signup from '../../Signup';
import Login from '../../Login';
import ForgotPassword from '../../ForgotPassword';
import EmailVerification from '../../EmailVerification';
import RegistrationDetails from '../../RegistrationDetails';
import Application from '../../Application';
import UserProfileSettings from '../../UserProfileSettings';
import NotificationsSettings from '../../NotificationsSettings';
import Organization from '../../Organization';
import ArchiveSamples from '../../ArchiveSamples';
import ItemView from '../../ItemView';
import AllItems from '../../AllItems';
import Home from '../../Home';
import TableSettings from '../../TableSettings';
import FolderSettings from '../../FolderSettings';
import NoContent from '../../NoContent';
import Tables from '../../Tables';

import LoadingScreen from '../../../components/LoadingScreen';

import { routes } from '../../../services/session/constants';
import loaders from './loaders';

const AppRouting = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={<Root />}
        errorElement={
          <Main isFullScreen>
            <NotFound />
          </Main>
        }
      >
        <Route element={<NotAuthenticated />}>
          <Route
            path={routes.SIGN_UP}
            element={<Signup />}
          />

          <Route
            path={routes.SIGN_IN}
            element={<Login />}
          />

          <Route
            path={routes.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
        </Route>

        <Route element={<Authenticated />}>
          <Route element={<EmailNotVerified />}>
            <Route
              path={routes.EMAIL_VERIFICATION}
              element={<EmailVerification />}
            />
          </Route>

          <Route element={<EmailVerified />}>
            <Route element={<NoViewerRecordExist />}>
              <Route
                path={routes.REGISTRATION_DETAILS}
                element={<RegistrationDetails />}
              />
            </Route>

            <Route element={<ViewerRecordExist />}>

              <Route element={<RegularUser />}>
                {/* Only regular user pages */}

                <Route
                  path={routes.ORGANIZATION}
                  element={<Organization />}
                />

                <Route
                  path={routes.ALL_ITEMS}
                  loader={loaders.initialSavedView}
                  element={<AllItems />}
                />

                <Route
                  path={routes.ITEMS_ARCHIVED}
                  element={<ArchiveSamples />}
                />

                <Route
                  path={routes.ITEMS}
                  element={<ItemView />}
                  loader={loaders.itemTitle}
                />

                <Route
                  path={routes.TABLE_SETTINGS}
                  element={
                    <Suspense fallback={<LoadingScreen />}>
                      <TableSettings />
                    </Suspense>
                  }
                  errorElement={
                    <NoContent
                      contentType="table"
                      defaultPageName="Home page"
                      defaultPageURL={routes.HOME_PAGE}
                    />
                  }
                />

                <Route
                  path={routes.TABLES}
                  element={<Tables />}
                />

                <Route
                  path={routes.FOLDER_SETTINGS}
                  element={
                    <Suspense fallback={<LoadingScreen />}>
                      <FolderSettings />
                    </Suspense>
                  }
                  errorElement={
                    <NoContent
                      contentType="folder"
                      defaultPageName="Home page"
                      defaultPageURL={routes.HOME_PAGE}
                    />
                  }
                />
              </Route>

              {/* All existing users routes */}

              <Route
                path={routes.HOME_PAGE}
                element={<Home />}
              />

              <Route
                loader={loaders.app}
                path={routes.APP}
                element={<Application />}
              />

              <Route
                path={routes.PROFILE_SETTINGS}
                element={<UserProfileSettings />}
              />

              <Route
                path={routes.NOTIFICATION_SETTINGS}
                element={<NotificationsSettings />}
              />
            </Route>
          </Route>
        </Route>

        <Route
          path={routes.EMAILS}
          element={<EmailsActions />}
        />

        <Route
          path={routes.ROOT_PAGE}
          element={<Navigate to={routes.HOME_PAGE} replace />}
        />
      </Route>
    )
  );

  return (
    <RouterProvider router={router} />
  );
};

export default AppRouting;
