import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import CopyClipboard from '../../CopyClipboard';
import CopyItemUrlIcon from '../../Icons/CopyItemURLIcon';
import { MENU_ITEM_MIN_HEIGHT, MENU_ITEM_ICON_MIN_WIDTH } from './constants';

const useStyles = makeStyles({
  menuItem: {
    minHeight: MENU_ITEM_MIN_HEIGHT
  },
  menuItemIcon: {
    minWidth: MENU_ITEM_ICON_MIN_WIDTH
  }
});

const CopyItemUrlAction = ({ onCopied, text }) => {
  const [isCopied, setCopied] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if(!isCopied) return;

    const timerId = setTimeout(setCopied, 500, false);

    return () => {
      clearTimeout(timerId);
    };
  }, [isCopied]);

  const handleCopy = () => {
    setCopied(true);
    onCopied();
  };

  return (
    <CopyClipboard
      tooltip
      tooltipProps={{
        open: isCopied,
        title: 'Copied',
        placement: 'top',
      }}
      onCopy={handleCopy}
      text={text}
    >
      <MenuItem className={classes.menuItem}>
        <ListItemIcon className={classes.menuItemIcon}>
          <CopyItemUrlIcon />
        </ListItemIcon>

        <ListItemText>
          Copy Item URL
        </ListItemText>
      </MenuItem>
    </CopyClipboard>
  );
};

CopyItemUrlAction.propTypes = {
  onCopied: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export default CopyItemUrlAction;
