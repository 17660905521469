import { createRequestTypes, action/*, createHandlers*/ } from '../../actions';
import * as constants from './constants';

const {
  REQUEST,
  SUCCESS,
  FAILURE,
  CURRENT_USER_ACCOUNTS_LOAD,
} = constants;

// Types
export const currentUserAccountsLoadTypes = createRequestTypes(CURRENT_USER_ACCOUNTS_LOAD);

// Handlers
export const currentUserAccountsLoadHandlers = {
  request: idsList => action(currentUserAccountsLoadTypes[REQUEST], { idsList }),
  success: response => action(currentUserAccountsLoadTypes[SUCCESS], { response }),
  failure: error => action(currentUserAccountsLoadTypes[FAILURE], { error })
};

// Components' actions
export const currentUserAccountsLoad = currentUserAccountsLoadHandlers.request;
export const setRelatedAccounts = data => action(constants.SET_RELATED_ACCOUNTS, { data });
