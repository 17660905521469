import pickBy from 'lodash/pickBy';

export const getBaseUrl = url => {
  const paramsStartIndex = url.indexOf('?');

  if ( paramsStartIndex > -1 ){
    url = url.substr(0, paramsStartIndex);
  }

  return url;
};

export const checkedIds = (itemsById) => {
  return pickBy(itemsById, (value,) => {
    return value.checked || Object.values(value.objects)
      .some(({ checked }) => checked);
  });
};
export const fullyCheckedIds = (itemsById) => {
  return Object.keys(itemsById).filter(id => itemsById[id].checked);
};

export function itemsResetAndDeletion({
  updateQuery,
  itemIdsToDelete,
  onItemsListReset,
}) {
  if (itemIdsToDelete.length) {
    updateQuery(cache => {
      const filtered = cache.items.searchInfo.totalNumberOfFilteredItems;
      const total = cache.items.searchInfo.totalNumberOfItems;
      const cacheItemsToDeleteCount = cache.items.edges.filter(i =>
        itemIdsToDelete.includes(i.node.id)
      ).length;

      return {
        ...cache,
        items: {
          ...cache.items,
          edges: cache.items.edges.filter(i =>
            !itemIdsToDelete.includes(i.node.id)
          ),
          searchInfo: {
            totalNumberOfFilteredItems: filtered - cacheItemsToDeleteCount,
            totalNumberOfItems: total - cacheItemsToDeleteCount,
          }
        }
      };
    });
  }

  onItemsListReset(itemIdsToDelete);
}
