import { useCallback, useRef, useState } from 'react';
import { useQuery, NetworkStatus } from '@apollo/client';
import defaults from 'lodash/defaults';

const SORT_COLUMN_TITLE = 'TITLE';
const SORT_COLUMN_DATE_CREATED = 'DATE_CREATED';
const SORT_COLUMN_DATE_UPDATED = 'DATE_UPDATED';
const SORT_ORDER_ASC = 'ASC';
const SORT_ORDER_DESC = 'DESC';

const SORT_COLUMNS_MAP = new Map([
  ['title', SORT_COLUMN_TITLE],
  ['dateUpdated', SORT_COLUMN_DATE_UPDATED],
  ['dateCreated', SORT_COLUMN_DATE_CREATED]
]);

const INIT_LOADING_ITEMS_COUNT = 25;
const MORE_LOADING_ITEMS_COUNT = 25;

const DEFAULT_FILTER_PARAM = {
  param: undefined,
  value: undefined
};

const DEFAULT_SORT_SETTINGS_PARAM = {
  sortBy: undefined,
  sortAsc: undefined
};

const DEFAULT_OPTIONS = {
  fetchPolicy: 'network-only',
  withTotal: false,
};

function useQueryLazyLoading(
  query,
  filter = DEFAULT_FILTER_PARAM,
  sortSettings = DEFAULT_SORT_SETTINGS_PARAM,
  options = DEFAULT_OPTIONS
) {
  const {
    fetchPolicy,
    withTotal,
  } = defaults(options, DEFAULT_OPTIONS);

  const variablesQueryRef = useRef({
    filters: [filter],
    sort: {
      param: SORT_COLUMNS_MAP.get(sortSettings.sortBy),
      order: sortSettings.sortAsc ? SORT_ORDER_ASC : SORT_ORDER_DESC
    },
  });

  const initialQueryVariables = useRef({
    withTotal,
    query: variablesQueryRef.current,
    first: INIT_LOADING_ITEMS_COUNT,
    withFilter: false
  });

  const [sorting, setSorting] = useState(false);
  const [searching, setSearching] = useState(false);

  const {
    data,
    updateQuery,
    fetchMore,
    refetch,
    loading,
    error,
    networkStatus
  } = useQuery(query, {
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
    variables: initialQueryVariables.current,
  });

  const search = useCallback(async (text, options = {}) => {
    setSearching(true);

    variablesQueryRef.current = {
      ...variablesQueryRef.current,
      search: {
        text,
        onlyTitleAndCode: options.onlyTitleAndCode
      }
    };

    await refetch({
      withTotal: false,
      withFilter: Boolean(options.withFilter),
      first: INIT_LOADING_ITEMS_COUNT,
      query: variablesQueryRef.current,
    });

    setSearching(false);
  }, [refetch]);

  const loadMore = useCallback(() => {
    return fetchMore({
      variables: {
        after: data?.items?.pageInfo?.endCursor,
        query: variablesQueryRef.current,
        first: MORE_LOADING_ITEMS_COUNT,
        withTotal: false,
        withFilter: false
      }
    });
  }, [fetchMore, data?.items?.pageInfo?.endCursor]);

  const sort = useCallback(async (order, param) => {
    setSorting(true);

    variablesQueryRef.current = {
      ...variablesQueryRef.current,
      sort: {
        param: SORT_COLUMNS_MAP.get(param),
        order: order ? SORT_ORDER_ASC : SORT_ORDER_DESC
      }
    };

    await refetch({
      first: INIT_LOADING_ITEMS_COUNT,
      query: variablesQueryRef.current,
      withTotal: false,
      withFilter: false
    });

    setSorting(false);
  }, [refetch]);

  return {
    data,
    updateQuery,
    sort,
    loading,
    sorting,
    search,
    networkStatus,
    searching,
    error: error && 'Items loading failed.',
    fetchMore: loadMore,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
  };
}

export default useQueryLazyLoading;
