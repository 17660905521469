import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

import {
  openAppSnackbarNotification
} from '../../../services/snackbar-notifications/actions';

import ActionsPanelStyled, { Button, Counter } from './styles';
import pickBy from 'lodash/pickBy';

const mapDispatchToProps = {
  openAppSnackbarNotification,
};

const ActionsPanel = ({
  onDelete,
  onRestore,
  onSelectedItemsChipClick,
  totalNumberOfItems,
  totalNumberOfFilteredItems,
  isSearchTextExist,
  hideCounter,
  isItemsRestoring,
  isDeleting,
  itemsById
}) => {

  const checkedSamples = useMemo(() => {
    return pickBy(itemsById, (value,) => {
      return value.checked;
    });
  }, [itemsById]);

  const nothingSelected = useMemo(
    () => !Object.keys(checkedSamples).length,
    [checkedSamples]
  );

  const isArchiveButtonDisabled = useMemo(() => ([
    isItemsRestoring,
    isDeleting
  ].some(Boolean)),
  [isDeleting, isItemsRestoring]
  );

  const isDeleteButtonDisabled = useMemo(() => ([
    isDeleting,
  ].some(Boolean)),
  [isDeleting]
  );

  const checkedSamplesLength = Object.keys(checkedSamples).length;

  return (
    <ActionsPanelStyled>
      <div className="action">
        <Tooltip
          title="Restore From Archive"
          placement="left-start"
        >
          <div>
            <Button
              disabled={isArchiveButtonDisabled || nothingSelected}
              variant="contained"
              color="secondary"
              onClick={onRestore}
            >
              restore
            </Button>
          </div>
        </Tooltip>
      </div>

      <Tooltip
        title={isDeleteButtonDisabled ?
          'Some items are not archived' :
          'Delete From Archive'}
        placement="left-start"
      >
        <div className="action">
          <Button
            disabled={isDeleteButtonDisabled || nothingSelected}
            variant="contained"
            color="secondary"
            onClick={onDelete}
          >
            Delete
          </Button>
        </div>
      </Tooltip>

      {checkedSamplesLength
        ? (
          <Chip
            className="selected-items-info"
            color="primary"
            label={`${checkedSamplesLength} selected item(s)`}
            onClick={onSelectedItemsChipClick}
          />
        )
        : null
      }

      {!hideCounter &&
        <Counter>
          {
            !isSearchTextExist ?
              `Total items: ${totalNumberOfItems || 0}` :
              `Total items found:
            ${totalNumberOfFilteredItems || 0}/${totalNumberOfItems || 0}`
          }
        </Counter>
      }
    </ActionsPanelStyled>
  );
};

ActionsPanel.propTypes = {
  openAppSnackbarNotification: PropTypes.func.isRequired,
  isItemsRestoring: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func.isRequired,
  onSelectedItemsChipClick: PropTypes.func.isRequired,
  totalNumberOfItems: PropTypes.number,
  totalNumberOfFilteredItems: PropTypes.number,
  isSearchTextExist: PropTypes.bool.isRequired,
  hideCounter: PropTypes.bool.isRequired,
  itemsById: PropTypes.object.isRequired,
};

export default connect(null, mapDispatchToProps)(ActionsPanel);
