import React, { useCallback } from 'react';
import PT from 'prop-types';
import { gql, useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ProtocolForm from '../ProtocolForm';

const CREATE_TABLE_PROTOCOL = gql`
  mutation CreateTableProtocol($tableId: ID!, $data: TableProtocolInput!) {
    createTableProtocol(tableId: $tableId, data: $data) {
      id
    }
  }
`;

const CreateProtocolForm = ({ tableId, protocols, onClose, onSuccess }) => {
  const [createTableProtocol, { loading, error }] = useMutation(CREATE_TABLE_PROTOCOL);

  const handleSubmit = useCallback(async (data) => {
    try {
      await createTableProtocol({
        variables: {
          tableId,
          data
        }
      });

      onSuccess();
    } catch(e) {
      console.error(e);
    }
  }, [tableId, createTableProtocol, onSuccess]);

  return (
    <Box
      border="1px solid #eee"
      borderRadius="4px"
      boxShadow="0px 1px 1px 0px #00000014, 0px 1px 3px 0px #00000014"
      sx={{
        background: '#f6f6f6'
      }}
    >
      <Box
        height="44px"
        display="flex"
        alignItems="center"
        padding="0 26px 0 32px"
        borderBottom="1px solid #eee"
      >
        <Typography
          variant="body2"
          fontWeight="500"
        >
          New Protocol
        </Typography>
      </Box>

      <ProtocolForm
        loading={loading}
        error={error}
        protocols={protocols}
        onSubmit={handleSubmit}
        onCancel={onClose}
      />
    </Box>
  );
};

CreateProtocolForm.propTypes = {
  tableId: PT.string.isRequired,
  protocols: PT.arrayOf(PT.object),
  onClose: PT.func,
  onSuccess: PT.func
};

export default CreateProtocolForm;
