const refetchWithNewColumns = async ({
  columnToDelete,
  items,
  table,
  queryVariables,
  refetchQuery,
}) => {
  let tableHeaderFeatureIDs = [];

  const iterableItems = items ?? table.allColumns.reduce((curr, item) => {
    if (item.id === 'name')
      return curr;

    return [
      ...curr,
      {
        id: item.id,
        columns: item.columns.map(column => ({
          id: column.id,
          visible: columnToDelete === column.id ? false : column.isVisible(),
        })),
        visible: item.isVisible(),
      }
    ];
  }, []);

  for (const { id: parentId, columns } of iterableItems) {
    if (parentId === 'Item info') {
      for (const { id, visible } of columns) {
        const column = table.getColumn(id);

        if (column?.isVisible() !== visible) {
          column.toggleVisibility(visible);
        }
      }
    } else {
      const visibleColumns = columns
        .filter(({ visible }) => visible)
        .map(({ id }) => id);

      tableHeaderFeatureIDs = tableHeaderFeatureIDs.concat(visibleColumns);
    }
  }

  if (tableHeaderFeatureIDs.length) {
    queryVariables.current.input.tableHeaderFeatureIDs = tableHeaderFeatureIDs;
  } else {
    delete queryVariables.current.input.tableHeaderFeatureIDs;
  }

  await refetchQuery(queryVariables.current);
};

export default refetchWithNewColumns;
