import styled from 'styled-components';
import { PageContent as PageContentLayout } from '../../components/Page';

const CHECKBOX_COLUMN_WIDTH = 30;
const OWNER_COLUMN_WIDTH = 65;
const OPTION_COLUMN_WIDTH = 40;
const ALL_FIXED_COLUMNS_WIDTH_SUM =
  CHECKBOX_COLUMN_WIDTH +
  OWNER_COLUMN_WIDTH +
  OPTION_COLUMN_WIDTH;

export const PageContent = styled(PageContentLayout)
  .attrs({ className: 'archive-samples-content' })`
    .table-container {
      .table-cell {
        &.sample-name {
          width: calc(100% - ${ALL_FIXED_COLUMNS_WIDTH_SUM}px);
        }
      }
    }
  `;
