import _unzip from 'lodash/unzip';

const mapSurfaceData = (input = {}) => {
  const titles = ['x', 'y', 'z'];
  let data = null;

  if(!Array.isArray(input) && Array.isArray(input.x)) {
    data = [titles].concat(_unzip([input.x, input.y, input.z]));
  } else if(Array.isArray(input) && input.length === 3) {
    data = [titles].concat(_unzip(input.slice()));
  } else if(Array.isArray(input)) {
    data = input.slice();
  }

  if(!data)
    return null;

  return [{ id: 0, data }];
};

export default mapSurfaceData;
