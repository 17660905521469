export * from '../../constants';

export const processingSubjects = Object.freeze({
  LAB_MEASUREMENTS: 'labMeasurements',
  PHYSICAL_MEASUREMENTS: 'physicalMeasurements',
  INSTRUMENTS: 'instruments',
  PARSERS: 'parsers'
});

export const processingSingleSubjects = Object.freeze({
  INSTRUMENT: 'instrument',
  PHYSICAL_MEASUREMENT: 'physicalMeasurement',
  PARSER: 'parser',
  PRESENTATION: 'presentation',
  CODE: 'code'
});

export const parsingSteps = Object.freeze({
  PARSER: 'parser',
  INSTRUMENT: 'instrument',
  PHYSICAL_MEASUREMENT: 'physicalMeasurement'
});

export const FILES_LIST_LOAD = 'FILES_LIST_LOAD';
