import styled from 'styled-components';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const InputStyled = styled(OutlinedInput)`
  height: 30px;
  width: 100%;
  border-radius: 4px;

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    display: none;
  };
`;
