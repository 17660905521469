import React, { useMemo } from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { GroupColumnHeader, GroupHeader } from '../../components/ItemsTable';
import { PrimaryHeader, SecondaryHeader } from '../../components/ItemsTable/GroupColumnHeader';
import { ParameterValue } from '../../components/ItemsTable/cells';

import { COL_MAX_WIDTH_PX, PARAM_VALUE_COL_MIN_WIDTH } from '../../components/ItemsTable/constants';

const useColumns = ({ featuresData, defaultColumns, sortable }) => {

  const columns = useMemo(() => {
    const features = featuresData ?? [];
    const featuresByProtocol = {};

    for(const feature of features) {
      const {
        protocolTitle,
        featureId,
        title,
        unitName,
        titleLinkId,
        titleLinkIsAccessible
      } = feature;

      if(!featuresByProtocol[protocolTitle]) {
        featuresByProtocol[protocolTitle] = {
          title: protocolTitle,
          columns: []
        };
      }

      featuresByProtocol[protocolTitle].columns.push({
        id: featureId,
        title,
        unitName,
        titleLinkId,
        titleLinkIsAccessible
      });
    }

    let parameterIndex = 0;
    const protocolColumns = Object
      .values(featuresByProtocol)
      .map(({ title, columns }) => ({
        id: title,
        header: ({ header, table }) => {
          let { colSpan, rowSpan, column } = header;

          if(column.isCollapsed()) {
            colSpan = 1;
            rowSpan = table.rows.length + 2;
          }

          const maxWidth = column.getLeafColumns().length * COL_MAX_WIDTH_PX;

          return (
            <GroupHeader
              colSpan={colSpan}
              rowSpan={rowSpan}
              collapsed={column.isCollapsed()}
              onToggleCollapse={column.toggleCollapse}
              maxWidth={maxWidth}
            >
              <Tooltip title={title}>
                <Typography variant="body2" fontWeight="bold" noWrap>
                  {title}
                </Typography>
              </Tooltip>
            </GroupHeader>
          );
        },
        columns: columns.map(({ id, title, unitName, titleLinkId, titleLinkIsAccessible }) => {
          const i = parameterIndex++;

          return {
            id: id,
            header: ({ table, header }) => {
              const { itemsSorting, onColumnRemove } = table.customState;
              const { state, onSort } = itemsSorting;
              const sorted = state?.featureId === id;
              const order = sorted ? state?.order : null;

              const maxWidth = header.column.getLeafColumns().length * COL_MAX_WIDTH_PX;

              const handleRemove = () => {
                onColumnRemove({ id, table });
              };

              return (
                <GroupColumnHeader
                  sortable
                  id={id}
                  sorted={sorted}
                  sortParam="FEATURE_ID"
                  sortOrder={order}
                  onSort={onSort}
                  maxWidth={maxWidth}
                  minWidth={PARAM_VALUE_COL_MIN_WIDTH}
                >
                  <PrimaryHeader
                    id={id}
                    sortable={sortable}
                    sortParam="FEATURE_ID"
                    order={order}
                    onSort={onSort}
                    onRemove={handleRemove}
                    linkId={titleLinkId}
                    noAccess={!titleLinkIsAccessible}
                    onLinkClick={table.customState.handleToggleSidebar}
                  >
                    <Tooltip
                      title={(titleLinkId && !titleLinkIsAccessible) ? 'Inaccessible item' : title}
                      placement="top"
                    >
                      <Typography variant="body2" noWrap>
                        {title}
                      </Typography>
                    </Tooltip>
                  </PrimaryHeader>

                  <SecondaryHeader>
                    {unitName ?? '-'}
                  </SecondaryHeader>
                </GroupColumnHeader>
              );
            },
            accessorFn: ({ values }) =>  values[i],
            cell: ParameterValue
          };
        })
      }));

    return [
      ...defaultColumns,
      ...protocolColumns
    ];
  }, [featuresData, defaultColumns, sortable]);

  return columns;
};

export default useColumns;
