import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';

import Box from '@mui/material/Box';

import Search from '../Search';
import InitialList from './InitialList';
import CreateTableButton from './CreateTableButton';

const sortChildren = (data) => [...data].sort((a, b) => {
  if(a.isFolder && !b.isFolder){
    return -1;
  } else if(!a.isFolder && b.isFolder){
    return 1;
  } else {
    return a.title.localeCompare(b.title);
  }
});

const sortTeams = (data) => data.reduce((init, table) => {
  if(table.children){
    return sortChildren([
      ...init,
      {
        ...table,
        children: sortTeams(sortChildren(table.children))
      }
    ]);
  }

  return sortChildren([...init, table]);
}, []);

const TablesFilter = ({ data = [], onFilterUpdate, value = [], onFilterDelete }) => {
  const [filter, setFilter] = useState('');

  const mapListToTree = useMemo(() => {
    const items = uniqBy(cloneDeep(data), 'id');

    const idMapping = items.reduce((acc, el, i) => {
      acc[el.id] = i;
      return acc;
    }, {});

    let rootItems = [];
    items.forEach(el => {
      const parentEl = items[idMapping[el.parentFolderId]];
      if (el.parentFolderId === null || !parentEl) {
        rootItems.push(el);
        return;
      }

      parentEl.children = [...(parentEl.children || []), el];
    });

    return sortTeams(rootItems);
  }, [data]);

  const filterList = useCallback((list) => {
    return list.reduce((init, team) => {
      if (team.children){
        const filteredChildren = filterList(team.children);

        if(team.title?.toLowerCase().includes(filter.toLowerCase())){
          return [
            ...init,
            team
          ];
        } else if (filteredChildren.length){
          return [
            ...init,
            {
              ...team,
              children: filteredChildren
            }
          ];
        } else return init;
      }

      return team.title?.toLowerCase().includes(filter.toLowerCase()) ? [...init, team] : init;
    }, []);
  }, [filter]);

  const filteredList = useMemo(() => filterList(mapListToTree),
    [filterList, mapListToTree]
  );

  const handleCheck = useCallback((ids, checked) => {
    let newList;

    if (checked){
      newList = [...value, ...ids];
    } else {
      newList = value.filter(id => !ids.includes(id));
    }

    onFilterUpdate('tableIds', newList.length ? newList : null);
  }, [onFilterUpdate, value]);

  return (
    <Box sx={{ margin: '0 10px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '4px',
          alignItems: 'center',
        }}
      >
        <Search
          onChange={setFilter}
          value={filter}
        />

        <CreateTableButton />
      </Box>

      <InitialList
        onFilterUpdate={handleCheck}
        data={filteredList}
        value={value}
        isFiltered={Boolean(filter)}
        onFilterDelete={onFilterDelete}
      />
    </Box>
  );
};

TablesFilter.propTypes = {
  data: PropTypes.array,
  onFilterUpdate: PropTypes.func.isRequired,
  value: PropTypes.array,
  onFilterDelete: PropTypes.func,
};

export default TablesFilter;
