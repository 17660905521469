export * from '../../constants';

export const MEASUREMENTS_PARSE = 'MEASUREMENTS_PARSE';
export const MEASUREMENT_PROCESSED_DATA_ADD = 'MEASUREMENT_PROCESSED_DATA_ADD';
export const MEASUREMENT_PARSED_DATA_BY_ID_REMOVE = 'MEASUREMENT_PARSED_DATA_BY_ID_REMOVE';
export const MEASUREMENT_PROCESSED_DATA_LOAD_BY_ID = 'MEASUREMENT_PROCESSED_DATA_LOAD_BY_ID';
export const MEASUREMENT_PROCESSED_DATA_RESET_BY_ID = 'MEASUREMENT_PROCESSED_DATA_RESET_BY_ID';
export const MEASUREMENT_PARSE_BY_ID = 'MEASUREMENT_PARSE_BY_ID';

export const INSTRUMENT = 'instrument';
export const PHYSICAL_MEASUREMENT = 'physicalMeasurement';
export const PARSER = 'parser';
export const PRESENTATION = 'presentation';

export const PARSED_DATA_RESET_BY_ID = 'PARSED_DATA_RESET_BY_ID';
export const PENDING_FILES_FILTER_ARCHIVED = 'ARCHIVED';
