import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import ListComponent  from '@mui/material/List';

import { OPENED_NAV_WIDTH, CLOSED_NAV_WIDTH, COLOR_PRIMARY } from '../../../styles';

const transitionDuration = 0.4;

export const List = styled(ListComponent)`
  ${injectRoot}
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  ${injectCss}

  z-index: 12;
  background-color: white;
  overflow-x: hidden;
  -ms-overflow-style: none;  // IE 10+
  overflow: -moz-scrollbars-none;

  .user-info {
    .background {
      height: 161px;
    }
  }
`;

function injectRoot({ root }) {
  return root && css`
    border-top: 1px solid #E0E0E0 !important;
    border-right: 1px solid #E0E0E0 !important;
    height: 100%;
  `;
}

function injectCss({ mobile, open }) {
  if (mobile) {
    return css`
      height: 100%;
      position: absolute;
      margin-left: ${open ? 0 : -OPENED_NAV_WIDTH}px;
      transition: margin-left ${transitionDuration}s;
      padding-bottom: 60px;
      box-sizing: border-box;
      width: ${OPENED_NAV_WIDTH}px;
    `;
  }

  return css`
    flex-basis: ${open ? OPENED_NAV_WIDTH : CLOSED_NAV_WIDTH}px;
    transition: flex-basis ${transitionDuration}s;
    padding-bottom: 60px;
    box-sizing: border-box;
  `;
}

export const OrganizationNavStyled = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  text-decoration: none;
  color: #000;
  position: relative;
  
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  
  &.active {
    color: ${ ({ color }) => color || COLOR_PRIMARY };
    text-decoration: underline;
  }
  
  .avatar {
    margin-right: 16px;
  }
  
  .sb-avatar > div {
    border-radius: 5px;
  }
`;
