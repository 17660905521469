import React, { useState, useCallback, useEffect } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ColumnOptions from './ColumnOptions';

const isMatchingSearch = ({ title }, search) => title.toLowerCase().includes(search.toLowerCase());

const label = {
  inputProps: {
    'aria-label': 'Select option'
  }
};

const ColumnOption = ({
  className,
  id,
  title,
  visible,
  columns,
  search,
  onChange,
  children,
  notActive
}) => {
  const [expanded, setExpanded] = useState(false);

  const matchingSearchSelf = isMatchingSearch({ title }, search);
  const matchingSearchChildren = columns?.some(column => isMatchingSearch(column, search)) ?? false;
  const matchingSearch = matchingSearchSelf || matchingSearchChildren;

  useEffect(() => {
    setExpanded(!!search);
  }, [search]);

  const handleToggleExpand = useCallback(() => {
    setExpanded(state => !state);
  }, []);

  const handleChange = useCallback((ev) => {
    if(columns) {
      let matchingColumns = [];

      if(matchingSearchSelf)
        matchingColumns = columns
          .filter(({ visible, notActive }) => visible && notActive || !notActive);
      else
        matchingColumns = columns
          .filter(column => isMatchingSearch(column, search) && !column.notActive);

      const checked = !matchingColumns.every(({ visible }) => visible);
      const matchingColumnIds = matchingColumns.map(({ id }) => id);

      onChange(matchingColumnIds, checked);
    } else {
      onChange([id], ev.target.checked);
    }
  }, [id, columns, search, onChange, matchingSearchSelf]);

  if(!matchingSearch)
    return null;

  return (
    <li className={className}>
      {columns?.length ?
        <IconButton
          aria-label={expanded ? 'Collapse' : 'Expand'}
          disableRipple
          size="small"
          sx={{
            '&.MuiButtonBase-root': {
              p: 0,
              mr: '4px',
              width: '16px',
              height: '16px'
            }
          }}
          onClick={handleToggleExpand}
        >
          {expanded ?
            <KeyboardArrowDownIcon fontSize="inherit" /> :
            <KeyboardArrowRightIcon fontSize="inherit" />
          }
        </IconButton> :
        null
      }

      <Checkbox
        id={id}
        {...label}
        disableRipple
        sx={{
          '&.MuiCheckbox-root': {
            p: 0,
            mr: '4px'
          },
          '& .MuiSvgIcon-root': {
            fontSize: 16
          }
        }}
        checked={visible}
        indeterminate={!visible && columns?.some(({ visible }) => visible)}
        onChange={handleChange}
        disabled={!visible && notActive}
      />

      <Tooltip title={title} placement="top-start">
        <Typography
          variant="caption"
          component="label"
          htmlFor={id}
        >
          {children}
        </Typography>
      </Tooltip>

      {columns?.length ?
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <ColumnOptions>
            {columns.map(({ id, title, visible, notActive }) => (
              <ColumnOption
                key={id}
                className={className}
                id={id}
                title={title}
                visible={visible}
                onChange={onChange}
                search={matchingSearchSelf ? '' : search}
                notActive={notActive}
              >
                {title}
              </ColumnOption>
            ))}
          </ColumnOptions>
        </Collapse> :
        null
      }
    </li>
  );
};

ColumnOption.propTypes = {
  className: PT.string,
  id: PT.string,
  title: PT.string,
  visible: PT.bool,
  columns: PT.arrayOf(PT.shape({
    id: PT.string,
    visible: PT.bool
  })),
  search: PT.string,
  onChange: PT.func,
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
  notActive: PT.bool
};

export default styled(ColumnOption)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
