import styled from 'styled-components';

import Box from '@mui/material/Box';

const Container = styled(Box)`
  margin: 0 24px;
  border: 1px solid var(--Light-Divider, #E1E4E7);
  border-radius: 5px;
  padding: 8px 16px;
  overflow: hidden;

  .iframe-container {
    position: relative;
    min-height: 280px;

    iframe {
      border: none;
      width: 100%;
    }
  }
`;

export default Container;
