import { processingSingleSubjects } from '../common/constants';

const {
  PARSER,
  INSTRUMENT,
  PHYSICAL_MEASUREMENT,
} = processingSingleSubjects;

export * from '../../utils';

export const requiredProcessingSettings = Object.freeze([
  PARSER,
  INSTRUMENT,
  PHYSICAL_MEASUREMENT
]);

export const mapDataToSettingsOptions = rawGraphicsData => ({
  axes: Array.isArray(rawGraphicsData)
    ? (Array.isArray(rawGraphicsData[0])
      ? rawGraphicsData[0].map((name, i) => ({ value: i, name }))
      : [[]]
    )
    : []
});

const getMeasurementSrc = data => data.ftpfilename ? 'ftp' : 'cloud';

export const withSrcAndFilename = (base, record) => ({
  ...base,
  src: getMeasurementSrc(record),
  filename: record.ftpfilename // for pending-files
    || record.location?.fullPath // for pending-files
    || record.sourceFile?.fullPath // for measurements (related pending-files)
});

