import LinkIcon from '@mui/icons-material/Link';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import TagIcon from '@mui/icons-material/Tag';

export const parameterTypes = {
  TEXT: {
    id: 'TEXT',
    label: 'Text',
    icon: TextFieldsIcon
  },
  LINK: {
    id: 'LINK',
    label: 'Link to item',
    icon: LinkIcon
  },
  BOOLEAN: {
    id: 'BOOLEAN',
    label: 'Boolean',
    icon: ToggleOnIcon
  },
  QUANTITY: {
    id: 'QUANTITY',
    label: 'Quantity',
    icon: TagIcon
  }
};
