const searchSplit = (base, searchString) => {
  const re = new RegExp(searchString.split(' ').join('|'), 'ig');
  let ar, prevStartIndex = 0, res = [];

  while((ar = re.exec(base)) !== null) {
    if(prevStartIndex !== ar.index)
      res.push({
        id: Math.random().toString().slice(2),
        text: base.slice(prevStartIndex, ar.index),
        search: false
      });

    res.push({
      id: Math.random().toString().slice(2),
      text: ar[0],
      search: true
    });

    prevStartIndex = re.lastIndex;
  }

  if (prevStartIndex < base.length)
    res.push({
      id: Math.random().toString().slice(2),
      text: base.substring(prevStartIndex),
      search: false
    });

  return res;
};

export default searchSplit;
