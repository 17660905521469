import { ReactComponent as QRCodeIcon } from './qr-code.svg';
import styled from 'styled-components';

export default styled(QRCodeIcon)`
  width: 1em;
  height: 1em;
  font-size: 24px;
  display: inline-block;
  fill: currentColor;
  user-select: none;
  flex-shrink: 0;
`;
