import { gql } from '@apollo/client';

export const GET_OPTIONS = gql`
  query TableItemsFilterOptions($tableIds: [ID!]) {
    tableItemsFilterOptions(tableIds: $tableIds) {
      features {
        id
        title
        titleItemId
        unitName
        valueType
        boolean
        quantityMin
        quantityMax
        textValues
        linkValues {
          id
          code
          title
        }
      }
      title
    }
  }
`;
