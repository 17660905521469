import React, { useRef } from 'react';
import dateFnsFormat from 'date-fns/format';

import { GroupHeader, ItemNameHeader } from '../../components/ItemsTable';
import {
  DateTime,
  ItemDescription,
  ItemLink,
  ItemName,
  ItemOwner,
  ItemCode,
  RawText
} from '../../components/ItemsTable/cells';
import Header from './Header';

import { TABS_VALUES } from '../../scenes/AllItems/constants';
import { sidebarSources } from '../../analytics/constants';

const useDefaultColumns = () => {

  const defaultColumnsRef = useRef([
    {
      id: 'name',
      header: ({ header, table }) => {
        const { rowSpan } = header;
        const {
          selectedItems: {
            state,
            checkAllChange
          },
          itemsSorting: {
            state: sortedState,
            onSort
          }
        } = table.customState;
        const indeterminate = !state.allChecked &&
                    Object.values(state.byId).some(Boolean);
        const order = sortedState?.param === 'TITLE' ?
          sortedState?.order :
          null;

        return (
          <ItemNameHeader
            checked={state.allChecked}
            indeterminate={indeterminate}
            onCheckChange={checkAllChange}
            rowSpan={rowSpan}
            sortParam="TITLE"
            sortOrder={order}
            onSort={onSort}
            sortable={!state.displaySelected}
          />
        );
      },
      accessorKey: ['title'],
      cell: ({ row, table }) => {
        const {
          selectedItems: {
            state,
            checkChange
          },
          batchItemActions: {
            onShare,
            onArchive,
            onDelete,
          },
          handleToggleSidebar,
          searchText
        } = table.customState;

        return (
          <ItemName
            item={row.data}
            checked={state.byId[row.data.id] ?? false}
            onCheckChange={checkChange}
            onShare={onShare}
            onArchive={onArchive}
            onDelete={onDelete}
            onClick={handleToggleSidebar}
            search={searchText}
          />
        );
      }
    },
    {
      id: 'Item info',
      header: ({ header, table }) => {
        let { colSpan, rowSpan, column } = header;

        if(column.isCollapsed()) {
          colSpan = 1;
          rowSpan = table.rows.length + 2;
        }

        return (
          <GroupHeader
            colSpan={colSpan}
            rowSpan={rowSpan}
            collapsed={column.isCollapsed()}
            onToggleCollapse={column.toggleCollapse}
          >
            Item info
          </GroupHeader>
        );
      },
      columns: [
        {
          id: 'DEFAULT_CODE',
          title: 'Code',
          header: props => (
            <Header {...props} text="Code" />
          ),
          accessorKey: ['code'],
          cell: ({ table, getValue }) => {
            const { searchText } = table.customState;

            return (
              <ItemCode
                code={getValue()}
                search={searchText}
              />
            );
          },
          visible: false
        },
        {
          id: 'DEFAULT_OWNER',
          title: 'Owner',
          header: props => (
            <Header {...props} text="Owner" />
          ),
          accessorKey: ['creator'],
          cell: ({ getValue }) => {
            return (
              <ItemOwner owner={getValue()} />
            );
          },
        },
        {
          id: 'DEFAULT_TEAMS',
          title: 'Table',
          header: props => (
            <Header {...props} text="Table" />
          ),
          accessorKey: ['table'],
          cell: ({ getValue }) => {
            const table = getValue().title;

            return (
              <RawText>
                {table}
              </RawText>
            );
          },
        },
        {
          id: 'DEFAULT_MEASUREMENTS',
          title: 'Measurements',
          header: props => (
            <Header {...props} text="Measurements" />
          ),
          accessorKey: ['numberOfTableItemFiles'],
          cell: ({ getValue, row, table }) => {
            const measurements = getValue() ?? null;
            const { handleToggleSidebar } = table.customState;

            return (
              <ItemLink
                onClick={() =>
                  handleToggleSidebar(row.id, TABS_VALUES.MEASUREMENTS, sidebarSources.TABLE_MEASUREMENTS)
                }
                text={measurements}
              />
            );
          },
          visible: false
        },
        {
          id: 'DEFAULT_DATE_CREATED',
          title: 'Date created',
          header: props => (
            <Header
              {...props}

              sortParam="DATE_CREATED"
              text="Date created"
            />
          ),
          accessorKey: ['dateCreated'],
          cell: ({ getValue }) => {
            return (
              <DateTime timestamp={getValue()} />
            );
          },
          visible: false
        },
        {
          id: 'DEFAULT_DATE_UPDATED',
          title: 'Date updated',
          header: props => (
            <Header
              {...props}

              sortParam="DATE_UPDATED"
              text="Date updated"
            />
          ),
          accessorKey: ['dateUpdated'],
          cell: ({ getValue }) => {
            return (
              <DateTime timestamp={getValue()} />
            );
          },
        },
        {
          id: 'DEFAULT_DESCRIPTION',
          title: 'Description',
          header: props => (
            <Header
              {...props}

              text="Description"
            />
          ),
          accessorKey: ['description'],
          cell: ({ getValue, table }) => {

            const { searchText } = table.customState;

            return (
              <ItemDescription
                text={getValue()}
                search={searchText}
              />
            );
          },
          visible: false
        },
        {
          id: 'DEFAULT_DATE_TIME',
          title: 'Date time',
          header: props => (
            <Header {...props} text="Date time" />
          ),
          accessorKey: ['dateFabricated', 'dateCreated'],
          cell: ({ getValue }) => {
            const value = getValue();
            return (
              <RawText>
                {value ? dateFnsFormat(value, `dd/MM/yyyy HH:mm`) : ''}
              </RawText>
            );
          },
          visible: false
        },
        {
          id: 'DEFAULT_COMMENTS',
          title: 'Comments',
          header: props => (
            <Header {...props} text="Comments" />
          ),
          accessorKey: ['interactions'],
          cell: ({ getValue, row, table }) => {
            const { handleToggleSidebar } = table.customState;

            return (
              <ItemLink
                onClick={() =>
                  handleToggleSidebar(row.id, TABS_VALUES.COMMENTS, sidebarSources.TABLE_COMMENTS)
                }
                text={getValue().comments}
              />
            );
          },
          visible: false
        }
      ]
    }
  ]);

  return defaultColumnsRef.current;
};

export default useDefaultColumns;
